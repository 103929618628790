import { Component } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { AlertController, ModalController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth/auth.service'
import { DataService } from 'src/app/services/data/data.service'
import { LoadingService } from 'src/app/services/loading/loading.service'

@Component({
  selector: 'app-guard',
  templateUrl: './guard.page.html',
  styleUrls: ['./guard.page.scss'],
})
export class GuardPage {
  private TAG = 'GuardPage|'
  public guard: any = {}
  public guardForm
  public perm: any = {}

  constructor(
    private alertCtrl: AlertController,
    public authService: AuthService,
    public dataService: DataService,
    private formBuilder: FormBuilder,
    public loading: LoadingService,
    private modalCtrl: ModalController,
  ) {
    this.perm = this.authService.user.permissions.perm5.a9
  } //end ()

  ionViewWillEnter() {
    this.guardForm = this.formBuilder.group({
      fname: [this.guard.fname, Validators.compose([Validators.minLength(1), Validators.required])],
      lname: [this.guard.lname, Validators.compose([Validators.minLength(1), Validators.required])],
      empID: [this.guard.empID, Validators.compose([Validators.minLength(1), Validators.required])],
      tagID: [this.guard.tagID, Validators.compose([Validators.minLength(1), Validators.required])],
    })


  }


  async presentAlert(message) {
    const alert = await this.alertCtrl.create({ header: 'Notice!', subHeader: message, buttons: ['OK'] })
    await alert.present()
  }

  async deleteGuard() {
    const alert = await this.alertCtrl.create({
      header: 'Notice!', message: `<p>Are you sure you want to delete</p> <p><strong>${this.guardForm.value.fname}</strong></p>?`,
      buttons: [{ text: 'Cancel', role: 'cancel' }, {
        text: 'Delete', handler: async () => {
          this.guard.deleted = true
          this.authService.saveHistory({ ...this.guard }, 'guard')
          await this.authService.firestore.doc('guards/' + this.guard.key).update({ date: Date.now(), dateModified: Date.now(), deleted: true }).then(async () => {
            await this.authService.firestore.doc('guards/' + this.guard.key).delete().then(() => { this.authService.logEvent('guard_remove'), this.dismiss() })
          })
        }
      }]
    })
    await alert.present()
  }

  async save() {
    if (this.guardForm.valid) {
      this.loading.present()
      await this.authService.firestore.doc('guards/' + this.guard.key).update({ ...this.authService.defaultDoc(), ...this.guardForm.value, }).then(() => {
        this.authService.saveHistory({ ...this.guardForm.value, dbDate: Date.now() }, 'guard')
        this.authService.logEvent('guard_modify'), this.loading.dismiss(), this.dismiss()
      })
    } else { this.presentAlert('Please make sure you that all the fields have been filled!') }
  }

  dismiss() { this.modalCtrl.dismiss() }
}
