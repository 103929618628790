import { Component, Input, NgZone, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AuthService } from 'src/app/services/auth/auth.service'
import { DataService } from 'src/app/services/data/data.service'
import { UtilityService } from 'src/app/services/utility/utility.service'

@Component({
  selector: 'app-boomin-event-pages-sub',
  templateUrl: './boomin-event-pages-sub.component.html',
  styleUrls: ['./boomin-event-pages-sub.component.scss'],
})
export class BoominEventPagesSubComponent implements OnInit {
  private TAG = 'BoominEventPagesComponent|'
  @Input('siteKey') siteKey
  public currentPage: any = {}
  public currentSubPage: any = {}
  subPages: any = []
  public pages = {}
  public search: any = ''
  public searchCount = 0
  private subject = new Subject()

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private modalCtrl: ModalController,
    public utilityService: UtilityService,
    private zone: NgZone
  ) {} //end ()

  ngOnInit() {
    if (this.currentPage.subPages) this.currentSubPage = Object.values(Object.values(this.currentPage.subPages)[0])[0]
    console.warn(this.TAG, this.siteKey, this.currentPage, this.currentSubPage)
    this.dataService.changesObj.boominPages.pipe(takeUntil(this.subject)).subscribe((val) => { this.zone.run(() => { if ((this.siteKey && val.siteKey === this.siteKey) || (!this.siteKey && !val.siteKey)) this.searchRefresh() }) })
    setTimeout(() => this.searchRefresh(), 10)
  } //end ngOnInit()

  ngOnDestroy() {
    this.pages = {}
    this.subject.next()
    this.subject.complete()
  } //end ngOnDestroy()

  searchPages(source, event?): any {
    this.zone.run(() => {
      this.pages = {}
      if (source) {
        const search = !event ? '' : event?.target ? event.target.value ? event.target.value.toLowerCase() : '' : event ? event.toLowerCase() : ''
        this.pages = Object.keys(source).filter(key => source[key].type === 'Form' && ((this.siteKey && source[key].siteKey === this.siteKey) || (!this.siteKey && !source[key].siteKey))).reduce((obj, key) => { return { ...obj, [key]: source[key] } }, {})
      }
      this.searchCount = Object.keys(this.pages).length
    }) //end this.zone.run()
  } //end searchObj()

  searchRefresh(event?) { this.searchPages(this.dataService.boominPagesObj, (event ? event : this.search)) } //end searchRefresh()

  save() {
    const pagesObj = {}
    // for (const index in this.pagesIn) {
    //   const page = { ... this.dataService.boominPagesObj[this.pagesIn[index]], inIndex: index, options: {} }
    //   pagesObj[page.key] = page
    // }

    // for (const index in this.pagesOut) {
    //   const page = { ... this.dataService.boominPagesObj[this.pagesOut[index]], outIndex: index, options: {} }
    //   pagesObj[page.key] = { ...pagesObj[page.key], ...page }
    // }

    // for (const key in this.currentPagesObj) {
    //   if (pagesObj[key]) pagesObj[key] = { ...this.currentPagesObj[key], ...pagesObj[key], options: this.currentPagesObj?.[key]?.options ? this.currentPagesObj[key].options : {} }
    // }

    // this.modalCtrl.dismiss({ subPages: pagesObj }, 'update')

    // let subPages = []
    // subPages.push({})
    this.modalCtrl.dismiss({ subPages: this.currentPage ? [this.currentSubPage] : [] }, 'update')


  } //end save()

  close() { this.modalCtrl.dismiss(null, null) } //end close()

  pageSubModify(page, option?) {
    this.currentSubPage = { ...page, options: this.currentSubPage?.options ? this.currentSubPage.options : {} }

    if (option)
      this.currentSubPage.options[option] = !this.currentSubPage.options[option]

    if (this.authService.showError) console.error(this.TAG, 'pageSubModify()', this.currentSubPage)

    // if (option) {
    //   if (!this.subPages[page.key][option]) this.subPages[page.key][option] = {}
    //   this.subPages[page.key][option] = !this.subPages[page.key][option]
    // }

  } //end pageModify
}
