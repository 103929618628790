import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { CheckpointPageRoutingModule } from './checkpoint-routing.module'
import { CheckpointPage } from './checkpoint.page'
import { MaterialModule } from 'src/app/modules/material/material.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CheckpointPageRoutingModule,
    MaterialModule,
  ],
  declarations: [CheckpointPage]
})
export class CheckpointPageModule {}
