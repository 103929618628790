import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { Routes, RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { ViewDeviceDataUsagePage } from './view-device-data-usage.page'
import { MaterialModule } from 'src/app/modules/material/material.module'

const routes: Routes = [{ path: '', component: ViewDeviceDataUsagePage }]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    MaterialModule
  ],
  declarations: [ViewDeviceDataUsagePage]
})
export class ViewDeviceDataUsagePageModule {}
