import { Component, OnInit } from '@angular/core'
import { AlertController, PopoverController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth/auth.service'
import { DataService } from 'src/app/services/data/data.service'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { UtilityService } from 'src/app/services/utility/utility.service'

@Component({
  selector: 'app-boomin-relay-open',
  templateUrl: './boomin-relay-open.component.html',
  styleUrls: ['./boomin-relay-open.component.scss'],
})
export class BoominRelayOpenComponent implements OnInit {
  private TAG = 'BoominRelayOpenComponent|'

  public relayCurrent: any = {}
  public relays: any = {}
  public show = { channels: true, relays: true, confirm: true }
  public singleRelay: boolean
  public siteKey: string = ''

  constructor(
    private alertController: AlertController,
    public authService: AuthService,
    public dataService: DataService,
    private loadingService: LoadingService,
    private popoverController: PopoverController,
    public utilityService: UtilityService,
  ) {} //end ()

  ngOnInit() {
    console.log(this.TAG, 'ngOnIt()',)
    this.show = { channels: false, relays: false, confirm: false }
    this.relays = Object.keys(this.dataService.boominRelaysObj).filter(key => this.dataService.boominRelaysObj[key].siteKey === this.siteKey && this.dataService.boominRelaysObj[key].enabled).reduce((obj, key) => { return { ...obj, [key]: this.dataService.boominRelaysObj[key] } }, {}) //end this.objects = Object.keys(source).filter()

    this.singleRelay = Object.keys(this.relays).length === 1
    if (this.singleRelay) {
      const relayKey = Object.keys(this.relays)[0]
      this.relayCurrent = this.relays[relayKey]
      this.show.channels = true
    } else { this.show.relays = true } //end if (this.singleRelay)

  } //end ngOnInit()

  async gateOpen(channelID, relayID, channelName) {
    const alert = await this.alertController.create({
      header: 'Confirm Gate Open', message: `<b>${channelName}</b>`,
      buttons: [
        { text: 'No', role: 'cancel' },
        {
          text: 'Yes', handler: () => {

            this.getBoominRelay(channelID, relayID).then(async (val: any) => {
              const [channel, relay] = [val.channel, val.relay]
              const cmd = {
                channelID: channel.id,
                channelName: channel.name,
                command: 'boomin-gate',
                companyDB: this.authService.user.companyDB,
                dateCreated: Date.now(),
                done: false,
                relayID: relay.relayID,
                relayName: relay.name,
                siteKey: this.siteKey,
                siteName: this.dataService.sitesObj[this.siteKey].name,
                userID: this.authService.user.userID,
                userName: this.authService.user.name
              }

              console.log(this.TAG, 'gateOpen()', cmd)

              let retryMax = 4
              const open = async () => {
                retryMax--
                this.loadingService.present()
                await this.authService.firestore.collection(`commands`).add(cmd).then(async (docRef) => {
                  if (this.authService.showError) console.error(this.TAG, 'showFinal() command upoloaded', { ...cmd }, docRef.id)
                  const cmdRef = await this.authService.firestore.doc(`commands/${docRef.id}`).onSnapshot(async cmdSnapshot => {
                    const commandResult = cmdSnapshot.data()
                    if (commandResult.done) {
                      this.loadingService.dismiss()
                      if (this.authService.showError) console.error(this.TAG, 'showFinal() command done', { ...commandResult })
                      if (commandResult.error) {
                        if (this.authService.showError) console.error(this.TAG, 'showFinal() command error', { ...commandResult })
                        if (retryMax) await this.gateOpenAlert(`Opening Gate Failure`, `${cmd.channelName}`, `Retries Remaining: ${retryMax}`, retryMax).then(() => { open() })
                        else await this.gateOpenAlert(`Opening Gate Failure`, `${cmd.channelName}`, `Retries Remaining: ${retryMax}`)
                        cmdRef()
                      } else {
                        if (this.authService.showError) console.error(this.TAG, 'showFinal() command successful', { ...commandResult })
                        await this.gateOpenAlert(`Opening Gate`, `${cmd.channelName}`, `Successful`)
                        cmdRef()
                      } //end if (commandResult.error)
                    } //end if (commandResult.done)
                  }) //end cmdRef
                }).catch(async () => {
                  this.loadingService.dismiss()
                  if (this.authService.showError) console.error(this.TAG, 'showFinal() command upoloaded failed', { ...cmd })
                  if (retryMax) await this.gateOpenAlert(`Opening Gate Failure`, `${cmd.channelName}`, `Retries Remaining: ${retryMax}`, retryMax).then(() => { open() })
                  else await this.gateOpenAlert(`Opening Gate Failure`, `${cmd.channelName}`, `Retries Remaining: ${retryMax}`)
                }) //end this.authService.firestore.collection('commands/')
              } //end open()
              open()
            }).catch(async () => {
              // this.loadingService.dismiss()
              if (this.authService.showError) console.error(this.TAG, 'showFinal() Opening Gate Failure')
              await this.gateOpenAlert(`Opening Gate Failure`, `Relay or Channel Missing`, `Failed to find Channel or Relay`)
            }) //end this.getBoominRelay()
          }
        }]
    })
    await alert.present()
  } //end gateOpen()

  private async gateOpenAlert(header, subheader, msg, retry?) {
    return await new Promise<void>(async (resolve, reject) => {
      const alertOpen = await this.alertController.getTop()
      if (alertOpen) this.alertController.dismiss()
      const buttons: any = [
        {
          cssClass: 'secondary',
          role: 'cancel',
          text: 'Okay',
          handler: () => {
            console.log(this.TAG, 'Confirm Okay')
            reject()
            this.dismiss()
          },
        }]

      if (retry) buttons.push({
        text: 'Retry Opening', handler: () => {
          console.log(this.TAG, 'Confirm Retry Opening')
          resolve()
        }
      })

      await (await this.alertController.create({
        animated: false,
        backdropDismiss: false,
        buttons: buttons,
        header: header,
        message: msg,
        subHeader: subheader,
      })).present()
    }) //end Promise()
  } //end gateOpenAlert()

  private getBoominRelay(channelID, relayID) {
    console.log(this.TAG, 'getBoominRelay()')
    return new Promise((resolve, reject) => {
      const relay: any = Object.values(this.dataService.boominRelaysObj).find((val: any) => val.relayID === relayID) || false
      if (relay) {
        console.log(this.TAG, 'getBoominRelay() relay found', { ...relay })
        const channel: any = relay.channels.find((val: any) => val.id === channelID) || false
        if (channel) {
          console.log(this.TAG, 'getBoominRelay() channel found', { ...channel }, 'relay found', { ...relay })
          resolve({ channel: channel, relay: relay })
        }
        else {
          if (this.authService.showError) console.error(this.TAG, 'getBoominRelay() channel found', channel, 'relay found', relay)
          reject(false)
        } //end if (channel)
      } else {
        if (this.authService.showError) console.error(this.TAG, 'getBoominRelay() relay found', relay)
        reject(false)
      } //end if (relay)
    })
  } //end getBoominRelay()

  dismiss() { this.popoverController.dismiss() } //end dismiss()
} //end BoominRelayOpenComponent