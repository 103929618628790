import { Component, Input, NgZone, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AuthService } from 'src/app/services/auth/auth.service'
import { DataService } from 'src/app/services/data/data.service'
import { UtilityService } from 'src/app/services/utility/utility.service'

@Component({
  selector: 'app-boomin-event-pages',
  templateUrl: './boomin-event-pages.component.html',
  styleUrls: ['./boomin-event-pages.component.scss'],
})
export class BoominEventPagesComponent implements OnInit {
  private TAG = 'BoominEventPagesComponent|'
  @Input('siteKey') siteKey
  public pages = {}
  public search: any = ''
  public searchCount = 0
  private subject = new Subject()

  keysIn = {}
  keysOut = {}
  keys = {}

  pagesIn: any = []
  pagesOut: any = []
  currentPagesObj: any = {}

  modeManage: boolean = false
  modeSelect: boolean = false

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private modalCtrl: ModalController,
    public utilityService: UtilityService,
    private zone: NgZone
  ) {} //end ()


  ngOnInit() {
    console.warn(this.TAG, this.pagesIn, this.pagesOut)
    this.pagesIn.map(key => this.keysIn[key] = true)
    this.pagesOut.map(key => this.keysOut[key] = true)
    this.dataService.changesObj.boominPages.pipe(takeUntil(this.subject)).subscribe((val) => { this.zone.run(() => { if (val.siteKey === this.siteKey) this.searchRefresh() }) })
    setTimeout(() => this.searchRefresh(), 10)
  } //end ngOnInit()

  ngOnDestroy() {
    this.pages = {}
    this.subject.next()
    this.subject.complete()
  } //end ngOnDestroy()

  searchPages(source, event?): any {
    this.zone.run(() => {
      this.pages = {}
      if (source) {
        const search = !event ? '' : event?.target ? event.target.value ? event.target.value.toLowerCase() : '' : event ? event.toLowerCase() : ''
        this.pages = Object.keys(source).filter(key => source[key].siteKey === this.siteKey && this.utilityService.searchNested(source[key], search)).reduce((obj, key) => { return { ...obj, [key]: source[key] } }, {})
      }
      this.searchCount = Object.keys(this.pages).length
    }) //end this.zone.run()
  } //end searchObj()

  searchRefresh(event?) { this.searchPages(this.dataService.boominPagesObj, (event ? event : this.search)) } //end searchRefresh()

  save() {
    if (this.modeManage) {
      const pagesObj = {}
      for (const index in this.pagesIn) {
        const page = { ... this.dataService.boominPagesObj[this.pagesIn[index]], inIndex: index, options: {} }
        pagesObj[page.key] = page
      }

      for (const index in this.pagesOut) {
        const page = { ... this.dataService.boominPagesObj[this.pagesOut[index]], outIndex: index, options: {} }
        pagesObj[page.key] = { ...pagesObj[page.key], ...page }
      }

      for (const key in this.currentPagesObj) {
        if (pagesObj[key]) pagesObj[key] = { ...this.currentPagesObj[key], ...pagesObj[key], options: this.currentPagesObj?.[key]?.options ? this.currentPagesObj[key].options : {} }
      }

      this.modalCtrl.dismiss({ keysIn: this.pagesIn, keysOut: this.pagesOut, pages: pagesObj }, 'update')
    }
    else if (this.modeSelect) {
      this.modalCtrl.dismiss({ keys: this.keys }, 'update')
    }
  } //end save()

  close() { this.modalCtrl.dismiss(null, null) } //end close()

  pageModify(page, side?) {
    if (side) {
      this[`keys${side}`][page.key] = !this[`keys${side}`][page.key]
      this[`keys${side}`][page.key] ? this.pageAdd(page.key, side) : this.pageRemove(page.key, side)
      console.log(this.TAG, 'pageModify()', page, side, this[`keys${side}`][page.key])
    } else {
      this.keysIn[page.key] = false, this.keysOut[page.key] = false
      this.pageRemove(page.key, 'In'), this.pageRemove(page.key, 'Out')
    } //end if (side)
  } //end pageModify

  pageAdd(key, side) {
    this[`pages${side}`] = ([...new Set(this[`pages${side}`])])
    if (this[`pages${side}`].indexOf(key.key) == -1) this[`pages${side}`].push(key)
  } //end pageAdd()

  pageRemove(key, side) {
    this[`pages${side}`] = ([...new Set(this[`pages${side}`])])
    const index = this[`pages${side}`].indexOf(key)
    if (index != -1) this[`pages${side}`].splice(index, 1)
  } //end pageRemove()

  pageSelect(ev: any, page) {
    this.keys[page.key] = ev.detail.checked
    console.log(this.TAG, 'pageSelect()', ev.detail.checked, page.key, { ...this.keys })
  } //end addPage()

} //end BoominEventPagesComponent
