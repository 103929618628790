import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth/auth.service'
import { DataService } from 'src/app/services/data/data.service'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { UtilityService } from 'src/app/services/utility/utility.service'

@Component({
  selector: 'app-checklist-detail',
  templateUrl: './checklist-detail.component.html',
  styleUrls: ['./checklist-detail.component.scss'],
})
export class ChecklistDetailComponent   {
  public add: boolean = false
  public checklist: any = {}
  public item: any = ''
  public perm: any = {}
  public textBox: any = ''
  public switch: boolean = true

  constructor(
    private alertCtrl: AlertController,
    private authService: AuthService,
    public dataService: DataService,
    public loading: LoadingService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public utilityService: UtilityService) {
    this.checklist = this.navParams.data.checklist
    this.navParams.data.add ? this.add = true : ''
    this.perm = this.authService.user.permissions.perm5.a6
  } //end ()

  addItem(item) {
    if (item) {
      this.checklist.items.push({
        description: item,
        ID: this.utilityService.makeID(),
        photo: false,
        type: 'item'
      })
      this.item = ''

    }
  } //end addItem()

  addTextBox(item) {
    if (item) {
      this.checklist.items.push({
        description: item,
        ID: this.utilityService.makeID(),
        photo: false,
        type: 'textBox'
      })
      this.textBox = ''
    }
  } //end addTextBox()

  deleteItem(event) {
    const index = this.checklist.items.indexOf(event)
    if (index !== -1) {
      this.checklist.items.splice(index, 1)
    }
  } //end deleteItem()

  checkForLinkedStuff() {
    let proceed = true
    const devices = Object.keys(this.dataService.devicesSitesObj)
      .filter(key => {
        let flag = false
        this.dataService.devicesSitesObj[key]?.checklists?.[this.checklist.key] ? flag = true : ''
        this.dataService.devicesSitesObj[key]?.checkInChecklist?.key == this.checklist.key ? flag = true : ''
        return flag
      })
      .map(key => this.dataService.devicesSitesObj[key])
    devices.length > 0 ? proceed = false : ''
    return { proceed: proceed, devices: devices }
  } //end checkForLinkedStuff()

  async deleteChecklist() {
    if (this.add) {
      this.dismiss()
    } else {
      const data = this.checkForLinkedStuff()
      if (data.proceed) {
        const alert = await this.alertCtrl.create({
          header: 'Notice!',
          message: 'Are you sure you want to delete ' + this.checklist.name + '?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel'
            }, {
              text: 'Delete',
              handler: async () => {
                this.loading.present()
                this.checklist.deleted = true
                this.authService.saveHistory({ ...this.checklist }, 'checklist')
                await this.authService.firestore.doc('checklists/' + this.checklist.key).update({ date: Date.now(), dateModified: Date.now(), deleted: true }).then(async () => {
                  await this.authService.firestore.doc('checklists/' + this.checklist.key).delete().then(() => {
                    this.authService.logEvent('checklist_remove')
                    this.loading.dismiss()
                    this.dismiss()
                  })
                })
              }
            }
          ]
        })
        await alert.present()
      } else { this.utilityService.simpleAlert('This checklist is still linked ' + data.devices[0].name + ' device. Please first remove this checklist from ' + data.devices[0].name + '!') }
    } //end if (this.add)
  } //end deleteChecklist()

  async saveChecklist() {
    if (this.checklist.items.length > 0 && this.checklist.name) {
      this.checklist.date = Date.now()
      this.checklist.dateModified = Date.now()
      this.checklist.timestamp = this.authService.timestamp
      this.loading.present()
      if (this.add) {
        this.checklist.companyDB = this.authService.user.companyDB
        await this.authService.firestore.collection('checklists').add(this.checklist).then(docRef => {
          this.checklist.key = docRef.id
          this.authService.logEvent('checklist_add')
          this.authService.saveHistory({ ...this.checklist }, 'checklist')
          this.loading.dismiss()
          this.add = false
          this.utilityService.simpleAlertSave('The checklist ' + this.checklist.name + ' has been successfully saved!').then((action) => { if (action.close) { this.dismiss() } })

        })
      } else {
        await this.authService.firestore.doc('checklists/' + this.checklist.key).update(this.checklist).then(() => {
          this.authService.logEvent('checklist_modify')
          this.authService.saveHistory({ ...this.checklist }, 'checklist')
          this.loading.dismiss()
          this.utilityService.simpleAlertSave('The checklist ' + this.checklist.name + ' has been successfully saved!').then((action) => { if (action.close) { this.dismiss() } })
        })
      } //end if (this.add)
    } else {
      this.checklist.name ? this.utilityService.simpleAlert('Please add items to the checklist before you save this checklist!') : this.utilityService.simpleAlert('Please enter a checklist name!')
    } //end if (this.checklist.items.length > 0 && this.checklist.name)
  } //end saveChecklist()

  dismiss() {
    this.modalCtrl.dismiss()
  } //end dismiss()

}
