import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicGestureConfig } from '../utils/IonicGestureConfig'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer'
import { NgxSpinnerModule } from 'ngx-spinner'
import { RouteReuseStrategy } from '@angular/router'

import { environment } from '../environments/environment'

import firebase from 'firebase/compat/app'
// import 'firebase/compat/app-check'
firebase.initializeApp(environment.firebaseConfig)
// firebase.appCheck().activate(new firebase.appCheck.ReCaptchaEnterpriseProvider('0845028D-F040-4A37-BF6B-6C96CB4C88A1'), true)
// firebase.appCheck().activate(new firebase.appCheck.ReCaptchaEnterpriseProvider('6LdqMr4kAAAAAOS1qmOTEY8ImToUFhgIA55wW6L5'), true)
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAnalyticsModule, APP_NAME, APP_VERSION, DEBUG_MODE, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics'
import { AngularFireAuthModule, USE_EMULATOR as EMULATOR_AUTH } from '@angular/fire/compat/auth'
import { AngularFireDatabaseModule } from '@angular/fire/compat/database'
import { AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS, USE_EMULATOR as EMULATOR_DATABASE } from '@angular/fire/compat/firestore'
import { AngularFireFunctionsModule, USE_EMULATOR as EMULATOR_FUNCTIONS } from '@angular/fire/compat/functions'
import { AngularFireStorageModule, USE_EMULATOR as EMULATOR_FIRESTORE } from '@angular/fire/compat/storage'

import { AlertActionComponent } from './pages/live-feed/alert-action/alert-action.component'
import { BoominEventDetailComponent } from './components/boomin/boomin-event-detail/boomin-event-detail.component'
import { BoominPageDetailComponent } from './components/boomin/boomin-page-detail/boomin-page-detail.component'
import { BoominReportDetailComponent } from './components/boomin/boomin-report-detail/boomin-report-detail.component'
import { ChangeEmailComponent } from './components/popovers/change-email/change-email.component'
import { ChangePasswordComponent } from './components/popovers/change-password/change-password.component'
import { ChecklistDetailComponent } from './pages/settings/checklists/checklist-detail/checklist-detail.component'
import { CheckpointPageModule } from './pages/settings/checkpoints/site-checkpoints/checkpoint/checkpoint.module'
import { CompaniesComponent } from './pages/global/companies/companies.component'
import { GuardPageModule } from './pages/settings/guards/guard/guard.module'
import { ObjectsSearchComponent } from './pages/global/objects-search/objects-search.component'
import { PatrolPopoverComponent } from './pages/devices/device/patrol-popover/patrol-popover.component'
import { PopoverComponent } from './pages/global/popover/popover.component'
import { PopoverSortComponent } from './pages/global/popover-sort/popover-sort.component'
import { RosterPopoverComponent } from './pages/check-in/roster-popover/roster-popover.component'
import { ViewDeviceDataUsagePageModule } from './pages/devices/device/view-device/view-device-data-usage/view-device-data-usage.module';
import { DragAbsoluteDirective } from './directives/drag-absolute/drag-absolute.directive';
import { BoominRelayOpenComponent } from './components/boomin/boomin-relay-open/boomin-relay-open/boomin-relay-open.component'

import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AlertActionComponent,
    AppComponent,
    BoominEventDetailComponent,
    BoominPageDetailComponent,
    BoominReportDetailComponent,
    ChangeEmailComponent,
    ChangePasswordComponent,
    ChecklistDetailComponent,
    CompaniesComponent,
    ObjectsSearchComponent,
    PatrolPopoverComponent,
    PopoverComponent,
    PopoverSortComponent,
    RosterPopoverComponent,
    DragAbsoluteDirective,
    BoominRelayOpenComponent,
  ],
  exports: [],
  entryComponents: [],
  imports: [
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    AngularFireDatabaseModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CheckpointPageModule,
    FormsModule,
    GuardPageModule,
    IonicModule.forRoot({ backButtonText: '', backButtonIcon: 'arrow-back', mode: 'md', statusTap: true }),
    NgxIonicImageViewerModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    ViewDeviceDataUsagePageModule,
    HttpClientModule
  ],
  providers: [
    // { provide: EMULATOR_AUTH, useValue: environment.emulator ? undefined : ['localhost', 9099], },
    // { provide: EMULATOR_FIRESTORE, useValue: environment.emulator ? undefined : ['localhost', 8080], },
    // { provide: EMULATOR_DATABASE, useValue: environment.emulator ? undefined : ['localhost', 9000], },
    // { provide: EMULATOR_FUNCTIONS, useValue: environment.emulator ? undefined : ['localhost', 5001], },
    { provide: APP_NAME, useValue: 'Amatrack Admin' },
    { provide: APP_VERSION, useValue: '3.4.13' },
    // { provide: DEBUG_MODE, useValue: environment.analyticsDebug },
    { provide: FIRESTORE_SETTINGS, useValue: { ignoreUndefinedProperties: true } },
    { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
    { provide: MAT_DATE_LOCALE, useValue: 'en-ZA' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ScreenTrackingService,
    UserTrackingService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}