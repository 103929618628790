import { Component } from '@angular/core'
import { NavParams, PopoverController } from '@ionic/angular'

@Component({
  selector: 'app-roster-popover',
  templateUrl: './roster-popover.component.html',
  styleUrls: ['./roster-popover.component.scss'],
})
export class RosterPopoverComponent {
  public items: any = []

  constructor(
    public navParams: NavParams,
    public popoverController: PopoverController
  ) {
    this.items = this.navParams.data.roster
    this.items.forEach(item => {
      item.fname = item.fname.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
      item.lname = item.lname.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
    }) //this.items.forEach()
  } //end ()

  itemSelected(item) {
    this.dismiss(item)
  }

  dismiss(data?: any) {
    this.popoverController.dismiss(data)
  }

}
