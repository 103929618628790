import { ChangeDetectorRef, Component, NgZone } from '@angular/core'
import { AlertController, ModalController, NavParams } from '@ionic/angular'
import { AuthService } from '../../../../../services/auth/auth.service'
import { DataService } from '../../../../../services/data/data.service'
import { LoadingService } from '../../../../../services/loading/loading.service'
import { UtilityService } from '../../../../../services/utility/utility.service'


@Component({
  selector: 'app-checkpoint',
  templateUrl: './checkpoint.page.html',
  styleUrls: ['./checkpoint.page.scss'],
})
export class CheckpointPage {
  private TAG = 'CheckpointPage|'
  public checkpoint: any = ''
  public perm: any = {}
  public siteKey: any = ''

  constructor(
    private alertCtrl: AlertController,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    public dataService: DataService,
    public loading: LoadingService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public utilityService: UtilityService,
    private zone: NgZone
  ) {
    this.checkpoint = this.navParams.data.checkpoint
    this.siteKey = this.navParams.data.checkpoint.siteKey
    this.perm = this.authService.user.permissions.perm5.a7
  } //end ()

  checkForLinkedPatrols(deleteSite?) {
    let proceed = true
    if (this.siteKey != '') {
      const patrols = Object.keys(this.dataService.patrolsObj).filter(key => this.siteKey ? this.dataService.patrolsObj[key].siteKey == this.siteKey ? true : false : true).map(key => this.dataService.patrolsObj[key])
      dance:
      for (let key in patrols) {
        if (patrols[key].checkpoints) {
          for (let chkKey in patrols[key].checkpoints) {
            if (patrols[key].checkpoints[chkKey].key == this.checkpoint.key) {
              proceed = false
              if (deleteSite) this.utilityService.simpleAlert('The checkpoint ' + this.checkpoint.name + ' is still linked to ' + patrols[key].name + ' patrol. Please remove it from ' + patrols[key].name + ' before you can delete it!')
              else this.utilityService.simpleAlert('The checkpoint ' + this.checkpoint.name + ' is still linked to ' + patrols[key].name + ' patrol. Please remove it from ' + patrols[key].name + ' before you can change it to a different site!')
              break dance
            }
          } //end for (let chkKey in patrols[key].checkpoints)
        }
      } //end for (let key in patrols)
    }
    return proceed
  }

  changeSite() {
    this.zone.run(() => {
      this.cdRef.detectChanges()
      const proceed = this.checkForLinkedPatrols()
      if (proceed) { this.authService.logEvent('checkpoint_site_change'), this.siteKey = this.checkpoint.siteKey } else {
        this.checkpoint.siteKey = this.siteKey
        console.log(this.TAG, this.checkpoint, this.siteKey)
      }
    }) //this.zone.run()
  }

  async deleteCheckpoint() {
    const proceed = this.checkForLinkedPatrols(true)
    if (proceed) {
      const alert = await this.alertCtrl.create({
        header: 'Notice!',
        message: 'Are you sure you want to delete ' + this.checkpoint.name + '?',
        buttons: [
          { text: 'Cancel', role: 'cancel' },
          {
            text: 'Delete',
            handler: async () => {
              this.loading.present()
              this.checkpoint.deleted = true
              this.authService.saveHistory({ ...this.checkpoint }, 'checkpoint')
              await this.authService.firestore.doc('checkpoints/' + this.checkpoint.key).update({ date: Date.now(), dateModified: Date.now(), deleted: true }).then(async () => {
                await this.authService.firestore.doc('checkpoints/' + this.checkpoint.key).delete().then(() => {
                  this.authService.logEvent('checkpoint_remove')
                  this.loading.dismiss()
                  this.dismiss()
                })
              })
            }
          }
        ]
      })

      await alert.present()
    }
  }

  async saveCheckpoint() {
    if (this.checkpoint.name) {
      this.loading.present()
      const checkpoint = {
        companyDB: this.authService.user.companyDB,
        date: Date.now(),
        dateModified: Date.now(),
        name: this.checkpoint.name,
        patrolStopAlarm: this.checkpoint.patrolStopAlarm ?? false,
        silentAlarm: this.checkpoint.silentAlarm ?? false,
        siteKey: this.checkpoint.siteKey,
        tagID: this.checkpoint.tagID,
        timestamp: this.authService.timestamp
      }
      await this.authService.firestore.doc('checkpoints/' + this.checkpoint.key).update(checkpoint).then(() => {
        this.authService.logEvent('checkpoint_modify')
        this.authService.saveHistory({ ...checkpoint }, 'checkpoint')
        this.loading.dismiss()
        this.dismiss()
      })
    } else {
      this.utilityService.simpleAlert('Please enter a checkpoint name!')
    } //end if (this.checkpoint.name)
  }

  dismiss() {
    this.modalCtrl.dismiss()
  }
}