import { Component } from '@angular/core'
import { AlertController, ModalController, NavParams } from '@ionic/angular'
import { UtilityService } from 'src/app/services/utility/utility.service'

@Component({
  selector: 'app-view-device-data-usage',
  templateUrl: './view-device-data-usage.page.html',
  styleUrls: ['./view-device-data-usage.page.scss'],
})
export class ViewDeviceDataUsagePage {
  public dataUsageDetail: any = {}
  public mobileUsage: any[] = []
  public segment: any = 'mobile'
  public showMobile: boolean = true
  public showWifi: boolean = false
  public wifiUsage: any[] = []

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public utilityService: UtilityService
  ) {
    this.dataUsageDetail = JSON.parse(JSON.stringify(this.navParams.data.dataUsage))
    if (this.dataUsageDetail.mobileUsage) this.setupDataSorting(this.dataUsageDetail.mobileUsage.appDetail)
    if (this.dataUsageDetail.wifiUsage) this.setupDataSorting(this.dataUsageDetail.wifiUsage.appDetail)
    if (!this.dataUsageDetail.mobileUsage) {
      this.showMobile = false
      this.showWifi = true
      this.segment = 'wifi'
    }
    const dataUsageDup = JSON.parse(JSON.stringify(this.dataUsageDetail))
    this.removeDuplicateAppName(dataUsageDup)
    this.sort('tdu')
  } //end ()

  setupData(app, element1, element2) {
    if (app[element1][1] == 'B') app[element2] = parseFloat(app[element1][0].replace(/,/g, '.'))
    if (app[element1][1] == 'kB') app[element2] = parseFloat(app[element1][0].replace(/,/g, '.')) * 1000
    if (app[element1][1] == 'MB') app[element2] = parseFloat(app[element1][0].replace(/,/g, '.')) * 1000 * 1000
    if (app[element1][1] == 'GB') app[element2] = parseFloat(app[element1][0].replace(/,/g, '.')) * 1000 * 1000 * 1000
    if (app[element1][1] == 'TB') app[element2] = parseFloat(app[element1][0].replace(/,/g, '.')) * 1000 * 1000 * 1000 * 1000
    return app
  }

  setupDataSorting(array) {
    array.forEach(app => {
      app.data = app.data.split(/\s/)
      app.dataReceived = app.dataReceived.split(/\s/)
      app.dataTransmitted = app.dataTransmitted.split(/\s/)
      this.setupData(app, 'data', 'dataSorted')
      this.setupData(app, 'dataReceived', 'dataReceivedSorted')
      this.setupData(app, 'dataTransmitted', 'dataTransmittedSorted')
    })
  } //end setupDataSorting()

  removeDuplicateAppName(array) {
    let dups = []
    if (array.mobileUsage) {
      this.mobileUsage = array.mobileUsage.appDetail.filter(el => {
        if (dups.indexOf(el.name) == -1) {
          dups.push(el.name)
          return true
        }
        return false
      })
    } //end if (array.mobileUsage)

    let dups2 = []
    if (array.wifiUsage) {
      this.wifiUsage = array.wifiUsage.appDetail.filter(el => {
        if (dups2.indexOf(el.name) == -1) {
          dups2.push(el.name)
          return true
        }
        return false
      })
    } //end if (array.wifiUsage)
    this.addTotal()
  } //end removeDuplicateAppName(array)

  getDataSize(app, element1, element2) {
    if (app[element1] >= 0 && app[element1] < 1000) {
      app[element2][0] = (app[element1]), app[element2][1] = 'B'
    }
    if (app[element1] >= (1000) && app[element1] < (1000 * 1000)) {
      app[element2][0] = (app[element1] / 1000).toFixed(2), app[element2][1] = 'kB'
    }
    if (app[element1] >= (1000 * 1000) && app[element1] < (1000 * 1000 * 1000)) {
      app[element2][0] = (app[element1] / 1000 / 1000).toFixed(2), app[element2][1] = 'MB'
    }
    if (app[element1] >= (1000 * 1000 * 1000) && app[element1] < (1000 * 1000 * 1000 * 1000)) {
      app[element2][0] = (app[element1] / 1000 / 1000 / 1000).toFixed(2), app[element2][1] = 'GB'
    }
    if (app[element1] >= (1000 * 1000 * 1000 * 1000) && app[element1] < (1000 * 1000 * 1000 * 1000 * 1000)) {
      app[element2][0] = (app[element1] / 1000 / 1000 / 1000 / 1000).toFixed(2), app[element2][1] = 'TB'
    }
    if (app[element1] >= (1000 * 1000 * 1000 * 1000 * 1000) && app[element1] < (1000 * 1000 * 1000 * 1000 * 1000 * 1000)) {
      app[element2][0] = (app[element1] / 1000 / 1000 / 1000 / 1000 / 1000).toFixed(2), app[element2][1] = 'PB'
    }
  }

  initializeAppData(app, type) {
    app.dataSorted = 0, app.dataReceivedSorted = 0, app.dataTransmittedSorted = 0, app.packets = 0, app.packetsReceived = 0, app.packetsTransmitted = 0
    const val = '' + app.name
    const temp = this.dataUsageDetail[type].appDetail.filter(d => {
      if (('' + d.name).indexOf(val) !== -1) {
        app.dataSorted += d.dataReceivedSorted
        app.dataSorted += d.dataTransmittedSorted
        app.dataReceivedSorted += d.dataReceivedSorted
        app.dataTransmittedSorted += d.dataTransmittedSorted

        app.packets += d.packetsReceived
        app.packets += d.packetsTransmitted
        app.packetsReceived += d.packetsReceived
        app.packetsTransmitted += d.packetsTransmitted
      } //end if ((''+d.name).indexOf(val) !== -1)
      return false
    }) // const temp = this.dataUsageDetail.wifiUsage.appDetail.filter()
    return app
  }

  addTotal() {
    this.mobileUsage.forEach(app => {
      app = this.initializeAppData(app, 'mobileUsage')
      this.getDataSize(app, 'dataSorted', 'data')
      this.getDataSize(app, 'dataReceivedSorted', 'dataReceived')
      this.getDataSize(app, 'dataTransmittedSorted', 'dataTransmitted')
    }) //this.mobileUsage.forEach()

    this.wifiUsage.forEach(app => {
      app = this.initializeAppData(app, 'wifiUsage')
      this.getDataSize(app, 'dataSorted', 'data')
      this.getDataSize(app, 'dataReceivedSorted', 'dataReceived')
      this.getDataSize(app, 'dataTransmittedSorted', 'dataTransmitted')
    }) //this.wifiUsage.forEach()

  } //end addTotal()

  segmentChanged(ev: any) {
    if (ev.detail.value == 'mobile') {
      this.showMobile = true
      this.showWifi = false
    } else if (ev.detail.value == 'wifi') {
      this.showMobile = false
      this.showWifi = true
    }
  } //end segmentChanged(ev: any)

  sortChanged(ev: any) {
    this.sort(ev.detail.value)
  } //end sortChanged(ev: any)

  sort(sortType) {
    if (sortType == 'tdu') { this.sortArray(this.mobileUsage, 'dataSorted'), this.sortArray(this.wifiUsage, 'dataSorted') }
    if (sortType == 'tdr') { this.sortArray(this.mobileUsage, 'dataReceivedSorted'), this.sortArray(this.wifiUsage, 'dataReceivedSorted') }
    if (sortType == 'tdt') { this.sortArray(this.mobileUsage, 'dataTransmittedSorted'), this.sortArray(this.wifiUsage, 'dataTransmittedSorted') }
    if (sortType == 'tpu') { this.sortArray(this.mobileUsage, 'packets'), this.sortArray(this.wifiUsage, 'packets') }
    if (sortType == 'tpr') { this.sortArray(this.mobileUsage, 'packetsReceived'), this.sortArray(this.wifiUsage, 'packetsReceived') }
    if (sortType == 'tpt') { this.sortArray(this.mobileUsage, 'packetsTransmitted'), this.sortArray(this.wifiUsage, 'packetsTransmitted') }
    if (sortType == 'name') { this.sortArray(this.mobileUsage, 'name'), this.sortArray(this.wifiUsage, 'name') }
  } //end sort(sortType)

  sortArray(array, type) {
    if (type == 'name') {
      array.sort((a, b) => {
        if (a[type] === b[type]) return 0
        if (a[type] > b[type]) return 1
        if (a[type] < b[type]) return -1
      })
    } else {
      array.sort((a, b) => {
        if (a[type] === b[type]) return 0
        if (a[type] > b[type]) return -1
        if (a[type] < b[type]) return 1
      })
    } //end if (type == 'name')
  }

  async viewAppName(appName) {
    const alert = await this.alertCtrl.create({
      header: 'Application Name:',
      subHeader: appName,
      buttons: ['OK']
    })
    await alert.present()
  } //end viewAppName(appName)

  dismiss() {
    this.modalCtrl.dismiss()
  } //end dismiss()
}