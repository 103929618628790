import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { AlertController } from '@ionic/angular'
import firebase from 'firebase/compat/app'
import { Observable } from 'rxjs'
import { AuthService } from 'src/app/services/auth/auth.service'

declare let window: any

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private TAG = 'AuthGuard|'
  private currentUser: any
  private startUp: boolean = false

  constructor(
    public afAuth: AngularFireAuth,
    public authService: AuthService,
    private alertCtrl: AlertController,
    private router: Router
  ) {} //end ()

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe(async (user: firebase.User) => {
        if (user) {
          if (!this.startUp) console.warn(this.TAG, 'User is logged in via ===>> ', user.email), this.startUp = true
          if (user.email === 'device@amatrack.net') this.authService.signOut()
          await this.getUser()
          if (this.currentUser.permissions) {
            console.log(this.TAG, 'state', state.url)
            this.checkPermissions(state.url, resolve)
          } else { this.rejectUser(resolve) } //end if (this.currentUser.permissions)
        } else {
          console.log(this.TAG, 'User is not logged in')
          this.router.navigate(['/login'])
          resolve(false)
        } //end if (user)
      }) //end this.auth.authState.subscribe()
    })
  } //canActivate()

  //TODO: inventories permissions
  checkPermissions(url, resolve) {
    url = url.split('/').slice(0, 4).join('/')
    if (url == '/tabs/settings/user-policies' && this.currentUser.isAdmin || (url.includes('boomin-inventories') && this.currentUser.isAdmin)) { resolve(true) } else { this.searchObj(url) ? resolve(true) : this.rejectUser(resolve) }
    console.log(this.TAG, 'checkPermissions', url, this.searchObj(url))
  }

  getUser() {
    return new Promise(async (resolve, reject) => {
      const interval = await setInterval(() => {
        if (this.authService.user) {
          this.currentUser = this.authService.user
          clearInterval(interval)
          resolve(true)
        }
      }, 300) //setInterval()
    }) //return new Promise()
  }

  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Notice!', subHeader: 'You don\'t have permission to access this page, please contact your manager to get access!',
      buttons: [{ text: 'OK', handler: () => { console.log(this.TAG, 'Confirm Okay'), window.location.reload() } }]
    })
    await alert.present()
  }

  rejectUser(resolve) {
    this.router.navigate(['/login'])
    resolve(false)
    this.afAuth.signOut()
    this.presentAlert()
  }

  searchNested(obj, val) {
    let flag = false
    const recurse = (obj) => {
      for (const key in obj) {
        let value = obj[key]
        if (value && typeof value === 'object') { recurse(value) }
        else { if (('' + value).toLowerCase().indexOf(val) !== -1 || !val) { if (obj.enabled) flag = true } } //if (value && typeof value === 'object')
      } //end for (const key in obj)
    }
    recurse(obj)
    return flag
  } //end searchNested()

  searchObj(val) {
    let objects = Object.keys(this.currentUser.permissions).filter(key => {
      return this.searchNested(this.currentUser.permissions[key], val)
    }).reduce((obj, key) => { return { ...obj, [key]: this.currentUser.permissions[key] } }, {}) //let objects = Object.keys(this.currentUser.permissions).filter()
    return Object.keys(objects).length > 0 ? true : false
  } //end searchObj()
}