import { Component, ElementRef, ViewChild } from '@angular/core'
import { AuthService } from './services/auth/auth.service'
import { DataService } from './services/data/data.service'
import { IdService } from './services/id/id.service'
import { GestureController, DomController } from '@ionic/angular'
import { PttAntmediaWebhooksService } from './services/antmediaWebhooks/ptt-antmedia-webhooks.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private TAG = 'AppComponent|'

  @ViewChild('widgetPTT', { read: ElementRef }) widgetPTT: ElementRef
  // @ViewChild(IonHeader, { read: ElementRef }) header: ElementRef
  power = 0;
  longPressActive = false;
  constructor(
    public authService: AuthService,
    public dataService: DataService,
    public idService: IdService,
    private gestureCtrl: GestureController,
    private domCtrl: DomController,
    private antMediaService: PttAntmediaWebhooksService,
  ) {} //end ()


  ngOnInit( ) {
    this.antMediaService.getStreamQueue().subscribe((queue: any[]) => {
      console.log('Updated Stream Queue:', queue);
    });
  }

  ngAfterViewInit() {
    // Use DomCtrl to get the correct header height
    // await this.domCtrl.read(() => {
    //   const headerHeight = this.header.nativeElement.offsetHeight
    this.setupGesture(60)
    // })
  }


  setupGesture(headerHeight) {
    console.log(this.TAG, 'setupGesture()', headerHeight)
    const limitX = { max: window.innerWidth - 60, min: 1 }
    const limitY = { max: window.innerHeight-60, min: (window.innerHeight * 0.25) }
    const moveGesture = this.gestureCtrl.create({
      el: this.widgetPTT.nativeElement,
      threshold: 0,
      gestureName: 'drag',
      onStart: ev => {
        console.log(this.TAG, 'move start!')
      },
      onMove: ev => {
        console.log(this.TAG,/*  ev, */ ev.currentX, window.innerWidth, limitX,ev.currentY, window.innerHeight, limitY, this.widgetPTT.nativeElement.offsetHeight, this.widgetPTT.nativeElement.offsetWidth,this.widgetPTT.nativeElement)

        let currentX = ev.currentX
        let currentY = ev.currentY

        if (currentX < limitX.min) currentX = limitX.min
        if (currentY < limitY.min) currentY = limitY.min

        if (currentX > limitX.max) currentX = limitX.max
        if (currentY > limitY.max) currentY = limitY.max

        this.widgetPTT.nativeElement.style.transform = `translate(${currentX}px, ${currentY}px)`
      },
      onEnd: ev => {
        console.log(this.TAG, 'move end!')
      }
    })

    // Don't forget to enable!
    moveGesture.enable(true)
  }

} //end AppComponent
