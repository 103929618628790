import { Directive, ElementRef, Input, Renderer2 } from '@angular/core'
import { DomController, GestureController } from '@ionic/angular'

@Directive({
  selector: '[appDragAbsolute]'
})
export class DragAbsoluteDirective {
  private TAG = 'DragAbsoluteDirective|'

  @Input('startLeft') startLeft: any
  @Input('startTop') startTop: any

  constructor(public element: ElementRef, public renderer: Renderer2, public domCtrl: DomController, private gestureCtrl: GestureController)
  {}//end ()

  ngAfterViewInit() {
    this.renderer.setStyle(this.element.nativeElement, 'position', 'absolute')
    this.renderer.setStyle(this.element.nativeElement, 'left', this.startLeft + 'px')
    this.renderer.setStyle(this.element.nativeElement, 'top', this.startTop + 'px')

    let hammer = new window['Hammer'](this.element.nativeElement)
    hammer.get('pan').set({ direction: window['Hammer'].DIRECTION_ALL })
    hammer.on('pan', (ev) => {
      console.log(this.TAG, 'pan', ev.center, screen, window.innerHeight, window.innerWidth, window, this.element.nativeElement)
      this.handlePan(ev)
    })

    const gesture = this.gestureCtrl.create({
      gestureName: 'drag',
      el: this.element.nativeElement,
      onMove: (detail) => { this.onMove(detail) }
    })
    gesture.enable()

  } //end ngAfterViewInit()

  handlePan(ev) {
    let newLeft = ev.center.x
    let newTop = ev.center.y

    if (newLeft < 1) newLeft = ev.center.x = 1
    if (newTop < 1) newTop = ev.center.y = 1

    if (newLeft > window.innerWidth - 60) newLeft = ev.center.x = window.innerWidth - 60
    if (newTop > window.innerHeight - 60) newTop = ev.center.y = window.innerHeight - 60


    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, 'left', newLeft + 'px')
      this.renderer.setStyle(this.element.nativeElement, 'top', newTop + 'px')
    })
  } //end handlePan()

  onMove(detail) {
    const ev = {
      ...detail,
      center: {
        x: detail.currentX,
        y: detail.currentY
      }
    }
    console.error(this.TAG, 'onMove()', detail, ev, screen)
    this.handlePan(ev)
  } //end onMove()


} //end DragAbsoluteDirective
