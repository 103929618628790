import { Component, OnInit, ViewChild } from '@angular/core'
import { IonReorderGroup, ModalController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth/auth.service'
import { DataService } from 'src/app/services/data/data.service'
import { DateService } from 'src/app/services/date/date.service'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { ModalService } from 'src/app/services/modal/modal.service'
import { UtilityService } from 'src/app/services/utility/utility.service'
import { BoominEventPagesComponent } from '../boomin-event-pages/boomin-event-pages.component'

@Component({
  selector: 'app-boomin-report-detail',
  templateUrl: './boomin-report-detail.component.html',
  styleUrls: ['./boomin-report-detail.component.scss'],
})
export class BoominReportDetailComponent implements OnInit {
  private TAG = 'BoominReportDetailComponent|'
  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup
  public description
  public report
  public isNew: boolean = false
  public name
  public orderIn = []
  public orderOut = []
  public pagesObj: any = {}
  public pageOptions = [
    { title: 'Optional', type: 'optional', disabled: false },
    { title: 'Photo', type: 'photo', disabled: false },
    { title: 'Repeatable', type: 'repeatable', disabled: false },
    // { title: 'Skip Whitelist', type: 'skipWhitelist', disabled: true }
  ]
  public segment = 'ordering'
  public siteKey

  constructor(
    private authService: AuthService,
    public dataService: DataService,
    public dateService: DateService,
    public loading: LoadingService,
    public modalCtrl: ModalController,
    public utilityService: UtilityService
  ) {} //end ()

  ngOnInit() {
    if (this.report != null) {
      this.isNew = false
      this.name = this.report.name
      this.description = this.report.description
      this.pagesObj = this.report.pagesObj
      Object.entries(this.pagesObj).forEach(([k, v]: any) => {
        if (!v.options) v.options = {}
        if (v.inIndex >= 0) this.orderIn[v.inIndex] = k
        if (v.outIndex >= 0) this.orderOut[v.outIndex] = k
      })
      console.log(this.TAG, 'pagesInIndex', this.orderIn)
      console.log(this.TAG, 'pagesOutIndex', this.orderOut)
      console.log(this.TAG, 'pagesObj', this.pagesObj)
    } else {
      this.description = ''
      this.report = {}
      this.isNew = true
      this.name = ''
      this.orderIn = [...new Set()]
      this.orderOut = [...new Set()]
      this.pagesObj = {}
    }
  } //end ngOnInit()

  doReorder(ev, side) {
    this[`pages${side}Index`] = ev.detail.complete()
  } //end doReorder()

  async pagesManage() {
    const modal = await this.modalCtrl.create({ component: BoominEventPagesComponent, componentProps: { report: this.report, pagesIn: this.orderIn, pagesOut: this.orderOut, currentPagesObj: this.pagesObj, siteKey: this.siteKey, modeSelect: true } })
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned.data && dataReturned.role == 'update') {
        console.log(this.TAG, 'pagesManage() update', { ...dataReturned.data }, this.orderIn, this.orderOut)
        // const data = dataReturned.data
        // this.orderIn = [...new Set(data.keysIn)]
        // this.orderOut = [...new Set(data.keysOut)]
        // this.pagesObj = { ...data.pages }
      } //end if (dataReturned.data && dataReturned.role == 'update')
    }) //end modal.onDidDismiss()
    return await modal.present()
  } //end pagesManage()

  changeOption(page, option) { page.options[option] = !page.options[option] } //end changeOptional()

  save(createCopy?) {
    const obj: any = this.authService.defaultDoc((this.isNew || createCopy))
    obj.name = this.name
    obj.description = this.description ? this.description : ''
    if (createCopy) obj.description != '' ? obj.description += ' Copy' : obj.description = 'Copy'
    obj.pagesObj = { ...this.pagesObj }
    obj.hasIn = this.orderIn.length > 0
    obj.hasOut = this.orderOut.length > 0
    obj.siteKey = this.siteKey
    delete obj.key
    this.isNew || createCopy ? this.create(obj) : this.edit(obj)
  } //end save()

  close() { this.modalCtrl.dismiss() } //end close()

  async create(report) {
    this.loading.present()
    await this.authService.firestore.collection(`boomin-reports`).add(report).then(() => {
      console.warn(this.TAG, 'REPORT ADDED', { ...report })
      this.authService.logEvent('boomin_report_add')
      this.loading.dismiss()
      this.modalCtrl.dismiss()
    }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //end this.auth.firestore.collection('boomin-reports')
  } //end create()

  async edit(report) {
    this.loading.present()
    await this.authService.firestore.doc(`boomin-reports/${this.report.key}`).set(report).then(() => {
      console.warn(this.TAG, 'REPORT MODIFIED', { ...report })
      this.authService.logEvent('boomin_report_modify')
      this.loading.dismiss()
      this.modalCtrl.dismiss()
    }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //catch(err)
  } //end edit()

  async delete() {
    this.loading.present()
    // await this.auth.firestore.collection('boomin-policies').where('reportsKeys', 'array-contains', this.report.key).get().then(async (querySnapshot) => {
    //   if (querySnapshot.exists) {
    //     this.loading.dismiss()
    //     this.utilityService.simpleAlert('Remove Event from Policies')
    //   } else {
    await this.authService.firestore.doc(`boomin-reports/${this.report.key}`).update({ ...this.authService.defaultDoc(), deleted: true }).then(async () => {
      await this.authService.firestore.doc(`boomin-reports/${this.report.key}`).delete().then(() => {
        this.authService.logEvent('boomin_report_remove')
        this.utilityService.simpleAlert('Event Removed')
        this.loading.dismiss()
        this.modalCtrl.dismiss()
      }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //await this.auth.firestore.doc(`boomin-reports/${this.report.key}`).delete().then()
    })
    // } //end if (querySnapshot)
    // }) //await this.auth.firestore.collection('boomin-policies').where('reportsKeys', 'array-contains', this.report.key).get()
  } //end delete()

}
