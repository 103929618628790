import { Component, NgZone } from '@angular/core'
import { ModalController, NavParams } from '@ionic/angular'
import { DataService } from 'src/app/services/data/data.service'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { AuthService } from '../../../services/auth/auth.service'
import { UtilityService } from '../../../services/utility/utility.service'

@Component({
  selector: 'app-alert-action',
  templateUrl: './alert-action.component.html',
  styleUrls: ['./alert-action.component.scss'],
})
export class AlertActionComponent {
  private TAG = 'AlertActionComponent|'
  public alert: any = {}
  public perm: any = {}
  public site: any = {}
  public user: any = {}
  public notifications: any = {}
  public scheduleAlerts: any = {}
  public patrolSchedules: any = []

  constructor(
    public authService: AuthService,
    private dataService: DataService,
    private loading: LoadingService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public utilityService: UtilityService,
    private zone: NgZone
  ) {

    this.perm = this.authService.user.permissions.perm1
    this.alert = this.navParams.data.alert
    this.site = this.navParams.data.site
    this.user = this.navParams.data.user
    console.log(this.TAG, 'Alert', this.alert)
    this.authService.logEvent('alert_view', { alertType: this.alert.alertType, alert: this.alert.alert, deviceKey: this.alert.deviceKey, deviceName: this.alert.deviceName, siteKey: this.alert.siteKey, siteName: this.alert.siteName })

    if (this.alert.alertType == 'fts' || this.alert.alertType == 'fp' || this.alert.alertType == 'ip' || this.alert.alertType == 'qp') {
      this.loading.present()
      if (this.alert.startTime) {
        if (!this.alert.sTime) {
          const sTime = new Date(this.alert.date)
          sTime.setHours(this.alert.startTime.split(':')[0], this.alert.startTime.split(':')[1], 0, 0)
          const eTime = new Date(this.alert.date)
          eTime.setHours(this.alert.endTime.split(':')[0], this.alert.endTime.split(':')[1], 0, 0)
          const alertDate = new Date(this.alert.date)
          if (sTime.getTime() > alertDate.getTime()) sTime.setDate(sTime.getDate() - 1)
          this.alert.sTime = sTime.getTime()
          this.alert.eTime = eTime.getTime()
        } //if (!this.alert.sTime)
        this.alert.shortDate = new Date(this.alert.sTime).toLocaleDateString('en-ZA').replace(/\//g, '-')
      } //if (alert.startTime)
      !this.scheduleAlerts ? this.scheduleAlerts = {} : ''
      !this.scheduleAlerts[this.alert.shortDate] ? this.scheduleAlerts[this.alert.shortDate] = {} : ''
      !this.scheduleAlerts[this.alert.shortDate][this.alert.scheduleID] ? this.scheduleAlerts[this.alert.shortDate][this.alert.scheduleID] = {} : ''
      this.scheduleAlerts[this.alert.shortDate][this.alert.scheduleID][this.alert.key] = this.alert

      this.loadNotifications().finally(() => this.sortPatrols(this.scheduleAlerts, this.notifications).finally(() => this.loading.dismiss()))
    }
  } //end ()

  getScheduleFromNotifications(source, property, checkpointKey, notifications, alerts) {
    let schedule
    if (notifications[source.shortDate][source.scheduleID]) {
      schedule = Object.keys(notifications[source.shortDate][source.scheduleID]).filter(key => {
        if (property == 'checkpointKey') { return notifications[source.shortDate][source.scheduleID][key][property] == checkpointKey ? true : false }
        if (property != 'checkpointKey') { return notifications[source.shortDate][source.scheduleID][key][property] ? true : false }
      }).reduce((obj, key) => { return notifications[source.shortDate][source.scheduleID][key] }, {})
      if (property == 'checkpointKey') { !schedule.checkpointKey ? schedule = false : '' }
      if (property == 'finishPatrol') { Object.keys(schedule).length === 0 ? schedule = false : schedule = true }
    } //end if (notifications[source.shortDate][source.scheduleID])

    return JSON.parse(JSON.stringify(schedule))
  } //end getScheduleFromNotifications()

  loadNotifications() {
    this.notifications = {}
    return new Promise<boolean>(async (resolve, reject) => {
      await this.authService.firestore.collection('notifications').where('scheduleID', '==', this.alert.scheduleID).where('sTime', '==', this.alert.sTime)
        .get().then(notificationsSnapshot => {
          if (!notificationsSnapshot.empty) {
            this.zone.run(() => {
              notificationsSnapshot.forEach((notificationData) => {
                const notification = { ...notificationData.data() as unknown as any, key: notificationData.id }
                if (!notification.sTime) {
                  const sTime = new Date(notification.date)
                  sTime.setHours(notification.startTime.split(':')[0], notification.startTime.split(':')[1], 0, 0)
                  const eTime = new Date(notification.date)
                  eTime.setHours(notification.endTime.split(':')[0], notification.endTime.split(':')[1], 0, 0)
                  const notificationDate = new Date(notification.date)
                  if (sTime.getTime() > notificationDate.getTime()) sTime.setDate(sTime.getDate() - 1)
                  notification.sTime = sTime.getTime()
                  notification.eTime = eTime.getTime()
                } //if (!notification.sTime)
                notification.shortDate = new Date(notification.sTime).toLocaleDateString('en-ZA').replace(/\//g, '-')
                !this.notifications[notification.shortDate] ? this.notifications[notification.shortDate] = {} : ''
                !this.notifications[notification.shortDate][notification.scheduleID] ? this.notifications[notification.shortDate][notification.scheduleID] = {} : ''
                this.notifications[notification.shortDate][notification.scheduleID][notification.key] = notification
              })
              resolve(true)
            }) //this.zone.run()
          } else {
            resolve(true)
          }
        }) //end await this.auth.firestore.collection('notifications').then(() => { this.sortData(notifications, alerts) })
    })
  } //end loadNotifications()

  sortPatrols(alerts, notifications) {
    let alertsObj = {}, notificationsObj = {}
    for (let day in alerts) {
      for (let scheduleID in alerts[day]) {
        for (let key in alerts[day][scheduleID]) {
          const schedule = alerts[day][scheduleID][key]
          if (schedule.alertType == 'fts' || schedule.alertType == 'fp' || schedule.alertType == 'ip' || schedule.alertType == 'qp') {
            alertsObj[day + scheduleID] = schedule
            break
          } //if (schedule.alertType == 'fts' || schedule.alertType == 'fp' || schedule.alertType == 'ip' || schedule.alertType == 'qp')
        } //for (let key in alerts[day][scheduleID])
      } //for (let scheduleID in alerts[day])
    } //for (let day in alerts)
    for (let day in notifications) {
      for (let scheduleID in notifications[day]) {
        for (let key in notifications[day][scheduleID]) {
          const schedule = notifications[day][scheduleID][key]
          if (schedule.startPatrol) {
            notificationsObj[day + scheduleID] = schedule
            break
          } //if (schedule.startPatrol)
        } //for (let key in notifications[day][scheduleID])
      } //for (let scheduleID in notifications[day])
    } //for (let day in notifications)
    const schedules = [...new Set([...Object.keys(notificationsObj), ...Object.keys(alertsObj)])]
    this.patrolSchedules = []
    let totalCheckpoints = 0
    let totalScanned = 0
    let categoryCount = { completed: 0, failed: 0, fts: 0, incomplete: 0, quick: 0 }
    let count = 0
    schedules.forEach(scheduleID => {
      let schedule = notificationsObj[scheduleID] ? notificationsObj[scheduleID] : alertsObj[scheduleID]
      if (schedule) {
        schedule.showMore = false
        totalCheckpoints += schedule.checkpoints.length
        schedule.scanned = 0
        schedule.categoryColor = 'light'
        if (schedule.startPatrol) {
          const checkpointsDone = []
          schedule.checkpoints.forEach(checkpoint => {
            const checkpointDone = this.getScheduleFromNotifications(schedule, 'checkpointKey', checkpoint.key, notifications, alerts)
            if (checkpointDone) checkpointDone.arrived = new Date(checkpointDone.date).toLocaleTimeString('en-ZA').slice(0, -3)
            if (checkpointDone.arrived) checkpoint.category = 'Completed', checkpoint.categoryColor = 'success'
            else checkpoint.category = 'Failed', checkpoint.categoryColor = 'danger'
            checkpointsDone.push(checkpointDone)
          }) //schedule.checkpoints.forEach(checkpoint)

          schedule.checkpointsDone = checkpointsDone
          const scannedChkpoints = checkpointsDone.filter(d => d != false ? true : false).length
          schedule.scanned = scannedChkpoints
          let totalScanned
          totalScanned += scannedChkpoints
          const completed = this.getScheduleFromNotifications(schedule, 'finishPatrol', null, notifications, alerts)
          const alertType = alertsObj[scheduleID] ? alertsObj[scheduleID].alertType : false
          const isIncomplete = (scannedChkpoints > 0 && scannedChkpoints < schedule.checkpoints.length)
          if (completed && alertType != 'qp' && !isIncomplete) schedule.category = 'Completed', schedule.categoryColor = 'success', ++categoryCount.completed
          if (completed && alertType == 'qp' && !isIncomplete) schedule.category = 'Quick', schedule.categoryColor = 'secondary', ++categoryCount.quick
          if ((!completed && alertType == 'ip') || isIncomplete) schedule.category = 'Incomplete', schedule.categoryColor = 'warning', ++categoryCount.incomplete
          if ((!completed && alertType == 'fp') || (!completed && !alertType && !isIncomplete)) schedule.category = 'Failed', schedule.categoryColor = 'danger', ++categoryCount.failed
          if (!schedule.category) {
            const count = checkpointsDone.filter(d => d != false ? true : false).length
            if (count == 0) {
              schedule.category = 'Failed', schedule.categoryColor = 'danger', ++categoryCount.failed
            } else if (count > 0 && count < schedule.checkpoints.length) {
              schedule.category = 'Incomplete', schedule.categoryColor = 'warning', ++categoryCount.incomplete
            } else if (count == schedule.checkpoints.length) {
              if (alertType) schedule.category = 'Quick', schedule.categoryColor = 'secondary', ++categoryCount.quick
              if (!alertType) schedule.category = 'Completed', schedule.categoryColor = 'success', ++categoryCount.completed
            }
          } //end if (!schedule.category)
        } else {
          schedule.checkpointsDone = []
          schedule.checkpoints.forEach(checkpoint => {
            schedule.checkpointsDone.push(false)
            checkpoint.category = 'Failed', checkpoint.categoryColor = 'danger'
            Object.entries(this.dataService.checkpointsObj).forEach(([key, value]: any) => {
              if (value.key == checkpoint.key) checkpoint.name = value.name
            })
          })
          schedule.category = 'FTS', schedule.categoryColor = 'danger', ++categoryCount.fts
        } //end if (schedule.startPatrol)
        schedule.arrived = schedule.category == 'FTS' ? '-' : new Date(schedule.date).toLocaleTimeString('en-ZA').slice(0, -3)
        schedule.startDate = new Date(schedule.sTime).toLocaleDateString('en-ZA').replace(/\//g, '-')
        schedule.endDate = new Date(schedule.eTime).toLocaleDateString('en-ZA').replace(/\//g, '-')
        schedule.percent = ((schedule.scanned / schedule.checkpoints.length) * 100).toFixed(1) + '%'
        schedule.planned = schedule.startTime + ' to ' + schedule.endTime
        schedule.schedule = schedule.startDate + ' ' + schedule.startTime + ' to ' + schedule.endDate + ' ' + schedule.endTime
        schedule.scheduleDetailName = schedule.patrolName + ' --> ' + schedule.startTime + ' to ' + schedule.endTime
        schedule.status = schedule.category == 'FTS' ? 'Failed' : 'Accepted'
        schedule.totalCheckpoints = schedule.checkpoints.length
        if (!schedule.category) console.log(this.TAG, '*********************', schedule.category)
        if (schedule.guards) { // This is for the pdf report
          schedule.lastRow = [{ text: schedule.guards.length > 1 ? 'Guards On Patrol: ' : 'Guard On Patrol: ', bold: true, color: '#3B7491' }]
          schedule.guards.forEach((guard, key) => {
            if (key == 0) {
              schedule.lastRow.push(guard.empID + ' | ' + guard.fname + ' ' + guard.lname)
            } else {
              schedule.lastRow.push(', ' + guard.empID + ' | ' + guard.fname + ' ' + guard.lname)
            }
          }) //schedule.guards.forEach(guard)
        } //if (schedule.guards)
        const alert = alertsObj[scheduleID] ? JSON.parse(JSON.stringify(alertsObj[scheduleID])) : false
        schedule.showMore = true
        this.patrolSchedules.push(schedule)
      } //end if (schedule)
    }) //schedules.forEach(scheduleID)
    this.patrolSchedules.sort((a, b) => {
      if (a.startDate > b.startDate) return -1
      if (a.startDate < b.startDate) return 1
      if (a.startTime > b.startTime) return -1
      if (a.startTime < b.startTime) return 1
      return 0
    })
    console.log(this.TAG, this.patrolSchedules)
    return Promise.resolve()
  } //end sortPatrols()

  save(alert) {
    this.dismiss(alert)
  }

  showLog(log) {
    log.showMore = !log.showMore
  } //end showLog()

  cancel() {
    this.dismiss()
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data)
  }

}
