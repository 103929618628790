import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { NavParams, PopoverController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth/auth.service'

@Component({
  selector: 'app-patrol-popover',
  templateUrl: './patrol-popover.component.html',
  styleUrls: ['./patrol-popover.component.scss'],
})
export class PatrolPopoverComponent {
  private imei
  public patrols: any[] = []
  public perm: any = {}

  constructor(
    public authService: AuthService,
    public navParams: NavParams,
    public popoverController: PopoverController,
    private router: Router
  ) {
    this.patrols = this.navParams.data.patrols
    this.imei = this.navParams.data.imei
    this.perm = this.authService.user.permissions.perm2.a5
  } //end ()

  patrolView(patrol?) {
    this.router.navigateByUrl(`/tabs/devices/device/${this.imei}/patrol/${patrol ? patrol.key : 'add'}`)
    this.dismiss()
  }

  dismiss(data?: any) {
    this.popoverController.dismiss(data)
  }

}
