import { KeyValue } from '@angular/common'
import { Component, NgZone } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth/auth.service'

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent {
  val = ''
  companies = {}

  constructor(
    public authService: AuthService,
    private popoverController: PopoverController,
    private zone: NgZone
  ) {
    this.searchObj()
  } //end ()

  dismiss(data?: any) { this.popoverController.dismiss({ companyDB: data }) } //end dismiss()

  searchObj(event?) {
    this.zone.run(() => {
      const val = !event ? '' : event?.target ? event.target.value ? event.target.value.toLowerCase() : '' : event ? event.toLowerCase() : ''
      this.companies = Object.keys(this.authService.companiesObj).filter(key => this.authService.companiesObj[key].name.toLowerCase().indexOf(val) !== -1 || key.toLowerCase().indexOf(val) !== -1 || !val).reduce((obj, key) => { return { ...obj, [key]: this.authService.companiesObj[key] } }, {})
    }) //this.zone.run()
  } //end searchObj()

  orderByNameAsc = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => { return a.value.name > b.value.name ? 1 : (b.value.name > a.value.name ? -1 : 0) } //end orderByNameAsc()
}
