import { Injectable } from '@angular/core'
import { customAlphabet } from 'nanoid/async'

@Injectable({
  providedIn: 'root'
})
export class IdService {
  private TAG = 'IdService|'
  private chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  private nanoid = customAlphabet(this.chars, 20)

  constructor() {
    console.error(this.TAG, '@@@ ID Service Init Complete @@@')
    // this.makeID(12).then(val=>console.error(this.TAG, 'maklsd asldknasd aslk ',val))
  } //end ()

  async makeID(size = 24): Promise<string> {
    return await this.nanoid(size)
  } //end makeID

} //end IdService