import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PopoverController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth/auth.service'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { UtilityService } from 'src/app/services/utility/utility.service'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  private TAG = 'ChangePasswordComponent|'
  public passwordMatch: boolean = true
  public passwordOK: boolean = false
  public passwordShow: boolean = false
  public passwordStrength: any = ''
  public formPassword


  public form: FormGroup
  public user: any = {}

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private popoverController: PopoverController,
    public utilityService: UtilityService,
  ) {
    console.log(this.TAG, '()')
    this.passwordShow = false
    this.formPassword = this.formBuilder.group({
      password: ['', Validators.compose([Validators.minLength(6), Validators.required])],
      passwordConfirm: ['', Validators.compose([Validators.minLength(6), Validators.required])]
    })

    this.formPassword.controls.password.valueChanges.subscribe((password) => {
      let strength = 0
      if (password) {
        if (password.length < 8) {
          this.passwordStrength = 'too short'
          this.passwordOK = false
        } else {
          if (password.length > 7) strength += 1
          if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) strength += 1
          if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) strength += 1
          if (password.match(/([!,%,&,@,#,$,^,*,?,_,~,{,},/,\],/,[,<,>])/)) strength += 1
          if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~,{,},/,\],/,[,<,>].*[!,%,&,@,#,$,^,*,?,_,~,{,},/,\],/,[,<,>])/)) strength += 1

          if (strength < 4) {
            this.passwordStrength = 'weak'
            this.passwordOK = false
          } else if (strength == 4) {
            this.passwordStrength = 'good'
            this.passwordOK = true
          } else if (strength > 4) {
            this.passwordStrength = 'strong'
            this.passwordOK = true
          }
        }
      } else {
        this.passwordStrength = ''
        this.passwordOK = false
      }
    })

    this.formPassword.controls.passwordConfirm.valueChanges.subscribe((password) => {
      if (password) {
        if (password == this.formPassword.value.password) {
          this.passwordMatch = true
        } else { this.passwordMatch = false }
      } else { this.passwordMatch = true }
    })
  } //end ()

  update() {
    console.log(this.TAG, this.formPassword)
    const cmd = {
      password: this.formPassword.value.password, userID: this.user.userID
}
    this.loading.present()
    const callableUserModifyPassword = this.authService.aff.httpsCallable('userModifyPassword')
    callableUserModifyPassword(cmd).subscribe((res) => {
      this.loading.dismiss()
      console.info(this.TAG, 'callaUserModifyPassword', res)
      this.authService.logEvent('user_modify_password')
      this.utilityService.simpleAlert('The user password has been successfully updated!').then(() => this.dismiss())
    }, (err) => {
      this.loading.dismiss()
      console.error(this.TAG, 'callaUserModifyPassword ERROR:', err, err?.message)
      this.utilityService.simpleAlert(err.message)
    }) //end callableUserModifyPassword()
  }

  dismiss() {
    this.popoverController.dismiss()
  }
}
