import { Injectable } from '@angular/core'
import { LoadingController } from '@ionic/angular'
import { NgxSpinnerService } from 'ngx-spinner'

@Injectable({ providedIn: 'root' })
export class LoadingService {
  isLoading = false

  constructor(
    public loadingController: LoadingController,
    public spinner: NgxSpinnerService
  ) {} //end ()

  async present(time?) {
    if (this.isLoading) {
      await this.spinner.hide()
      this.isLoading = false
      this.present(time)
    }

    this.isLoading = true
    await this.spinner.show()
    const duration = time ? time : 10000
    setTimeout(() => {
      this.spinner.hide()
      this.isLoading = false
    }, duration) //setTimeout()
  }

  async dismiss() {
    if (this.isLoading) {
      await this.spinner.hide()
      this.isLoading = false
    }
  }
}