import { KeyValue } from '@angular/common'
import { Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { AlertController, ModalController } from '@ionic/angular'
import { ViewerModalComponent } from 'ngx-ionic-image-viewer'
import { ContactDetailPage } from 'src/app/pages/settings/contacts/contact-detail/contact-detail.page'
import { AuthService } from '../auth/auth.service'
import { DataService } from '../data/data.service'
import { LoadingService } from '../loading/loading.service'

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private TAG = 'UtilityService|'
  public alertsObj: any = {}
  public customPopoverOptions: any = { animated: true, cssClass: 'popover-custom', mode: 'md', translucent: true }
  countItems: number = 0
  countAlertObjects: number = 0
  countObjects: number = 0
  countObjectUsnassigned: number = 0
  public days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  public incidentsObj: any = {}
  public items: any[] = [] as any
  private searchKeysIgnore = [
    'auto', 'autoField',
    'boomin', 'boominOnly',
    'categoryColor', 'checkInChecklist', 'checklists',
    'dateCreated', 'dateModified', 'dbDate', 'dbTimestamp', 'deviceKey',
    'eventIndex', 'boominKey', 'eventsKeys', 'eventsObj',
    'hasIn', 'hasOut', 'hasScanHardware',
    'ID', 'identifier', 'image', 'imageUrl', 'img', 'incidentPolicyKey', 'inIndex', 'isChecked', 'isValid',
    'key',
    'list', 'login',
    'manual', 'mode',
    'outIndex',
    'pagessKeys', 'pagessObj', 'photo', 'policyKey',
    'remoteLogs', 'report',
    'scanPlugin', 'selected', 'settings', 'showMore', 'siteKey',
    'thumb', 'thumbUrl', 'timestamp', 'token', 'type',
    'userID',
  ]
  public objects: any = {}
  public objectsUnassigned: any = {}
  public saveError: string
  private tolerance = 0

  constructor(
    private alertController: AlertController,
    public authService: AuthService,
    public dataService: DataService,
    public loading: LoadingService,
    private modalCtrl: ModalController,
    public router: Router,
    private zone: NgZone
  ) {
    if (this.authService.showError) console.error(this.TAG, '@@@ Utility Service Init Complete @@@')
  } //end ()

  checkExistingCheckpointTagID(tagID, checkpointCurrent?) {
    return new Promise((resolve, reject) => {
      const checkpoint: any = Object.values(this.dataService.checkpointsObj).find((checkpoint: any) => checkpoint.tagID === tagID)
      if ((checkpoint && checkpointCurrent && checkpoint.key != checkpointCurrent.key) || (checkpoint && !checkpointCurrent)) reject(checkpoint)
      else resolve(false)
    })
  } //end checkExistingCheckpointTagID()

  checkExistingGuardEmpID(empID, guardCurrent?) {
    return new Promise((resolve, reject) => {
      const guard: any = Object.values(this.dataService.guardsObj).find((gaurd: any) => gaurd.empID === empID)
      if ((guard && guardCurrent && guard.key != guardCurrent.key) || (guard && !guardCurrent)) reject(guard)
      else resolve(false)
    })
  } //end checkExistingGuardEmpID()

  checkExistingGuardTagID(tagID, guardCurrent?) {
    return new Promise((resolve, reject) => {
      const guard: any = Object.values(this.dataService.guardsObj).find((gaurd: any) => gaurd.tagID === tagID)
      if ((guard && guardCurrent && guard.key != guardCurrent.key) || (guard && !guardCurrent)) reject(guard)
      else resolve(false)
    })
  } //end checkExistingGuardTagID()

  checkOnceOff(data, type?) {
    this.tolerance = data.tolerance ? data.tolerance : 0
    const patrolName = data.patrolName, schedule = data.schedule, today = data.today, scheduledDate = new Date(schedule.date)
    //------------------------------------------------------- recurrenceDate ------------------------------------------------------------------
    const recurrenceDay = this.days.indexOf(data.dayName)
    let recurrenceDate
    if (!type) {
      const rDate = new Date(schedule.date)
      if (recurrenceDay == rDate.getDay()) {
        recurrenceDate = rDate.toJSON().split('T')[0]
      } else if (recurrenceDay > rDate.getDay()) {
        const dayDiff = recurrenceDay - rDate.getDay()
        recurrenceDate = new Date(rDate.setDate(rDate.getDate() + dayDiff)).toJSON().split('T')[0]
      } else if (recurrenceDay < rDate.getDay()) {
        const dayDiff = rDate.getDay() - recurrenceDay
        recurrenceDate = new Date(rDate.setDate(rDate.getDate() - dayDiff)).toJSON().split('T')[0]
      }
    } //end if (!type)
    //------------------------------------------- nextScheduledDate & previousScheduledDate-----------------------------------------------------
    const nextDate = new Date(schedule.date), previousDate = new Date(schedule.date)
    const nextScheduledDate = new Date(nextDate.setDate(nextDate.getDate() + 1)).toJSON().split('T')[0], previousScheduledDate = new Date(previousDate.setDate(previousDate.getDate() - 1)).toJSON().split('T')[0]
    //------------------------------------------------------------------------------------------------------------------------------------------
    let proceed = true, oSchedule, timeData, checkDate
    if (data.oSchedule) oSchedule = data.oSchedule
    if (type == 'checkSchedule') {
      timeData = this.getPatrolTime(data.currentTime, data.time, schedule.startTime, schedule.endTime, type)
    } else if (type == 'checkAllSchedule') {
      timeData = this.getPatrolTime(oSchedule.endTime, oSchedule.startTime, schedule.startTime, schedule.endTime, type)
    } else { timeData = this.getPatrolTime(data.currentTime, data.recurrenceStartTime, schedule.startTime, schedule.endTime) }
    const eventStart = timeData.eventStart, start = timeData.start, end = timeData.end, eventEnd = timeData.eventEnd, newEnd = timeData.newEnd, newEventEnd = timeData.newEventEnd, eventDate = data.eventDate ? data.eventDate : false
    if (oSchedule) {
      checkDate = oSchedule.date
    } else { checkDate = eventDate ? eventDate : recurrenceDate }
    if (((scheduledDate.getTime() + 2880 * 60000) > today.getTime()) && proceed) { //Checking for a 48hr period just in case patrol passes midnight
      if ((schedule.date == checkDate) && (schedule.ID != oSchedule?.ID)) {
        if ((eventStart.getTime() >= start.getTime()) && (eventStart.getTime() <= end.getTime())) {
          proceed = false
          if (type == 'checkAllSchedule') {
            this.saveError = patrolName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' already has a schedule during the following time frame ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
          } else { this.simpleAlert(patrolName + ' already has a schedule during the chosen time frame ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
        } else if ((eventEnd.getTime() >= start.getTime()) && (eventEnd.getTime() <= end.getTime())) {
          proceed = false
          if (type == 'checkAllSchedule') {
            this.saveError = patrolName + ' ' + oSchedule.date + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
          } else { this.simpleAlert('This schedule\'s end time will clash with ' + patrolName + ' already scheduled on ' + checkDate + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
        } else if (eventStart.getTime() > eventEnd.getTime() && (eventStart.getTime() >= start.getTime()) && (eventStart.getTime() <= end.getTime())) {
          if ((eventStart.getTime() >= start.getTime()) && (eventStart.getTime() <= newEnd)) {
            proceed = false
            if (type == 'checkAllSchedule') {
              this.saveError = patrolName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' already has a schedule during the following time frame ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
            } else { this.simpleAlert(patrolName + ' already has a schedule during the chosen time frame ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
          } else if ((newEventEnd >= start.getTime()) && (newEventEnd <= newEnd)) {
            proceed = false
            if (type == 'checkAllSchedule') {
              this.saveError = patrolName + ' ' + oSchedule.date + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
            } else { this.simpleAlert('This schedule\'s end time will clash with ' + patrolName + ' already scheduled on ' + checkDate + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
          }
        } else if ((eventStart.getTime() <= start.getTime()) && (eventStart.getTime() <= eventEnd.getTime()) && (eventEnd.getTime() > start.getTime())) {
          proceed = false
          if (type == 'checkAllSchedule') {
            this.saveError = patrolName + ' ' + oSchedule.date + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
          } else { this.simpleAlert('This schedule\'s end time will clash with ' + patrolName + ' already scheduled on ' + checkDate + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
        } else if ((start.getTime() >= end.getTime()) && (eventStart.getTime() >= eventEnd.getTime()) && (eventStart.getTime() <= newEnd)) {
          proceed = false
          if (type == 'checkAllSchedule') {
            this.saveError = patrolName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' already has a schedule during the following time frame ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
          } else { this.simpleAlert(patrolName + ' already has a schedule during the chosen time frame ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
        }
      } else if (previousScheduledDate == checkDate) {
        if (eventStart.getTime() > eventEnd.getTime() && eventEnd.getTime() > start.getTime()) {
          proceed = false
          if (type == 'checkAllSchedule') {
            this.saveError = patrolName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' already has a schedule during the following time frame on ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
          } else { this.simpleAlert(patrolName + ' already has a schedule during the chosen time frame on ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
        }
      } else if (nextScheduledDate == checkDate) {
        if (start.getTime() > end.getTime() && end.getTime() > eventStart.getTime()) {
          proceed = false
          if (type == 'checkAllSchedule') {
            this.saveError = patrolName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' already has a schedule during the following time frame on ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
          } else { this.simpleAlert(patrolName + ' already has a schedule during the chosen time frame on ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
        }
      }
    }

    return proceed
  } //end checkOnceOff()

  checkAllSchedulesOnce(data) {
    let proceed = true
    this.tolerance = data.tolerance ? data.tolerance : 0
    const oSchedule = data.oSchedule, patrolName = data.patrolName, weeklyName = data.weeklyName, oPatrolName = data.oPatrolName
    for (let i = 0; i < data.onceSchedules.length; i++) {
      const schedule = data.onceSchedules[i]
      const dbDate = new Date(schedule.date)
      const recurrenceDay = this.days.indexOf(data.weeklyName)
      let recurrenceDate
      if (recurrenceDay == dbDate.getDay()) {
        recurrenceDate = dbDate.toJSON().split('T')[0]
      } else if (recurrenceDay > dbDate.getDay()) {
        const dayDiff = recurrenceDay - dbDate.getDay()
        recurrenceDate = new Date(dbDate.setDate(dbDate.getDate() + dayDiff)).toJSON().split('T')[0]
      } else if (recurrenceDay < dbDate.getDay()) {
        const dayDiff = dbDate.getDay() - recurrenceDay
        recurrenceDate = new Date(dbDate.setDate(dbDate.getDate() - dayDiff)).toJSON().split('T')[0]
      }
      const nextDate = new Date(schedule.date), previousDate = new Date(schedule.date)
      const nextScheduledDate = new Date(nextDate.setDate(nextDate.getDate() + 1)).toJSON().split('T')[0], previousScheduledDate = new Date(previousDate.setDate(previousDate.getDate() - 1)).toJSON().split('T')[0]
      const timeData = this.getPatrolTime(oSchedule.endTime, oSchedule.startTime, schedule.startTime, schedule.endTime, 'checkAllSchedule')
      const eventStart = timeData.eventStart, start = timeData.start, end = timeData.end, eventEnd = timeData.eventEnd, newEnd = timeData.newEnd, newEventEnd = timeData.newEventEnd
      if (schedule.date == recurrenceDate) {
        if ((eventStart.getTime() >= start.getTime()) && (eventStart.getTime() <= end.getTime())) {
          proceed = false
          this.saveError = patrolName + ' ' + weeklyName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' recurrance schedule will clash with ' + oPatrolName + ' ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
        } else if ((eventEnd.getTime() >= start.getTime()) && (eventEnd.getTime() <= end.getTime())) {
          proceed = false
          this.saveError = patrolName + ' ' + weeklyName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' recurrance schedule will clash with ' + oPatrolName + ' ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
        } else if (eventStart.getTime() > eventEnd.getTime() && (eventStart.getTime() >= start.getTime()) && (eventStart.getTime() <= end.getTime())) {
          if ((eventStart.getTime() >= start.getTime()) && (eventStart.getTime() <= newEnd)) {
            proceed = false
            this.saveError = patrolName + ' ' + weeklyName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' recurrance schedule will clash with ' + oPatrolName + ' ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
          } else if ((newEventEnd >= start.getTime()) && (newEventEnd <= newEnd)) {
            proceed = false
            this.saveError = patrolName + ' ' + weeklyName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' recurrance schedule will clash with ' + oPatrolName + ' ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
          }
        } else if ((eventStart.getTime() <= start.getTime()) && (eventStart.getTime() <= eventEnd.getTime()) && (eventEnd.getTime() > start.getTime())) {
          proceed = false
          this.saveError = patrolName + ' ' + weeklyName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' recurrance schedule will clash with ' + oPatrolName + ' ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
        } else if ((start.getTime() >= end.getTime()) && (eventStart.getTime() >= eventEnd.getTime()) && (eventStart.getTime() <= newEnd)) {
          proceed = false
          this.saveError = patrolName + ' ' + weeklyName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' recurrance schedule will clash with ' + oPatrolName + ' ' + schedule.date + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
        }
      } else if (previousScheduledDate == recurrenceDate) {
        if (eventStart.getTime() > eventEnd.getTime() && eventEnd.getTime() > start.getTime()) {
          proceed = false
          this.saveError = patrolName + ' ' + weeklyName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' recurrance schedule will clash with ' + oPatrolName + ' ' + previousScheduledDate + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
        }
      } else if (nextScheduledDate == recurrenceDate) {
        if (start.getTime() > end.getTime() && end.getTime() > eventStart.getTime()) {
          proceed = false
          this.saveError = patrolName + ' ' + weeklyName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' recurrance schedule will clash with ' + oPatrolName + ' ' + nextScheduledDate + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
        }
      } //end if (schedule.date == recurrenceDate)

      if (!proceed) break
    } //end for (let i = 0; i < data.onceSchedules.length; i++)

    return proceed
  } //end checkAllSchedulesOnce()

  checkRecurring(data, type?) {
    this.tolerance = data.tolerance ? data.tolerance : 0
    let proceed = true, previousDay, nextDay, oSchedule, oScheduledDate
    if (data.oSchedule) oSchedule = data.oSchedule
    if (data.oSchedule) oScheduledDate = new Date(oSchedule.date)
    let day = data.day ? data.day : ''
    let dayName
    if (!data.checkAllScheduleOnceSchedules) dayName = data.dayName ? data.dayName : this.days[oScheduledDate.getDay()]
    const index = data.index ? data.index : '', patrolName = data.patrolName

    if (data.current) {
      if (index != 0 && index !== -1) previousDay = data.recurringSchedules[index - 1].name
      if (index + 1 != data.recurringSchedules.length && index !== -1) nextDay = data.recurringSchedules[index + 1].name
    } else if (data.checkAllSchedule) {
      if (oScheduledDate.getDay() != 0) previousDay = this.days[oScheduledDate.getDay() - 1]
      if (oScheduledDate.getDay() != 6) nextDay = this.days[oScheduledDate.getDay() + 1]
    } else {
      const recurrenceDay = this.days.indexOf(dayName)
      if (recurrenceDay != 0) previousDay = this.days[recurrenceDay - 1]
      if (recurrenceDay != 6) nextDay = this.days[recurrenceDay + 1]
    } //end if (data.current)

    if (data.checkAllScheduleOnceSchedules) {
      proceed = this.checkAllSchedulesOnce(data)
    } else {
      for (let i = 0; i < data.daySchedule.length; i++) {
        const schedule = data.daySchedule[i]
        let timeData
        if (type == 'checkSchedule') {
          timeData = this.getPatrolTime(data.currentTime, data.time, schedule.startTime, schedule.endTime, type)
        } else if (type == 'checkAllSchedule') {
          timeData = this.getPatrolTime(oSchedule.endTime, oSchedule.startTime, schedule.startTime, schedule.endTime, type)
        } else { timeData = this.getPatrolTime(data.currentTime, data.recurrenceStartTime, schedule.startTime, schedule.endTime) }
        const eventStart = timeData.eventStart, start = timeData.start, end = timeData.end, eventEnd = timeData.eventEnd, newEnd = timeData.newEnd, newEventEnd = timeData.newEventEnd
        if ((day == dayName) && (schedule.ID != oSchedule?.ID)) {
          if ((eventStart.getTime() >= start.getTime()) && (eventStart.getTime() <= end.getTime())) {
            proceed = false
            this.checkRecurringAlert(1, { day: day, dayName: dayName, oPatrolName: data.oPatrolName, oSchedule: oSchedule, patrolName: patrolName, schedule: schedule, type: type }, data?.checkAllScheduleAlert)
          } else if ((eventEnd.getTime() >= start.getTime()) && (eventEnd.getTime() <= end.getTime()) && (eventStart.getTime() <= eventEnd.getTime())) {
            proceed = false
            this.checkRecurringAlert(2, { day: day, dayName: dayName, oPatrolName: data.oPatrolName, oSchedule: oSchedule, patrolName: patrolName, schedule: schedule, type: type }, data?.checkAllScheduleAlert)
          } else if (eventStart.getTime() > eventEnd.getTime() && (eventStart.getTime() >= start.getTime()) && (eventStart.getTime() <= end.getTime())) {
            if (eventStart.getTime() <= newEnd) {
              proceed = false
              this.checkRecurringAlert(3, { day: day, dayName: dayName, oPatrolName: data.oPatrolName, oSchedule: oSchedule, patrolName: patrolName, schedule: schedule, type: type }, data?.checkAllScheduleAlert)
            } else if ((newEventEnd >= start.getTime()) && (newEventEnd <= newEnd)) {
              proceed = false
              this.checkRecurringAlert(4, { day: day, dayName: dayName, oPatrolName: data.oPatrolName, oSchedule: oSchedule, patrolName: patrolName, schedule: schedule, type: type }, data?.checkAllScheduleAlert)
            }
          } else if ((eventStart.getTime() <= start.getTime()) && (eventStart.getTime() <= eventEnd.getTime()) && (eventEnd.getTime() > start.getTime())) {
            proceed = false
            this.checkRecurringAlert(5, { day: day, dayName: dayName, oPatrolName: data.oPatrolName, oSchedule: oSchedule, patrolName: patrolName, schedule: schedule, type: type }, data?.checkAllScheduleAlert)
          } else if ((start.getTime() >= end.getTime()) && (eventStart.getTime() >= eventEnd.getTime()) && (eventStart.getTime() <= newEnd)) {
            proceed = false
            this.checkRecurringAlert(6, { day: day, dayName: dayName, oPatrolName: data.oPatrolName, oSchedule: oSchedule, patrolName: patrolName, schedule: schedule, type: type }, data?.checkAllScheduleAlert)
          }
        } else if (previousDay == day) {
          if (start.getTime() > end.getTime() && end.getTime() > eventStart.getTime()) {
            proceed = false
            this.checkRecurringAlert(7, { day: day, dayName: dayName, oPatrolName: data.oPatrolName, oSchedule: oSchedule, patrolName: patrolName, previousDay: previousDay, schedule: schedule, type: type }, data?.checkAllScheduleAlert)
          }
        } else if (nextDay == day) {
          if (eventStart.getTime() > eventEnd.getTime() && eventEnd.getTime() > start.getTime()) {
            proceed = false
            this.checkRecurringAlert(8, { day: day, dayName: dayName, nextDay: nextDay, oPatrolName: data.oPatrolName, oSchedule: oSchedule, patrolName: patrolName, schedule: schedule, type: type }, data?.checkAllScheduleAlert)
          }
        }
        if (!proceed) break
      } //end for (let i = 0; i < data.daySchedule.length; i++)
    } //end if (data.checkAllScheduleOnceSchedules)

    return proceed
  } //end checkRecurring()

  checkRecurringAlert(id, data, alertType?) {
    const day = data.day, dayName = data.dayName, oPatrolName = data.oPatrolName, nextDay = data.nextDay, oSchedule = data.oSchedule, patrolName = data.patrolName, previousDay = data.previousDay, schedule = data.schedule, type = data.type
    if (id == 1) {
      if (alertType == 1) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (alertType == 2) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (type == 'checkAllSchedule') {
        this.saveError = patrolName + ' ' + oSchedule.date + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with the recurrance for ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else { this.simpleAlert(patrolName + ' already has a schedule during the chosen time frame ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
    } else if (id == 2) {
      if (alertType == 1) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (alertType == 2) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (type == 'checkAllSchedule') {
        this.saveError = patrolName + ' ' + oSchedule.date + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with the recurrance for ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else { this.simpleAlert('This schedule\'s end time will clash with ' + patrolName + ' already scheduled on ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
    } else if (id == 3) {
      if (alertType == 1) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (alertType == 2) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (type == 'checkAllSchedule') {
        this.saveError = patrolName + ' ' + oSchedule.date + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with the recurrance for ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else { this.simpleAlert(patrolName + ' already has a schedule during the chosen time frame ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
    } else if (id == 4) {
      if (alertType == 1) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (alertType == 2) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (type == 'checkAllSchedule') {
        this.saveError = patrolName + ' ' + oSchedule.date + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with the recurrance for ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else { this.simpleAlert('This schedule\'s end time will clash with ' + patrolName + ' already scheduled on ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
    } else if (id == 5) {
      if (alertType == 1) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (alertType == 2) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (type == 'checkAllSchedule') {
        this.saveError = patrolName + ' ' + oSchedule.date + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with the recurrance for ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else { this.simpleAlert('This schedule\'s end time will clash with ' + patrolName + ' already scheduled on ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
    } else if (id == 6) {
      if (alertType == 1) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (alertType == 2) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (type == 'checkAllSchedule') {
        this.saveError = patrolName + ' ' + oSchedule.date + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with the recurrance for ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else { this.simpleAlert(patrolName + ' already has a schedule during the chosen time frame ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
    } else if (id == 7) {
      if (alertType == 1) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + previousDay + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (alertType == 2) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + previousDay + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (type == 'checkAllSchedule') {
        this.saveError = patrolName + ' ' + previousDay + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with the recurrance for ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else { this.simpleAlert(patrolName + ' already has a schedule during the chosen time frame on ' + previousDay + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
    } else if (id == 8) {
      if (alertType == 1) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + nextDay + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (alertType == 2) {
        this.saveError = patrolName + ' ' + dayName + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with ' + nextDay + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else if (type == 'checkAllSchedule') {
        this.saveError = patrolName + ' ' + nextDay + ' ' + oSchedule.startTime + ' - ' + oSchedule.endTime + ' has a schedule that will clash with the recurrance for ' + oPatrolName + ' ' + day + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!'
      } else { this.simpleAlert(patrolName + ' already has a schedule during the chosen time frame on ' + nextDay + ' ' + schedule.startTime + ' - ' + schedule.endTime + ' +' + this.tolerance + ' minutes tolerance!') }
    }
  } //end checkRecurringAlert()

  comparer(otherArray) {
    return current => {
      return otherArray.filter(other => {
        return other.key == current.key
      }).length == 0
    }
  } //end comparer()

  dateDiff(startDate, endDate) {
    const a = new Date(startDate)
    const b = new Date(endDate)
    const _MS_PER_DAY = 1000 * 60 * 60 * 24
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
    return Math.floor((utc2 - utc1) / _MS_PER_DAY)
  } //end dateDiff()

  enterPin(msg) {
    return new Promise<void>(async (resolve, reject) => {
      if (!this.authService.isDev) {
        const alert = await this.alertController.create({
          backdropDismiss: false,
          buttons: [{ text: 'Cancel', },
          {
            text: msg,
            handler: async (data: any) => {
              if (data?.pin) { if (data.pin == '1234') { resolve() } else { this.simpleAlert('Incorrect pin!'), reject() } }
              else reject()
            }
          }],
          inputs: [{ name: 'pin', placeholder: '1234', type: 'number' },],
          message: 'Enter 1234 to confirm!',
        }) //end this.alertController.create()
        await alert.present()
      } else resolve() //end if (this.user.dev)
    }) //end new Promise<void>()
  } //end enterPin()

  generateFromImage(img, MAX_WIDTH: number = 700, MAX_HEIGHT: number = 700, quality: number = 1, callback) {
    const canvas: any = document.createElement('canvas'), image = new Image()
    image.onload = () => {
      let width = image.width, height = image.height
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, width, height)
      // IMPORTANT: 'jpeg' NOT 'jpg'
      const dataUrl = canvas.toDataURL('image/jpeg', quality)
      callback(dataUrl)
    }
    image.src = img
  } //end generateFromImage()

  getNewDate(date: Date, days: number, previous: boolean) {
    const newDate = new Date(date.getTime())
    previous ? newDate.setDate(date.getDate() - days) : newDate.setDate(date.getDate() + days)
    return newDate
  } //end getNewDate()

  getPatrolCurrentTime(startTime, duration) {
    const time = new Date()
    time.setHours(startTime.split(':')[0], startTime.split(':')[1], 0, 0)
    const currentTime = new Date(time.getTime() + duration * 60000)
    return currentTime
  } //end getPatrolCurrentTime()

  getPatrolTime(time, time1?, time2?, time3?, type?) {
    let result
    if (!time1) {
      const m = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
      const h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
      result = h + ':' + m
      return result
    } else {
      const eventStart = new Date()
      if (type == 'checkSchedule') {
        eventStart.setHours(time1.getHours(), time1.getMinutes(), 0, 0)
      } else {
        eventStart.setHours(time1.split(':')[0], time1.split(':')[1], 0, 0)
      }

      const start = new Date()
      start.setHours(time2.split(':')[0], time2.split(':')[1], 0, 0)

      const end = new Date()
      end.setHours(time3.split(':')[0], parseInt(time3.split(':')[1]) + this.tolerance, 0, 0)

      const eventEnd = new Date()
      if (type == 'checkAllSchedule') {
        eventEnd.setHours(time.split(':')[0], parseInt(time.split(':')[1]), 0, 0)
      } else {
        eventEnd.setHours(time.getHours(), time.getMinutes(), 0, 0)
      }

      const newEnd = end.getTime() + start.getTime()
      const newEventEnd = eventEnd.getTime() + start.getTime()
      const result = { eventStart: eventStart, start: start, end: end, eventEnd: eventEnd, newEnd: newEnd, newEventEnd: newEventEnd }
      // if (this.auth.showLog) console.log(this.TAG, '***', time3, end)
      return result
    }
  } //end getPatrolTime()

  async imageViewer(image, ref, ID?) {
    console.log(this.TAG, image, ref)
    if (image?.thumb || image?.type == 'checklist' || image?.type == 'incident' || image == null) {
      this.loading.present()
      let imageRef
      if (ID) {
        imageRef = this.authService.firestorage.ref(ref + image.key + '/' + ID)
      } else {
        if (image == null) imageRef = this.authService.firestorage.ref(ref)
        else image.type == 'checklist' ? imageRef = this.authService.firestorage.ref(ref) : imageRef = this.authService.firestorage.ref(ref + image.key)
      } //if (ID)
      await imageRef.getDownloadURL().then(async (url) => {
        const modal = await this.modalCtrl.create({
          component: ViewerModalComponent,
          componentProps: { src: url, scheme: 'dark' },
          cssClass: 'ion-img-viewer',
          keyboardClose: true, showBackdrop: true
        })
        this.loading.dismiss()
        return await modal.present()
      })
    }
  } //end imageViewer()

  async imageViewURL(ref, retry = false) {
    console.log(this.TAG, 'imageViewURL() ref', ref, 'retry->', retry)
    this.loading.present()
    let imageRef = this.authService.firestorage.ref(ref)
    await imageRef.getDownloadURL().then(async (url) => {
      console.log(this.TAG, 'imageViewURL()', url)
      const modal = await this.modalCtrl.create({ component: ViewerModalComponent, componentProps: { src: url, scheme: 'dark' }, cssClass: 'ion-img-viewer', keyboardClose: true, showBackdrop: true })
      return await modal.present()
    }).catch(async (err) => {
      if (ref.includes('boomin-results') && !retry) {
        await this.loading.dismiss()
        const lastSlash = ref.lastIndexOf('/')
        this.imageViewURL(ref.substring(0, lastSlash) + '_' + ref.substring(lastSlash + 1), true)
        return
      } //end if (ref.includes('boomin-results') && !retry)

      let msg = ''
      if (err.code === 'storage/object-not-found') msg = `File doesn't exist`
      else if (err.code === 'storage/unauthorized') msg = `User doesn't have permission to access the object`
      else if (err.code === 'storage/canceled') msg = `User canceled the upload`
      else if (err.code === 'storage/unknown') msg = `Unknown error occurred, inspect the server response`
      if (this.authService.showError) console.error(this.TAG, 'imageViewURL()', msg)
      if (msg) this.simpleAlert(msg)
    }).finally(() => this.loading.dismiss())
  } //end imageViewer()

  makeID() {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-'
    for (let i = 0; i < 24; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  } //end makeID()

  orderByChat = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    if (a.value.latestMsgDate < b.value.latestMsgDate) return 1
    if (a.value.latestMsgDate > b.value.latestMsgDate) return -1
    if (a.value.name.toLowerCase() < b.value.name.toLowerCase()) return -1
    if (a.value.name.toLowerCase() > b.value.name.toLowerCase()) return 1
    return 0
  } //end orderByChat()

  orderByDateAsc = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    return a.value.date > b.value.date ? 1 : (b.value.date > a.value.date ? -1 : 0)
  } //end orderByDateAsc()

  orderByDateDesc = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    return a.value.date > b.value.date ? -1 : (b.value.date > a.value.date ? 1 : 0)
  } //end orderByDateDesc()

  orderByFLNameAsc = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    if (a.value.fname.toLowerCase() < b.value.fname.toLowerCase()) return -1
    if (a.value.fname.toLowerCase() > b.value.fname.toLowerCase()) return 1
    if (a.value.lname.toLowerCase() < b.value.lname.toLowerCase()) return -1
    if (a.value.lname.toLowerCase() > b.value.lname.toLowerCase()) return 1
    return 0
  } //end orderByFNameAsc()

  orderByUsers = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    if (a.value.fname.toLowerCase() < b.value.fname.toLowerCase()) return -1
    if (a.value.fname.toLowerCase() > b.value.fname.toLowerCase()) return 1
    if (a.value.lname.toLowerCase() < b.value.lname.toLowerCase()) return -1
    if (a.value.lname.toLowerCase() > b.value.lname.toLowerCase()) return 1
    if (a.value.email.toLowerCase() < b.value.email.toLowerCase()) return -1
    if (a.value.email.toLowerCase() > b.value.email.toLowerCase()) return 1
    return 0
  } //end orderByFNameAsc()

  orderByFNameAsc = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    return a.value.fname.toLowerCase() > b.value.fname.toLowerCase() ? 1 : (b.value.fname.toLowerCase() > a.value.fname.toLowerCase() ? -1 : 0)
  } //end orderByFNameAsc()

  public orderByIndexAsc = (a: KeyValue<number, any>, b: KeyValue<number, any>): any => {
    return a.value.index > b.value.index ? 1 : (b.value.index > a.value.index ? -1 : 0)
  } //end orderIndex()

  orderByNameAsc = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
    return a.value.name.toLowerCase() > b.value.name.toLowerCase() ? 1 : (b.value.name.toLowerCase() > a.value.name.toLowerCase() ? -1 : 0)
  } //end orderByNameAsc()

  public orderByPatrolDateDesc = (a: KeyValue<number, any>, b: KeyValue<number, any>): any => {
    if (a.value.startDate < b.value.startDate) return 1
    if (a.value.startDate > b.value.startDate) return -1
    if (a.value.startTime < b.value.startTime) return 1
    if (a.value.startTime > b.value.startTime) return -1
    return 0
  } //end orderPatrolDesc()

  public orderByPatrolDateAsc = (a: KeyValue<number, any>, b: KeyValue<number, any>): any => {
    // return a.value.index > b.value.index ? 1 : (b.value.index > a.value.index ? -1 : 0)
    if (a.value.startDate < b.value.startDate) return -1
    if (a.value.startDate > b.value.startDate) return 1
    if (a.value.startTime < b.value.startTime) return -1
    if (a.value.startTime > b.value.startTime) return 1
    return 0
  } //end orderPatrolDateAsc()

  orderBoominPoliciesOrEvents = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    if (a.value.name.toLowerCase() < b.value.name.toLowerCase()) return -1
    else if (a.value.name.toLowerCase() > b.value.name.toLowerCase()) return 1
    else if (a.value.description.toLowerCase() < b.value.description.toLowerCase()) return -1
    else if (a.value.description.toLowerCase() > b.value.description.toLowerCase()) return 1
    else return 0
  } //end orderBoominPolicies()

  orderBoominCustomListDetails = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
    if (a.value?.details[0]?.value?.toLowerCase() < b.value?.details[0]?.value?.toLowerCase()) return -1
    if (a.value?.details[0]?.value?.toLowerCase() > b.value?.details[0]?.value?.toLowerCase()) return 1
    if (a.value?.details[1]?.value?.toLowerCase() < b.value?.details[1]?.value?.toLowerCase()) return -1
    if (a.value?.details[1]?.value?.toLowerCase() > b.value?.details[1]?.value?.toLowerCase()) return 1
    return 0
  } //end orderBoominCustomListDetails()

  orderBoominPolicyEvents = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    return a.value.eventIndex > b.value.eventIndex ? 1 : (b.value.eventIndex > a.value.eventIndex ? -1 : 0)
  } //end orderBoominPolicyEvents()

  orderBoominMatched = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    if (a.value.dateIn > b.value.dateIn) return 1
    else if (a.value.dateIn < b.value.dateIn) return -1
    else return 0
  } //end orderBoominMatched()

  orderBoominResults = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    if (a.value.date > b.value.date) return -1
    else if (a.value.date < b.value.date) return 1
    else return 0
  } //end orderBoominResults()

  orderBoominEventPages = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    return a.value.index > b.value.index ? 1 : (b.value.index > a.value.index ? -1 : 0)
  } //end orderBoominEventPages()

  orderBoominEventInPages = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    return a.value.inIndex > b.value.inIndex ? 1 : (b.value.inIndex > a.value.inIndex ? -1 : 0)
  } //end orderBoominEventInPages()

  orderBoominEventOutPages = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    return a.value.outIndex > b.value.outIndex ? 1 : (b.value.outIndex > a.value.outIndex ? -1 : 0)
  } //end orderBoominEventOutPages()

  orderBoominPages = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
    if (a.value.type < b.value.type) return -1
    if (a.value.type > b.value.type) return 1
    if (a.value.pageType < b.value.pageType) return -1
    if (a.value.pageType > b.value.pageType) return 1
    if (a.value.name.toLowerCase() < b.value.name.toLowerCase()) return -1
    if (a.value.name.toLowerCase() > b.value.name.toLowerCase()) return 1
    if (a.value.description.toLowerCase() < b.value.description.toLowerCase()) return -1
    if (a.value.description.toLowerCase() > b.value.description.toLowerCase()) return 1
    else return 0
  } //end orderBoominPages()

  orderBoominLists = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    if (a.value.firstname.toLowerCase() < b.value.firstname.toLowerCase()) return -1
    if (a.value.firstname.toLowerCase() > b.value.firstname.toLowerCase()) return 1
    if (a.value.lastname.toLowerCase() < b.value.lastname.toLowerCase()) return -1
    if (a.value.lastname.toLowerCase() > b.value.lastname.toLowerCase()) return 1
    if (a.value.identifier.toLowerCase() < b.value.identifier.toLowerCase()) return -1
    if (a.value.identifier.toLowerCase() > b.value.identifier.toLowerCase()) return 1
    if (a.value.description.toLowerCase() < b.value.description.toLowerCase()) return -1
    if (a.value.description.toLowerCase() > b.value.description.toLowerCase()) return 1
    else return 0
  } //end orderBoominLists()

  orderBoominDeliveries = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    if (a.value.deliveryTime < b.value.deliveryTime) return -1
    if (a.value.deliveryTime > b.value.deliveryTime) return 1
    if (a.value.details[0].title.toLowerCase() < b.value.details[0].title.toLowerCase()) return -1
    if (a.value.details[0].title.toLowerCase() > b.value.details[0].title.toLowerCase()) return 1
    if (a.value.identifiers[0].title.toLowerCase() < b.value.identifiers[0].title.toLowerCase()) return -1
    if (a.value.identifiers[0].title.toLowerCase() > b.value.identifiers[0].title.toLowerCase()) return 1
    else return 0
  } //end orderBoominDeliveries()

  reorderIndex(obj, indexFrom, indexTo, indexType, toDelete?: boolean) {
    console.log(this.TAG, 'reorderBoominEventPages(obj, indexFrom, indexTo, indexType)', obj, indexFrom, indexTo, indexType)
    let tempFrom
    const isForward = indexFrom < indexTo
    Object.entries(obj).forEach(([k, v]) => {
      if (v[indexType] === indexFrom) tempFrom = k
      else if (isForward && v[indexType] > indexFrom && v[indexType] <= indexTo) v[indexType] -= 1
      else if (!isForward && v[indexType] < indexFrom && v[indexType] >= indexTo) v[indexType] += 1
    })
    if (!toDelete) obj[tempFrom][indexType] = indexTo
  } //end reorderIndex()

  async presentContact(contact?) {
    console.log(this.TAG, 'presentContact()', contact)
    const modal = await this.modalCtrl.create({
      component: ContactDetailPage,
      componentProps: { contact: contact ? contact : null },
      cssClass: 'modal-contact'
    })
    return await modal.present()
  } //end presentContact()

  searchArray(source, event?) {
    this.zone.run(() => {
      this.countItems = 0
      let val = !event ? '' : event?.target ? event.target.value ? event.target.value.toLowerCase() : '' : event ? event.toLowerCase() : ''
      this.items = source.filter(d => {
        let flag = this.searchNested(d, val)
        return flag
      }) //end this.items = source.filter(d =>)
      this.countItems = this.items.length
    }) //end this.zone.run()
  } //end searchArray()

  searchNested(obj, val) {
    if (!val) return true
    let flag = false
    const searchProperties = (obj) => {
      for (const key in obj) {
        if (this.searchKeysIgnore.indexOf(key) < 0) {
          const value = obj[key]
          if (value && typeof value === 'object') { searchProperties(value) }
          else if (typeof value === 'number' || typeof value === 'string') {
            if (('' + value).toLowerCase().indexOf(val) !== -1 || !val) {
              flag = true
              break
            }
          } //end if (value && typeof value === 'object')
        } //end if (keyIgnoreList.indexOf(key) < 0)
      } //end for (const key in obj)
    } //end recurse
    searchProperties(obj)
    return flag
  } //end searchNested()

  searchObj(source, filter: any, event?): any {
    this.zone.run(() => {
      if (source) {
        this.countObjects = 0
        this.countObjectUsnassigned = 0
        let val = !event ? '' : event?.target ? event.target.value ? event.target.value.toLowerCase().trim() : '' : event ? event.toLowerCase().trim() : ''
        this.objects = Object.keys(source).filter(key => {
          let flag = this.searchNested(source[key], val)
          if (flag) {
            if (filter?.boomin) {
              if (filter.boomin.type == 'summary') { flag = (filter.boomin.category == 'all' && !source[key].hide) ? true : (filter.boomin.category == source[key].mode) }
              else if (filter.boomin.type == 'list') { flag = (filter.boomin.category == source[key].list) }
              else if (filter.boomin.type == 'onSite') {
                const siteStart = this.dataService.boominResultsObj.start[filter.boomin.siteKey]
                const start = new Date(new Date(siteStart).setDate(new Date().getDate())).getTime()
                flag = (filter.boomin.category == source[key].identifierType && source[key].date >= start)
              }
              else if (filter.boomin.type == 'exception') { flag = (filter.boomin.category == 'all') ? true : (source[key][filter.boomin.category]) }
              else if (filter.boomin.type == 'checklists') { flag = true }
              // if (flag && filter.boomin.basic != 'all' && filter.boomin.basic != 'matched') flag = (source[key].mode == filter.boomin.basic)
              if (flag && filter.boomin.type == 'matched') flag = (source[key].matching[filter.boomin.category] === true)
              if (flag && filter.boomin.type != 'identifiers') flag = filter.boomin.site ? (source[key].siteKey == filter.boomin.siteKey) : (source[key].deviceKey == filter.boomin.deviceKey)
              if (flag && filter.boomin.type != 'identifiers')
                if (filter.boomin.isLive) { flag = (source[key].isLive) }
                else if (filter?.boomin?.isLive === false) { flag = (source[key].date >= filter.boomin.start && source[key].date <= filter.boomin.end) }
            } //end if (filter?.boomin)

            if (filter?.device) {
              if (flag && filter.device.isDev !== null) flag = filter.device.isDev ? (source[key].version.split('.').length > 3) : !(source[key].version.split('.').length > 3)
              if (flag && filter.device.amatrack) flag = (!source[key].boominOnly)
              if (flag && filter.device.boomin) flag = (source[key].boomin)
              if (flag && filter.device.radio) flag = (source[key].type == 'radio')
              if (flag && filter.device.ptt) flag = (source[key].ptt?.enabled)
              if (flag && filter.device.supervisor) flag = (source[key].userID || source[key].supervisor?.enabled)
              if (flag && filter.device.unassigned) flag = (source[key].userID || source[key].siteKey || source[key].supervisor?.enabled)
            } //end if (filter?.device)

            if (filter?.checkpoint) {
              if (flag && filter.checkpoint.unassigned) flag = (source[key].siteKey)
            } //end if (filter?.checkpoint)
          } //end if (flag)
          return flag
        }).reduce((obj, key) => { return { ...obj, [key]: source[key] } }, {}) //end this.objects = Object.keys(source).filter()

        if (filter?.boomin?.type == 'identifiers') {
          if (Object.keys(this.objects).length) for (const key in this.objects) {
            this.objects[key] = this.objects[key].filter(clocking => {
              let flag = true
              if (flag) filter.boomin.site ? (clocking.siteKey == filter.boomin.siteKey) : (clocking.deviceKey == filter.boomin.deviceKey)
              if (flag) {
                if (filter.boomin.isLive) { flag = (clocking.isLive) }
                else if (filter.boomin.isLive === false) { flag = (clocking.date >= filter.boomin.start && clocking.date <= filter.boomin.end) }
              }
              return flag
            })
            if (this.objects[key].length < 1) delete this.objects[key]
          } //end for (const key in this.objects)
        } //end if (filter?.boomin?.type == 'identifiers')
        if (filter?.device?.unassigned) {
          this.objectsUnassigned = Object.keys(source).filter(key => {
            let flag = this.searchNested(source[key], val)
            if (flag) flag = !(source[key].userID || source[key].siteKey || source[key].supervisor?.enabled)
            if (flag && filter.device.isDev !== null) flag = filter.device.isDev ? (source[key].version.split('.').length > 3) : !(source[key].version.split('.').length > 3)
            if (flag && filter.device.amatrack) flag = (!source[key].boominOnly)
            if (flag && filter.device.boomin) flag = (source[key].boomin)
            if (flag && filter.device.radio) flag = (source[key].type == 'radio')

            return flag
          }).reduce((obj, key) => { return { ...obj, [key]: source[key] } }, {}) //end this.objects = Object.keys(source).filter()
        } //end if (filter.device.unassigned)
        if (filter?.checkpoint?.unassigned) {
          this.objectsUnassigned = Object.keys(source).filter(key => {
            let flag = this.searchNested(source[key], val)
            if (flag) flag = !(source[key].siteKey)
            return flag
          }).reduce((obj, key) => { return { ...obj, [key]: source[key] } }, {}) //end this.objects = Object.keys(source).filter()
        } //end if (filter.device.unassigned)

        this.countObjects = Object.keys(this.objects).length
        this.countObjectUsnassigned = Object.keys(this.objectsUnassigned).length
        // if (filter?.boomin?.type == 'identifiers') if (this.auth.showError) console.error(this.TAG, filter)
      } else {
        this.objects = {}
        this.objectsUnassigned = {}
        this.countObjects = 0
        this.countObjectUsnassigned = 0
      } //end if (source)
    }) //end this.zone.run()
  } //end searchObj()

  searchObjAlerts(source, event?, incidents?) {
    this.zone.run(() => {
      let val = !event ? '' : event?.target ? event.target.value ? event.target.value.toLowerCase() : '' : event ? event.toLowerCase() : ''
      this.alertsObj = Object.keys(source).filter(key => {
        let flag = false
        Object.values(source[key]).forEach(field => { if (('' + field).toLowerCase().indexOf(val) !== -1 || !val) flag = true })
        return flag
      }).reduce((obj, key) => {
        return { ...obj, [key]: source[key] }
      }, {}) //end this.alertsObj = Object.keys(source).filter()
      if (incidents) {
        this.incidentsObj = Object.keys(incidents).filter(key => {
          let flag = false
          Object.values(incidents[key]).forEach(field => { if (('' + field).toLowerCase().indexOf(val) !== -1 || !val) flag = true })
          return flag
        }).reduce((obj, key) => {
          return { ...obj, [key]: incidents[key] }
        }, {}) //end this.incidentsObj = Object.keys(incidents).filter()
      } //end if (incidents)
      this.countAlertObjects = Object.keys(this.alertsObj).length + Object.keys(this.incidentsObj).length
    }) //end this.zone.run()
  } //end searchArray()

  shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1
      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }
    return array
  } //end shuffle()

  simpleAlert(message, header?) {
    return new Promise<void>(async (resolve) => {
      const alert = await this.alertController.create({ header: header ? header : 'Notice!', message: message, buttons: ['OK'] })
      alert.onDidDismiss().then(() => resolve())
      await alert.present()
    })
  } //end simpleAlert()

  simpleAlertSave(message, header?) {
    return new Promise<{ stay: boolean, close: boolean }>(async (resolve) => {
      const save = { close: false, stay: false }
      const alert = await this.alertController.create({
        backdropDismiss: false,
        header: header ? header : 'Notice!',
        message: message,
        buttons: [
          {
            text: 'Stay',
            handler: () => { save.stay = true },
          }, {
            text: 'Close',
            role: 'cancel',
            handler: () => { save.close = true },

          },
        ]
      })
      alert.onDidDismiss().then(() => resolve(save))
      await alert.present()
    })
  } //end simpleAlertSave()

  timeElapased(end: number, start: number) {
    const timeDiff = Math.floor((end - (start)) / 1000)

    let seconds = timeDiff
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    let days = Math.floor(hours / 24)
    hours = hours - (days * 24)
    minutes = minutes - (days * 24 * 60) - (hours * 60)
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60)

    return { timeDiff: timeDiff, hours: hours, minutes: minutes, seconds: seconds, text: `${hours}h ${minutes}m ${seconds}s` }
  } //end timeElapased()

  timeSince(then, now) {
    const diff = now - then
    const diffSeconds = (diff / 1000)
    const diffMinutes = (diff / 60000)
    const diffHours = (diff / 3600000)
    const diffDays = (diff / 3600000 * 24)
    let time = ''
    if (diffSeconds > 0 && diffSeconds < 1.5) time = diffSeconds.toFixed(0) + ' second ago'
    else if (diffSeconds > 1.5 && diffSeconds < 59.5) time = diffSeconds.toFixed(0) + ' seconds ago'
    else if (diffSeconds > 59.5 && diffMinutes < 1.5) time = diffMinutes.toFixed(0) + ' minute ago'
    else if (diffMinutes > 1.5 && diffMinutes < 59.5) time = diffMinutes.toFixed(0) + ' minutes ago'
    else if (diffMinutes > 59.5 && diffHours < 1.5) time = diffHours.toFixed(0) + ' hour ago'
    else if (diffHours > 1.5 && diffHours < 23.5) time = diffHours.toFixed(0) + ' hours ago'
    else if (diffHours > 23.5 && diffDays < 1.5) time = diffDays.toFixed(0) + ' day ago'
    else if (diffDays > 1.5 && then != 0) time = diffDays.toFixed(0) + ' days ago'
    else time = 'N/A'
    return time
  } //end timeSince()

  public toTitleCase(str) {
    return str.toLowerCase().split(' ').map((word) => { return word.replace(word[0], word[0].toUpperCase()) }).join(' ')
  } //end toTitleCase()

  public toTitleCaseUnderscore(str) {
    return str.toLowerCase().split(' ').map((word) => { return word.replace(word[0], word[0].toUpperCase()) }).join('_')
  } //end toTitleCaseUnderscore()

  public toTitleCaseJoined(str) {
    return str.toLowerCase().split(' ').map((word) => { return word.replace(word[0], word[0].toUpperCase()) }).join('')
  } //end toTitleCaseJoined()

  trackByFn(index, item) {
    return item ? item.id : index
  } //end trackByFn()

} //end
