import { KeyValue } from '@angular/common'
import { Component, NgZone } from '@angular/core'
import { NavParams, PopoverController } from '@ionic/angular'
import { DataService } from 'src/app/services/data/data.service'

@Component({
  selector: 'app-objects-search',
  templateUrl: './objects-search.component.html',
  styleUrls: ['./objects-search.component.scss'],
})
export class ObjectsSearchComponent {
  val = ''
  objects = {}
  sourceObjects = {}
  showCompany: false
  showSite: false

  constructor(
    public dataService: DataService,
    private navParams: NavParams,
    private popoverController: PopoverController,
    private zone: NgZone
  ) {
    this.sourceObjects = this.navParams.data.objects
    this.searchObj()
  } //end ()

  dismiss(data?: any) { this.popoverController.dismiss(data) } //end dismiss()

  searchObj(event?) {
    this.zone.run(() => {
      const val = !event ? '' : event?.target ? event.target.value ? event.target.value.toLowerCase() : '' : event ? event.toLowerCase() : ''
      this.objects = Object.keys(this.sourceObjects).filter(key => this.sourceObjects[key].name.toLowerCase().indexOf(val) !== -1 || !val).reduce((obj, key) => { return { ...obj, [key]: this.sourceObjects[key] } }, {})
    }) //this.zone.run()
  } //end searchObj()

  orderByNameAsc = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => { return a.value.name > b.value.name ? 1 : (b.value.name > a.value.name ? -1 : 0) }
}
