import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PopoverController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth/auth.service'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { UtilityService } from 'src/app/services/utility/utility.service'
import { EmailValidator } from '../../../validators/email'

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
})
export class ChangeEmailComponent {
  private TAG = 'ChangeEmailComponent|'
  public passwordMatch: boolean = true
  public passwordOK: boolean = false
  public passwordStrength: any = ''
  public formPassword


  public form: FormGroup
  public user: any = {}

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private popoverController: PopoverController,
    public utilityService: UtilityService,
  ) {
    console.log(this.TAG, '()')
    this.formPassword = this.formBuilder.group({
      password: ['', Validators.compose([Validators.minLength(6), EmailValidator.isValid])],
      passwordConfirm: ['', Validators.compose([Validators.minLength(6), Validators.required])]
    })

    this.formPassword.controls.passwordConfirm.valueChanges.subscribe((password) => {
      if (password) {
        if (password == this.formPassword.value.password) {
          this.passwordMatch = true
        } else { this.passwordMatch = false }
      } else { this.passwordMatch = true }
    })
  } //end ()

  update() {
    console.log(this.TAG, this.formPassword)
    const cmd = {
      email:this.formPassword.value.password,userID:this.user.userID
    }
    this.loading.present()
    const callableUserModifyEmail = this.authService.aff.httpsCallable('userModifyEmail')
    callableUserModifyEmail(cmd).subscribe((res) => {
      this.loading.dismiss()
      console.info(this.TAG, 'callableUserModifyEmail', res)
      this.authService.logEvent('user_modify_email')
      this.utilityService.simpleAlert('The user email has been successfully updated!').then(() => this.dismiss())
    }, (err) => {
      this.loading.dismiss()
      console.error(this.TAG, 'callableUserModifyEmail ERROR:', err, err?.message)
      this.utilityService.simpleAlert(err.message)
    }) //end callableUserModifyEmail()
  }

  dismiss() {
    this.popoverController.dismiss()
  }
}
