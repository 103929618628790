import { Injectable, NgZone } from '@angular/core'
import { AngularFireAnalytics } from '@angular/fire/compat/analytics'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFireFunctions } from '@angular/fire/compat/functions'
import { Router } from '@angular/router'
import firebase from 'firebase/compat/app'
import { Subject } from 'rxjs'
import { docProps } from 'src/app/interfaces/interfaces'
import { environment } from '../../../environments/environment'

declare let window: any

@Injectable({ providedIn: 'root' })
export class AuthService {
  private TAG = 'AuthService|'
  public authUser: firebase.User
  public companiesObj: any = {}
  public deleteField: firebase.firestore.FieldValue
  public firestorage: firebase.storage.Storage
  public firestore: firebase.firestore.Firestore
  public isAdmin: boolean = false
  public isDev: boolean = false
  public isGlobal: boolean = false
  public login: boolean = false
  private observer = new Subject<any>()
  public permissions: any = {}
  public showError: boolean = false
  public showLog: boolean = false
  public showWarn: boolean = false
  public timestamp = firebase.firestore.FieldValue.serverTimestamp()
  public user: any

  constructor(
    public afAuth: AngularFireAuth,
    public afan: AngularFireAnalytics,
    public aff: AngularFireFunctions,
    private router: Router,
    private zone: NgZone,
  ) {
    this.firestorage = firebase.storage()
    this.firestore = firebase.firestore()
    this.deleteField = firebase.firestore.FieldValue.delete()
    let runOnce = true
    const timeStartup = Date.now()
    this.afAuth.authState.subscribe(async (fUser: firebase.User) => {
      if (fUser) {
        this.authUser = fUser

        // try {
        firebase.database().ref('metadata/' + fUser.uid + '/refreshTime').on('value', (snapshot) => {
          const timeRefresh = snapshot.val()
          console.error(this.TAG, timeStartup, timeRefresh)
          if (timeRefresh && timeRefresh > timeStartup) window.location.reload()
        })
        // } catch (error) { console.error(this.TAG, error) }

        // try {
        //   const claims = await fUser.getIdTokenResult()
        //   console.error(this.TAG, 'ASDASD ASDasd ', claims)
        // } catch (error) {
        //   console.error(this.TAG, 'ERROR ASDASD ASDasd ', fUser)
        // }

        if (runOnce) {
          runOnce = false

          // .then((idTokenResult) => {
          //   // Confirm the user is an Admin.
          //   if (!!idTokenResult.claims.admin) {
          //     // Show admin UI.
          //     showAdminUI()
          //   } else {
          //     // Show regular user UI.
          //     showRegularUI()
          //   }
          // })
          // .catch((error) => {
          //   console.log(this.TAG, error)
          // });

          await this.firestore.doc('users/' + this.authUser.uid)
            .update({ [`lastLogin.portal`]: Date.now() })
            .then(() => console.log(this.TAG, 'user last login update successful'))
            .catch(err => console.error(this.TAG, 'user last login update unsuccessful', '->', err))

          await this.firestore.doc('users/' + this.authUser.uid).onSnapshot(async userSnapshot => {
            if (this.user?.companyDB && this.user?.companyDB !== userSnapshot.data().companyDB) window.location.reload()
            this.user = userSnapshot.data()
            if (this.user) {
              if (!this.user.settings) this.user.settings = {}
              this.user.name = `${this.user.fname} ${this.user.lname}`
              this.isAdmin = this.user.isAdmin === true
              this.isDev = this.user.companyDB === 'dev' || this.user.companyDB === 'dev2' || this.user.isDev === true
              this.isGlobal = this.user.isGlobal === true
              this.isAdmin ? console.warn(this.TAG, '@@@ ADMIN LOGIN @@@', this.user.companyDB, this.user.name, this.user) : console.warn(this.TAG, '@@@ NON-ADMIN USER LOGIN @@@', this.user.companyDB, this.user.name)
              this.isDev ? console.warn(this.TAG, '@@@ DEV LOGIN @@@', this.user.companyDB, this.user.name, this.user, this.authUser, 'production', environment.production, window.location.hostname) : console.warn(this.TAG, '@@@ NON-DEV USER LOGIN @@@', this.user.companyDB, this.user.name, window.location.hostname)
              this.isGlobal ? console.warn(this.TAG, '@@@ GLOBAL LOGIN @@@', this.user.companyDB, this.user.name) : console.warn(this.TAG, '@@@ NON-GLOBAL USER LOGIN @@@', this.user.companyDB, this.user.name)
              if (this.user.isDev) { this.showError = true, this.showLog = true, this.showWarn = true } else { this.showError = false, this.showLog = false, this.showWarn = false }// show extra logs if dev
              if (this.isDev || this.isGlobal || this.isAdmin) {

                // const callableTestOnCall = this.aff.httpsCallable('testOnCall')
                // callableTestOnCall({someMessage:'YAY!'}).subscribe(res => {
                //   console.info(this.TAG, 'callableTestOnCall', res)
                // }, (err) => {
                //   console.error(this.TAG, 'callableTestOnCall ERROR:', err, err?.message)
                // }) //end callableTestOnCall()

                await this.firestore.collection('companies').onSnapshot(companiesSnapshot => {
                  this.zone.run(() => {
                    companiesSnapshot.docChanges().forEach(companyData => {
                      const company = { dbName: companyData.doc.data().dbName, key: companyData.doc.id, name: companyData.doc.data().name }
                      if (companyData.type === 'added' || companyData.type === 'modified') this.companiesObj[company.key] = company
                      if (companyData.type === 'removed' && this.companiesObj[company.key]) delete this.companiesObj[company.key]
                      this.observer.next({ event: companyData.type, type: 'companies', key: company.key, data: company })
                    }) //companiesSnapshot.docChanges().forEach()
                    this.observer.next({ event: 'modified', type: '*companies' })
                  }) //end this.zone.run()
                }) //end this.firestore.collection('companies')
              } else {
                // if (this.user.companyDB != 'bakerssalimited' && window.location.hostname.includes('patrol.amatrack.net')) window.open('https://admin.amatrack.net', '_self')
              } //end if (this.dev || this.isGlobal)
            } //end if (this.currentUser)
          }) //end this.firestore.doc('users/' + this.authUser.uid)
        } //end if (runOnce)
      } else { this.authUser = null, runOnce = true, this.isAdmin = false, this.isDev = false, this.isGlobal = false, this.companiesObj = {} }
    }) //end this.auth.authState.subscribe()
    console.error(this.TAG, '@@@ Auth Service Init Complete @@@')
  } //end ()

  public defaultDoc(isNew: boolean = false): docProps {
    const now = Date.now()
    const obj: docProps = { companyDB: this.user.companyDB, date: now, dateModified: now }
    if (isNew) obj.dateCreated = now
    //TODO: auto metadata for objects
    // if (this.isDev) {
    //   obj._info = { date: { modified: now, modifiedTimestamp: this.timestamp }, timestamp: { modified: this.timestamp }, reg: { companyDB: this.user.companyDB } }
    //   if (isNew) {
    //     obj._info.date.created = now
    //     obj._info.date.createdTimestamp = this.timestamp
    //     obj._info.timestamp.created = this.timestamp
    //   }
    // }
    return obj
  } //end defaultDoc()

  public async logEvent(eventName: string, eventParams?: any) {
    if (this.user) {
      eventParams = { ...eventParams, companyDB: this.user.companyDB, userID: this.user.userID, userName: this.user.name, isAdmin: this.isAdmin, isGlobal: this.isGlobal, isDev: this.isDev, userRole: this.user.role }
      await this.afan.logEvent(eventName, eventParams).catch(err => console.error(this.TAG, 'logEvent() unsuccessful', eventName, eventParams, err))
    }
    else { console.error(this.TAG, 'NO Current User', this.user) } //end if(this.currentUser)
  } //end logEvent()

  public saveHistory(history, type) {
    //TODO: Cloudfuntion - move to cloud function collection onCreate/Modify/Remove etc...
    let saveHistory = this.searchObj(history)
    console.log(this.TAG, 'Retuned Obj', { ...saveHistory })
    saveHistory.dateModified = Date.now()
    saveHistory.hDate = Date.now()
    saveHistory.hTimestamp = this.timestamp
    saveHistory.type = type
    saveHistory.user = this.user.fname + ' ' + this.user.lname
    saveHistory.userActionTime = Date.now()
    saveHistory.userID = this.user.userID
    this.firestore.collection('history').add(saveHistory)
  } //end saveHistory()

  private searchNested(obj) {
    const recurse = (obj) => {
      for (const key in obj) {
        let value = obj[key]
        if (value && typeof value === 'object') { recurse(value) }
        else { value === undefined ? obj[key] = null : '' }
      } //end for (const key in obj)
    }
    recurse(obj)
    return true
  } //end searchNested()

  private searchObj(source) {
    let objects
    if (source) {
      objects = Object.keys(source).filter(key => {
        let flag = this.searchNested(source[key])
        return flag
      }).reduce((obj, key) => { return { ...obj, [key]: source[key] } }, {}) //let objects = Object.keys(source).filter()
    } else { objects = source }//end if (source)
    return objects
  } //end searchObj()

  public signIn(email: any, password: any) { return this.afAuth.signInWithEmailAndPassword(email, password) } //end signIn()

  public signOut(): void { this.router.navigate(['/login']).finally(() => this.afAuth.signOut().then(() => { console.log(this.TAG, 'Logged Out'), window.location.reload() })) } //end signOut()

  public register(email: any, password: any) { return this.afAuth.createUserWithEmailAndPassword(email, password) } //end register()

  public resetPassword(email: string) { return this.afAuth.sendPasswordResetEmail(email) } //end resetPassword()

  public updateEmail(email: string) { return this.authUser.updateEmail(email) } //end updateEmail()

  public updatePassword(password: string) { return this.authUser.updatePassword(password) } //end updatePassword()

} //end AuthService