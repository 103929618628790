// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  analyticsDebug: true,
  emulator: true,
  firebaseConfig: {
    apiKey: "AIzaSyDTXvV5Pi_Kzq27JLeQMfzInGfsHd4GpQE",
    authDomain: "amatrack-c0e9f.firebaseapp.com",
    databaseURL: "https://amatrack-c0e9f-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "amatrack-c0e9f",
    storageBucket: "amatrack-c0e9f.appspot.com",
    messagingSenderId: "347346809765",
    appId: "1:347346809765:web:30f9a188282125192bee4c",
    measurementId: "G-YLGXJLGB7Z"
  },
  production: false,
  socketURL: 'http://localhost:8080'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.