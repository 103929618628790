import { Injectable, NgZone } from '@angular/core'
import { Platform } from '@ionic/angular'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Change } from 'src/app/interfaces/interfaces'
import { AuthService } from 'src/app/services/auth/auth.service'
import { DateService } from 'src/app/services/date/date.service'
import { LoadingService } from '../loading/loading.service'
// import { User, sayGoodbye,sayHello } from 'demo-lib'
@Injectable({
  providedIn: 'root'
})

export class DataService {
  TAG = 'DataService|'
  public alertsObj = {}// live-feed
  public boominEventsObj: any = {} //boomin pages
  public boominListsObj: any = { custom: {}, person: { black: {}, white: {}, watch: {} }, vehicle: { black: {}, white: {}, watch: {} } } //boom-page-detail, boomin, list-custom-details, boomin-inventorries
  public boominPagesObj: any = {} //boomin pages
  public boominPoliciesObj: any = {}// boomin pages, view device
  public boominRelaysObj: any = {}// boomin-relay
  public boominReportsObj: any = {}//
  public boominResultsObj = {
    history: { checklists: {}, identifiers: {}, listCustom: {}, matched: {}, onSite: {}, subPages: {}, summary: {} },
    propRemoveField: ['autoField',],
    propRemoveResult: ['deviceMatchKey', 'matching', 'resultIn', 'companyDB', 'dateModified', 'dbDate', 'dbTimestamp', 'duration', 'images', 'policyKey', 'processed', 'timestamp', 'v',],
    live: { checklists: {}, identifiers: {}, listCustom: {}, matched: {}, onSite: {}, subPages: {}, summary: {} },
    liveLoaded: {},
    loadingData: {},
    retryCount: {},
    start: {},
  } //roll-call, live-feed-boomin, utility
  public chatsObj: any = {}
  public chatsActive: boolean = false
  public checklistsObj: any = {}
  public checklistsDoneObj: any = {}
  public checkpointsObj: any = {}
  public clockings: any[] = []
  private clockingsRef: any
  public companyObj: any = {}
  public defaultReportsObj: any = {}
  public contactsObj: any = {}
  public contactsActive: boolean = false
  public devicesObj: any = {}
  public devicesBatteryObj: any = {}
  public devicesLastSeenObj: any = {}
  public devicesRestartsObj: any = {}
  public deviceScreenshotsObj: any = {}
  public devicesSitesObj: any = {}
  public deviceUpdateAllowed: boolean = false
  public deviceUpdatesObj: any = {}
  public guardsObj: any = {}
  public globalChatEnabled: boolean = true
  public hasNotScannedPatrolAlert: any
  public incidentsObj: any = {}
  public incidentsChkNotificationsObj: any = {}
  public incidentsPoliciesObj: any = {}
  public incidentsAllPoliciesObj: any = {}
  public incidentsTemplatesObj: any = {}
  public inventoryEnabled: boolean = false
  public loaded = {
    all: false, initial: false,
    alerts: false,
    devices: false,
    sites: false,
    userPolicies: false,
    users: false,
    guards: false,
    contacts: false,
    checkpoints: false,
    checklists: false,
    patrols: false,
    chats: false,
checkins:false,
  }
  public platformReady: boolean = false
  public newMSG: number = 0
  public noChats: boolean = false
  public notification = new Audio('assets/audio/notification.mp3')
  private observerObj = {
    alerts: new Subject<Change>(),
    chats: new Subject<Change>(),
    boominEvents: new Subject<Change>(),
    boominLists: new Subject<Change>(),
    boominPages: new Subject<Change>(),
    boominPolicies: new Subject<Change>(),
    boominRelays: new Subject<Change>(),
    boominReports: new Subject<Change>(),
    boominResults: new Subject<Change>(),
    checklists: new Subject<Change>(),
    checkins: new Subject<Change>(),
    checkpoints: new Subject<Change>(),
    contacts: new Subject<Change>(),
    defaultReports: new Subject<Change>(),
    deviceBattery: new Subject<Change>(),
    deviceLastSeen: new Subject<Change>(),
    devices: new Subject<Change>(),
    guards: new Subject<Change>(),
    incidentsPolicies: new Subject<Change>(),
    incidentsTemplates: new Subject<Change>(),
    patrols: new Subject<Change>(),
    ptt: new Subject<Change>(),
    reportTemplates: new Subject<Change>(),
    roster: new Subject<Change>(),
    sites: new Subject<Change>(),
    userUserEmailBlocking: new Subject<Change>(),
    userPolicies: new Subject<Change>(),
    users: new Subject<Change>(),
  }
  public changesObj = {
    alerts: this.observerObj.alerts.asObservable(),
    chats: this.observerObj.chats.asObservable(),
    boominEvents: this.observerObj.boominEvents.asObservable(),
    boominLists: this.observerObj.boominLists.asObservable(),
    boominPages: this.observerObj.boominPages.asObservable(),
    boominPolicies: this.observerObj.boominPolicies.asObservable(),
    boominRelays: this.observerObj.boominRelays.asObservable(),
    boominReports: this.observerObj.boominReports.asObservable(),
    boominResults: this.observerObj.boominResults.asObservable(),
    checklists: this.observerObj.checklists.asObservable(),
    checkins: this.observerObj.checkins.asObservable(),
    checkpoints: this.observerObj.checkpoints.asObservable(),
    contacts: this.observerObj.contacts.asObservable(),
    defaultReports: this.observerObj.defaultReports.asObservable(),
    deviceBattery: this.observerObj.deviceBattery.asObservable(),
    deviceLastSeen: this.observerObj.deviceLastSeen.asObservable(),
    devices: this.observerObj.devices.asObservable(),
    guards: this.observerObj.guards.asObservable(),
    incidentsPolicies: this.observerObj.incidentsPolicies.asObservable(),
    incidentsTemplates: this.observerObj.incidentsTemplates.asObservable(),
    patrols: this.observerObj.patrols.asObservable(),
    ptt: this.observerObj.ptt.asObservable(),
    reportTemplates: this.observerObj.reportTemplates.asObservable(),
    roster: this.observerObj.roster.asObservable(),
    sites: this.observerObj.sites.asObservable(),
    userUserEmailBlocking: this.observerObj.userUserEmailBlocking.asObservable(),
    userPolicies: this.observerObj.userPolicies.asObservable(),
    users: this.observerObj.users.asObservable(),
  }
  public panic = new Audio('assets/audio/panic.mp3')
  public panicAlarm: boolean = false
  public patrolsObj: any = {}
  public permissions: any = {}
  public pptEnabled: boolean = false
  public pptSiteMode: boolean = false
  public reportTemplatesObj: any = {}
  private retryMax = 1
  public rostersObj: any = {}
  private rosterRef: any
  public scanToPatrol: boolean = false
  public shift: any = { day: '06:00', night: '18:00' }
  public sitesObj: any = {}
  public siteRelays: any = {}
  private unsubscribe
  public userEmailsBlockedObj: any = {}
  public userPoliciesObj: any = {}
  public usersObj: any = {}
  public version: string = 'v3.4.19'
  private isAdminUser: boolean = false

  constructor(
    private authService: AuthService,
    private dateService: DateService,
    private loading: LoadingService,
    public platform: Platform,
    private zone: NgZone
  ) {
    let runOnce = true
    this.unsubscribe = new Subject()

    this.platform.ready().then(() => {
      this.platformReady = true

      const interval = setInterval(() => {
        if (this.authService.user?.companyDB) {
          if (runOnce) this.dateService.initialize(), runOnce = false
          if (this.dateService.loaded) {
            clearInterval(interval)
            this.isAdminUser = (this.authService.user.isDev || this.authService.user.isAdmin || this.authService.user.isGlobal)
            this.initialize()
          }
        }
      }, 100)
      if (this.authService.showError) console.error(this.TAG, '@@@ Data Service Init @@@')
      // sayHello()
      // sayGoodbye()
    }) //this.platform.ready()
  } //end ()

  private initialize() {
    console.warn(this.TAG, 'initialize()', 'date timeStartup', new Date(this.dateService.startup), this.dateService.startup)
    this.loading.present()
    this.companyHandler()
    this.sitesHandler()
  } //end initialize()

  private loadAllHandlers() {
    console.log(this.TAG, 'loadAllHandlers()', this.isAdminUser)
    let promisedLoad = []
    promisedLoad.push(this.alertsHandler())
    if (this.isAdminUser) promisedLoad.push(this.boominEventsHandler())
    if (this.isAdminUser) promisedLoad.push(this.boominListsHandler())
    if (this.isAdminUser) promisedLoad.push(this.boominPagesHandler())
    if (this.isAdminUser) promisedLoad.push(this.boominPoliciesHandler())
    if (this.isAdminUser) promisedLoad.push(this.boominRelaysHandler())
    if (this.isAdminUser) promisedLoad.push(this.boominReportsHandler())
    promisedLoad.push(this.chatsHandler())
    promisedLoad.push(this.checklistsDoneHandler())
    if (this.isAdminUser) promisedLoad.push(this.checklistsHandler())
    promisedLoad.push(this.checkpointsHandler())
    promisedLoad.push(this.clockingsHandler())
    if (this.isAdminUser) promisedLoad.push(this.contactsHandler())
    promisedLoad.push(this.defaultReportTemplatesHandler())
    promisedLoad.push(this.devicesHandlerBattery())
    promisedLoad.push(this.devicesHandlerLastSeen())
    promisedLoad.push(this.devicesHandlerRestart())
    promisedLoad.push(this.devicesHandlerScreenshot())
    if (this.isAdminUser || this.authService.user.email === 'rianam@redalert.co.za') promisedLoad.push(this.guardsHandler())
    promisedLoad.push(this.incidentsDoneHandler())
    if (this.isAdminUser) promisedLoad.push(this.incidentsPoliciesHandler())
    if (this.isAdminUser) promisedLoad.push(this.incidentsTemplatesHandler())
    if (this.isAdminUser) promisedLoad.push(this.patrolsHandler())
    promisedLoad.push(this.permissionsHandler())
    promisedLoad.push(this.reportTemplatesHandler())
    promisedLoad.push(this.rosterHandler())
    promisedLoad.push(this.settingsChatHandler())
    promisedLoad.push(this.settingsContactsHandler())
    promisedLoad.push(this.settingsHasNotScannedPatrolAlertHandler())
    promisedLoad.push(this.settingsInventoryHandler())
    promisedLoad.push(this.settingsPTTHandler())
    promisedLoad.push(this.settingsScanToPatrolHandler())
    promisedLoad.push(this.settingsShiftHandler())
    if (this.isAdminUser) promisedLoad.push(this.userEmailBlockedHandler())
    promisedLoad.push(this.userPoliciesHandler())
    promisedLoad.push(this.usersHandler())
    Promise.all(promisedLoad).then(() => {
      this.loading.dismiss()
      this.dateService.changes.pipe(takeUntil(this.unsubscribe)).subscribe((val) => {
        if (val.event === 'modified' && val.type === 'date') { this.rosterHandler() }
      }) //end this.dateService.changes.pipe(takeUntil(this.unsubscribe)).subscribe()
      this.loaded.all = true
      if (this.authService.showError) console.error(this.TAG, '@@@ Data Service Init Complete @@@')
      this.devicesHandlerUpdate()
    })
  } //end loadAllHandlers()

  private alertAdd(alertData, load) {
    const alert = { ...alertData.doc.data() as any, key: alertData.doc.id }
    if (this.sitesObj[alert?.siteKey] || this.alertsObj[alert.key] || alert.userID) {
      if (this.sitesObj[alert.siteKey]?.name) alert.siteName = this.sitesObj[alert.siteKey].name
      if (this.devicesObj[alert.imei]?.name) alert.deviceName = this.devicesObj[alert.imei].name
      if (this.devicesObj[alert.imei]?.cell) alert.deviceCell = this.devicesObj[alert.imei].cell
      if (alert.alertType === 'pa') alert.type = alert.silent ? 'silent' : alert.medical ? 'medical' : 'normal'

      if (alertData.type === 'added' && (this.sitesObj[alert.siteKey] || alert.userID)) {
        if (!alert.read && !load) this.playNotificationSound(alert.alertType)
        this.alertsObj[alert.key] = alert
        this.observerObj.alerts.next({ event: alertData.type, key: alert.key })
      } //end if (alertData.type === 'added')
      else if (alertData.type === 'modified') {
        if (this.sitesObj[alert.siteKey] || alert.userID) {
          this.alertsObj[alert.key] = alert
          this.observerObj.alerts.next({ event: alertData.type, key: alert.key })
        } else {
          this.alertRemove(alert)
        }
      } //end if (alertData.type === 'modified')
      else if (alertData.type === 'removed') { this.alertRemove(alert) }
    } //end if (this.sitesObj[alert?.siteKey] || this.alertsObj[alert.key])
  } //end alertAdd()

  alertRemove(alert) {
    console.log(this.TAG, 'alertRemove()', { ...alert })
    if (this.alertsObj[alert.key]) {
      delete this.alertsObj[alert.key]
      this.observerObj.alerts.next({ event: 'removed', key: alert.key })
    }
  } //end alertRemove()

  private alertsHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      const alertTypes = ['hns', 'fts', 'qp', 'fp', 'ip', 'enc', 'bc', 'bl', 'pa', 'pcm']
      await this.authService.firestore.collection('alerts').where('companyDB', '==', this.authService.user.companyDB).where('alertType', 'in', alertTypes).where('date', '>=', this.dateService.fsYesterday).orderBy('date', 'desc').limit(3000)
        .onSnapshot(alertsSnapshot => {
          this.zone.run(() => {
            alertsSnapshot.docChanges().forEach(alertData => {
              this.alertAdd(alertData, runOnce)
            }) //alertsSnapshot.docChanges().forEach()
            if (runOnce) {
              runOnce = false
              this.loaded.alerts = true
              this.observerObj.alerts.next({ event: 'loaded-alerts' })
              resolve(true)
              console.log(this.TAG, 'alertsHandler()', Object.keys(this.alertsObj).length)
            } //end if (runOnce)
          }) //end this.zone.run()
        }) //.onSnapshot()
    }) //end new Promise()
  } //end alertsHandler()

  private boominEventsHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('boomin-events').where('companyDB', '==', this.authService.user.companyDB)
        .onSnapshot(eventsSnapshot => {
          this.zone.run(() => {
            eventsSnapshot.docChanges().forEach(boominEventData => {
              const boominEvent = { ...boominEventData.doc.data() as any, key: boominEventData.doc.id }
              if (boominEventData.type === 'added' || boominEventData.type === 'modified') this.boominEventsObj[boominEvent.key] = boominEvent
              else if (boominEventData.type === 'removed' && this.boominEventsObj[boominEvent.key]) delete this.boominEventsObj[boominEvent.key]
              this.observerObj.boominEvents.next({ event: boominEventData.type, key: boominEventData.doc.id, siteKey: boominEvent.siteKey })
            }) //eventsSnapshot.docChanges().forEach()
            if (runOnce) {
              runOnce = false
              this.observerObj.boominEvents.next({ event: 'loaded' })
              resolve(true)
              console.log(this.TAG, 'boominEventsHandler()', Object.keys(this.boominEventsObj).length)
            } //end if (runOnce)
          }) //end this.zone.run()
        }) //end this.auth.firestore.collection('boomin-events')
    }) //end new Promise()
  } //end boominEventsHandler()

  private boominListsDelete(list) {
    ['black', 'watch', 'white'].map(type => delete this.boominListsObj[list.type][type][list.identifier])
  } //end boominListsDelete()

  private boominListsHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('boomin-lists').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(listsSnapshot => {
        this.zone.run(() => {
          listsSnapshot.docChanges().forEach(listData => {
            const list = { ...listData.doc.data() as any, key: listData.doc.id }
            if (listData.type === 'added' || listData.type === 'modified') {
              if (list.listType !== 'custom') {
                if (listData.type === 'modified') this.boominListsDelete(list)
                this.boominListsObj[list.type][list.listType][list.identifier] = list
              } else {
                if (!this.boominListsObj.custom[list.type]) this.boominListsObj.custom[list.type] = {}
                this.boominListsObj.custom[list.type][list.key] = list
              } //end if (list.listListType !== 'custom')
            } //end if (listData.type === 'modified')
            else if (listData.type === 'removed') {
              if (list.listType !== 'custom') {
                this.boominListsDelete(list)
              } else {
                delete this.boominListsObj.custom[list.type][list.key]
              } //end if (list.listListType !== 'custom')
            }
            this.observerObj.boominLists.next({ event: listData.type, key: list.key })
          }) //listsSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.observerObj.boominLists.next({ event: 'loaded' })
            console.log(this.TAG, 'boominListsHandler()', Object.keys(this.boominListsObj).length, (this.authService.isDev ? this.boominListsObj : ''))
            resolve(true)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('boomin-lists')
    }) //end new Promise()
  } //end boominListsHandler()

  boominPageEventsModify(page, pageKey) {
    return new Promise<void>(async (resolve, reject) => {
      const events = Object.values(this.boominEventsObj).filter((event: any) => event.pagesObj[pageKey]) as any
      if (events.length) {
        for (let event of events) {
          event = { ...event, ...this.authService.defaultDoc() }
          event.pagesObj[pageKey] = { ...event.pagesObj[pageKey], ...page }
          await this.authService.firestore.doc(`boomin-events/${event.key}`).set(event).then(() => {
            if (this.authService.isDev) console.log(this.TAG, 'boominPageEventsModify () page', page.name, 'in event', event.key, ' => ', event.name)
          }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //end this.auth.firestore.collection('boomin-events').where('pagesKeys', 'array-contains', pageKey).get()      }
        } //end for (const event of events)
      } //end if (events.length)
      resolve()
    }) //end Promise
  } //end boominPageEventsEdit()

  private boominPagesHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('boomin-pages').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(settingsSnapshot => {
        this.zone.run(() => {
          settingsSnapshot.docChanges().forEach(boominPageData => {
            const boominPage = { ...boominPageData.doc.data() as any, key: boominPageData.doc.id }
            this.metadataRemove(boominPage)
            if (boominPageData.type === 'added' || boominPageData.type === 'modified') this.boominPagesObj[boominPage.key] = boominPage
            else if (boominPageData.type === 'removed' && this.boominPagesObj[boominPage.key]) delete this.boominPagesObj[boominPage.key]
            this.observerObj.boominPages.next({ event: boominPageData.type, key: boominPage.key, siteKey: boominPage.siteKey })
          }) //pagesSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.observerObj.boominPages.next({ event: 'loaded' })
            console.log(this.TAG, 'boominPagesHandler()', Object.keys(this.boominPagesObj).length)
            resolve(true)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.doc('settings/defaultSettings'))
    }) //end new Promise()
  } //end boominPagesHandler()

  private boominPoliciesHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('boomin-policies').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(policiesSnapshot => {
        this.zone.run(() => {
          policiesSnapshot.docChanges().forEach(boominPolicyData => {
            const boominPolicy = { ...boominPolicyData.doc.data() as any, key: boominPolicyData.doc.id }
            if (boominPolicyData.type === 'added' || boominPolicyData.type === 'modified') this.boominPoliciesObj[boominPolicy.key] = boominPolicy
            else if (boominPolicyData.type === 'removed' && this.boominPoliciesObj[boominPolicy.key]) delete this.boominPoliciesObj[boominPolicy.key]
            this.observerObj.boominPolicies.next({ event: boominPolicyData.type, key: boominPolicy.key })
          }) //policiesSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.observerObj.boominPolicies.next({ event: 'loaded' })
            console.log(this.TAG, 'boominPolicysHandler()', Object.keys(this.boominPoliciesObj).length)
            resolve(true)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('boomin-policies')
    }) //end new Promise()
  } //end boominPoliciesHandler()

  private boominRelaysHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('boomin-relays').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(relaysSnapshot => {
        this.zone.run(() => {
          relaysSnapshot.docChanges().forEach(boominRelayData => {
            const boominRelay = { ...boominRelayData.doc.data() as any, key: boominRelayData.doc.id }
            if (boominRelayData.type === 'added' || boominRelayData.type === 'modified') this.boominRelaysObj[boominRelay.key] = boominRelay, this.siteRelays[boominRelay.siteKey] = boominRelay?.enabled || false
            else if (boominRelayData.type === 'removed' && this.boominRelaysObj[boominRelay.key]) delete this.boominRelaysObj[boominRelay.key], delete this.siteRelays[boominRelay.siteKey]
            this.observerObj.boominRelays.next({ event: boominRelayData.type, key: boominRelay.key, siteKey: boominRelay.siteKey })
          }) //relaysSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.observerObj.boominRelays.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'boominRelaysHandler()', Object.keys(this.boominRelaysObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('boomin-relays')
    }) //end new Promise()
  } //end boominPoliciesHandler()

  private boominReportsHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('boomin-reports').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(reportsSnapshot => {
        this.zone.run(() => {
          reportsSnapshot.docChanges().forEach(boominReportData => {
            const boominReport = { ...boominReportData.doc.data() as any, key: boominReportData.doc.id }
            if (boominReportData.type === 'added' || boominReportData.type === 'modified') this.boominReportsObj[boominReport.key] = boominReport
            else if (boominReportData.type === 'removed' && this.boominReportsObj[boominReport.key]) delete this.boominReportsObj[boominReport.key]
            this.observerObj.boominReports.next({ event: boominReportData.type, key: boominReport.key })
          }) //reportsSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.observerObj.boominReports.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'boominReportsHandler()', Object.keys(this.boominReportsObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('boomin-reports')
    }) //end new Promise()
  } //end boominPoliciesHandler()

  private boominResultsAdd(boomin, isLive) {
    //TODO: continue boomin results objects cleanup
    if (this.sitesObj[boomin?.siteKey]) {
      this.boominResultsObj.propRemoveResult.map(key => delete boomin[key])
      const liveKey = isLive ? 'live' : 'history'
      if (!this.boominResultsObj[liveKey].listCustom?.[boomin.siteKey]) this.boominResultsObj[liveKey].listCustom[boomin.siteKey] = {}
      if (!this.boominResultsObj[liveKey].matched?.[boomin.siteKey]) this.boominResultsObj[liveKey].matched[boomin.siteKey] = {}
      if (!this.boominResultsObj[liveKey].summary?.[boomin.siteKey]) this.boominResultsObj[liveKey].summary[boomin.siteKey] = {}
      const preciseMatch = this.sitesObj[boomin.siteKey].boomin?.matching?.report?.out || false
      boomin.pagesCount = {}
      boomin.showMore = false
      boomin.identifiersIn = {}
      boomin.identifiersOut = {}
      boomin.matching = { match: false, nomatch: true, mismatch: false }

      // console.warn(this.TAG, 'boominResultsAdd', { ...boomin },boomin.mode)

      if (boomin.list === 'black') boomin.list = 'block'
      else if (boomin.list === 'white') boomin.list = 'priority'

      for (let pageKey in boomin.pages) {
        const page = boomin.pages[pageKey]
        boomin.pagesCount[`${page.name}`] ? boomin.pagesCount[`${page.name}`]++ : boomin.pagesCount[`${page.name}`] = 1
        page.fieldsReport = [], page.fieldsSummary = [], page.fieldsSubPages = []
        page.fields = Object.values(page.fields)
        for (let fieldKey in page.fields) {
          const field = page.fields[fieldKey]
          if (field.title.toLowerCase().includes('date')) field.value = new Date(field.value).toLocaleDateString()
          if (field.autoField) {
            if (!(page.identifier || page.identifierType)) {
              if (field.autoField === 'idNumber') [page.identifier, page.identifierType] = [field.value, 'person']
              else if (field.autoField === 'licenceNo') [page.identifier, page.identifierType] = [field.value, 'vehicle']
              else if (field.autoField === 'barcode') [page.identifier, page.identifierType] = [field.value, field.title.replace(' ', '').toLowerCase()]
            } //end if (!(page.identifier || page.identifierType))
          } //end if (field.autoField )

          if (field.type === 'number') field.value += ''
          if (field.value || field.thumb || field.image) {
            if (field.report) { page.fieldsReport.push(fieldKey) }
            if (field.summary || (page.identifier && (field.thumb || field.image))) { page.fieldsSummary.push(fieldKey) }
          } //end if (field.value || field.thumb || field.image || field.imageUrl)

          if (field.listIdentifier) {
            if (!page?.listIdentifiers?.length) page.listIdentifiers = []
            page.listIdentifiers.push(field.value)
          } //end if (field.listIdentifier)
          // this.boominResultsObj.propRemoveField.map(key => delete field[key])
        } //end for (let field of Object.values(page.fields))
        if (page.identifier && !page.listName) {
          if (!this.boominResultsObj[liveKey].onSite?.[boomin.siteKey]) this.boominResultsObj[liveKey].onSite[boomin.siteKey] = {}
          if (!this.boominResultsObj[liveKey].identifiers?.[boomin.siteKey]) this.boominResultsObj[liveKey].identifiers[boomin.siteKey] = {}
          const identifierType = page.identifierType, identifier = page.identifier
          if (!this.boominResultsObj[liveKey].identifiers[boomin.siteKey][identifierType]) this.boominResultsObj[liveKey].identifiers[boomin.siteKey][identifierType] = {}
          if (!this.boominResultsObj[liveKey].identifiers[boomin.siteKey][identifierType][identifier]) this.boominResultsObj[liveKey].identifiers[boomin.siteKey][identifierType][identifier] = []
          const rIdentifier = { pageKey: pageKey, boominKey: boomin.key }
          const skip = this.boominResultsObj[liveKey].identifiers[boomin.siteKey][identifierType][identifier].findIndex(clocking => clocking.date === boomin.date && clocking.identifier === boomin.identifier && clocking.boominKey === rIdentifier.boominKey && clocking.mode === boomin.mode) >= 0
          if (!skip) this.boominResultsObj[liveKey].identifiers[boomin.siteKey][identifierType][identifier].push(rIdentifier)
          if (boomin.mode === 'in') {
            if (!boomin.identifiersIn[identifierType]) boomin.identifiersIn[identifierType] = {}
            if (!boomin.identifiersIn[identifierType][identifier]) boomin.identifiersIn[identifierType][identifier] = {}
            boomin.identifiersIn[identifierType][identifier] = { boominKey: boomin.key, pageKey: pageKey }
            if (boomin.allowed) this.boominResultsObj[liveKey].onSite[boomin.siteKey][identifier] = { identifierType: identifierType, boominKey: boomin.key, pageKey: pageKey }
          } else if (boomin.mode === 'out') {
            if (!boomin.identifiersOut[identifierType]) boomin.identifiersOut[identifierType] = {}
            if (!boomin.identifiersOut[identifierType][identifier]) boomin.identifiersOut[identifierType][identifier] = {}
            boomin.identifiersOut[identifierType][identifier] = { boominKey: boomin.key, pageKey: pageKey }
            if (this.boominResultsObj[liveKey].onSite[boomin.siteKey][identifier] && boomin.allowed) {
              if (!boomin.identifiersIn[identifierType]) boomin.identifiersIn[identifierType] = {}
              if (!boomin.identifiersIn[identifierType][identifier]) {
                const result = this.boominResultsObj[liveKey].onSite[boomin.siteKey][identifier]
                const identifierIn = { ...this.boominResultsObj[liveKey].summary[boomin.siteKey][result.boominKey].identifiersIn[identifierType][identifier] }
                boomin.identifiersIn[identifierType][identifier] = { ...identifierIn }

                if (identifierType === 'person' || identifierType === 'vehicle') {
                  if (!boomin.resultIn) {
                    boomin.resultIn = { ...this.boominResultsObj[liveKey].summary[boomin.siteKey][result.boominKey] }
                    boomin.durationMatch = (boomin.date - boomin.resultIn.date)
                  } else if (boomin.resultIn?.date < this.boominResultsObj?.[liveKey]?.summary?.[boomin.siteKey]?.[result.boominKey]?.date) {
                    boomin.resultIn = { ...this.boominResultsObj[liveKey].summary[boomin.siteKey][result.boominKey] }
                    boomin.durationMatch = (boomin.date - boomin.resultIn.date)
                  } //end if (!boomin.resultIn)
                  if (!preciseMatch) { for (const type in boomin.resultIn.identifiersIn) { for (const identifier in boomin.resultIn.identifiersIn[type]) { delete this.boominResultsObj[liveKey].onSite[boomin.siteKey][identifier] } } }
                } //end if (identifierType === 'person' || identifierType === 'vehicle')
              } //end if (!boomin.identifiersIn[identifierType][identifier])
              delete this.boominResultsObj[liveKey].onSite[boomin.siteKey][identifier]
            } //end if (this.boominResultsObj[`${isLive?'live':'history'}`].onSite[boomin.siteKey][identifier])
            if (boomin.allowed) this.boominResultsObj[liveKey].matched[boomin.siteKey][boomin.key] = boomin
          } //end if (boomin.mode === 'in')
        } //end if (page.identifier)

        if (page?.subPages?.length) {
          page.subPages[0].fields = Object.values(page.subPages[0].fields)
          page.subPages[0].fieldsReport = []
          for (let fieldKey in page.subPages[0].fields) {
            const field = page.subPages[0].fields[fieldKey]
            if (field.title.toLowerCase().includes('date')) field.value = new Date(field.value).toLocaleDateString()
            if (field.type === 'number') field.value += ''
            page.subPages[0].fieldsReport.push(fieldKey)
          } //end for (let field of Object.values(page.fields))
          delete page.subPages[0].pageKey
          const subPage = page.subPages[0]
          if (!boomin.subPages) boomin.subPages = {}
          if (!boomin.subPages[subPage.name]) boomin.subPages[subPage.name] = []
          // for (let field of Object.values(subPage.fields)) { page.fieldsSubPages.push(field) }
          boomin.subPages[subPage.name].push(pageKey)

          if (!this.boominResultsObj[liveKey].subPages?.[boomin.siteKey]) this.boominResultsObj[liveKey].subPages[boomin.siteKey] = {}
          if (!this.boominResultsObj[liveKey].subPages[boomin.siteKey][subPage.name]) {
            this.boominResultsObj[liveKey].subPages[boomin.siteKey][subPage.name] = []
            if (this.authService.isDev) console.warn(this.TAG, 'boomin lf add sub page', '|', subPage.name, '|', this.sitesObj?.[boomin.siteKey]?.name, /* JSON.parse(JSON.stringify(page)), this.boominResultsObj[liveKey].subPages[boomin.siteKey] */)
          }
          this.boominResultsObj[liveKey].subPages[boomin.siteKey][subPage.name].push(boomin.key)
        } //end if (page?.subPages?.length)

        if (page?.listIdentifiers?.length) {
          if (!this.boominResultsObj[liveKey].listCustom[boomin.siteKey][page.listName]) {
            this.boominResultsObj[liveKey].listCustom[boomin.siteKey][page.listName] = []
            this.observerObj.boominResults.next({ event: 'boomin-result-custom-list', siteKey: boomin.siteKey, key: boomin.key, data: { name: page.listName, type: page.listName.toLowerCase() } })
            if (this.authService.isDev) console.warn(this.TAG, 'boomin add custom list', '|', page.listName, '|', this.sitesObj?.[boomin.siteKey]?.name, /* JSON.parse(JSON.stringify(page)), this.boominResultsObj[liveKey].listCustom[boomin.siteKey] */)
          }
          console.error(this.TAG, 'custom list item', page)
          if (page.listName === 'Staff') {
            const index = this.boominResultsObj[liveKey].listCustom[boomin.siteKey][page.listName].findIndex(item => item.identifiers.some(r => page.listIdentifiers.includes(r)))
            let duration: number = 0
            if (boomin.mode === 'in') {
              duration = 0
            } else if (boomin.mode === 'out') {
              if (index >= 0) {
                for (let itemIndex = this.boominResultsObj[liveKey].listCustom[boomin.siteKey][page.listName][index].items.length - 1; itemIndex >= 0; itemIndex--) {
                  const element = this.boominResultsObj[liveKey].listCustom[boomin.siteKey][page.listName][index].items[itemIndex]
                  if (element.mode === 'out') continue
                  if (element.mode === 'in' && element.dateOut) break
                  this.boominResultsObj[liveKey].listCustom[boomin.siteKey][page.listName][index].duration += (boomin.date - element.date)
                  this.boominResultsObj[liveKey].listCustom[boomin.siteKey][page.listName][index].items[itemIndex].dateOut = boomin.date
                  break
                }
              } //end if (index >= 0)
            } //end if (boomin.mode === 'in')

            if (index >= 0) {
              this.boominResultsObj[liveKey].listCustom[boomin.siteKey][page.listName][index].items.push({ ...page, date: boomin.date, mode: boomin.mode, boominKey: boomin.key })
              this.boominResultsObj[liveKey].listCustom[boomin.siteKey][page.listName][index].onsite = (boomin.mode === 'in')
            }
            else {
              this.boominResultsObj[liveKey].listCustom[boomin.siteKey][page.listName].push({ duration: duration, identifiers: page.listIdentifiers, items: [{ ...page, date: boomin.date, mode: boomin.mode, boominKey: boomin.key }], onsite: (boomin.mode === 'in') })
            } //end if (index >= 0)
            // console.log(this.TAG, index, { ...boomin }, { ...this.boominResultsObj[liveKey].listCustom[boomin.siteKey] }, { ...this.boominResultsObj[liveKey].onSite[boomin.siteKey] })
          } else {
            this.boominResultsObj[liveKey].listCustom[boomin.siteKey][page.listName].push({ identifiers: page.listIdentifiers, items: [{ ...page, date: boomin.date, mode: boomin.mode, boominKey: boomin.key }] })
          }//end if (page.listName === 'Staff')
        } //end if (page?.listIdentifiers?.length)
      } //end for (let field of Object.values(page.fields))

      if (boomin.mode === 'in') {
        boomin.categoryColor = 'green'
      } else if (boomin.mode === 'out') {
        boomin.categoryColor = 'danger'
        if (Object.keys(boomin.identifiersIn).length && Object.keys(boomin.identifiersOut).length && boomin.allowed) {
          boomin.matching = { match: true, nomatch: false, mismatch: false }
          if (preciseMatch) {
            if (Object.keys(boomin.identifiersIn).length != Object.keys(boomin.identifiersOut).length) {
              boomin.matching = { match: false, nomatch: false, mismatch: true }
            } else {
              for (const type in boomin.resultIn.identifiersIn) {
                if (boomin.identifiersOut[type]) {
                  for (const key of Object.keys(boomin.identifiersIn[type])) {
                    const identifier = boomin.identifiersIn[type][key]
                    identifier.match = (identifier.boominKey === boomin.resultIn.key)
                    if (!identifier.match) boomin.matching = { match: false, nomatch: false, mismatch: true }
                  }
                } else { boomin.matching = { match: false, nomatch: false, mismatch: true } } //end if (boomin.identifiersOut[type])
              } //end for (const type in boomin.identifiersIn)
            } //end if (Object.keys(boomin.identifiersIn).length != Object.keys(boomin.identifiersOut).length)
          }//end if (preciseMatch)
        } //end if (Object.keys(boomin.identifiersIn).length && Object.keys(boomin.identifiersOut).length)
        this.boominResultsObj[liveKey].matched[boomin.siteKey][boomin.key] = boomin
      } //end if (boomin.mode === 'in')

      if (boomin.type === 'boomin-checklist') {
        if (!this.boominResultsObj[liveKey].checklists?.[boomin.siteKey]) this.boominResultsObj[liveKey].checklists[boomin.siteKey] = {}
        boomin.checklist = true
        this.boominResultsObj[liveKey].checklists[boomin.siteKey][boomin.key] = boomin
      } else {
        this.boominResultsObj[liveKey].summary[boomin.siteKey][boomin.key] = boomin
      } //end if (boomin.type === 'boomin-checklist')
      // if (this.auth.showError) console.error(this.TAG, 'BOOMIN ', this.boominResultsObj)
      this.observerObj.boominResults.next({ event: 'added', siteKey: boomin.siteKey, key: boomin.key })
      // console.error(this.TAG, 'BOOMIN ADDED', 'siteKey', boomin.siteKey, 'boominKey', boomin.key)
    } //end if (this.sitesObj[boominResult?.siteKey])
  } //end boominResultsAdd()

  private boominResultsInitialise(siteKey, proceed, isLive) {
    this.boominResultsObjClear(siteKey, isLive)
    this.boominResultsObj.liveLoaded[siteKey] = false
    this.boominResultsObj.loadingData[siteKey] = false
    this.boominResultsObj.retryCount[siteKey] = 0
    this.boominResultsObj.start[siteKey] = ''
    if (proceed) {
      const times = this.sitesObj[siteKey]?.boomin?.times?.dayStartSite ? this.sitesObj[siteKey]?.boomin?.times?.dayStartSite.split(':').map(Number) : [0, 0]
      const todayRefresh = new Date(new Date().setHours(times[0], times[1], 0, 0)), tomorrowRefresh = new Date(new Date(todayRefresh).setDate(todayRefresh.getDate() + 1))
      const timerTomorrow = tomorrowRefresh.getTime() - Date.now(), timerToday = todayRefresh.getTime() - Date.now()
      const [timer, when] = timerToday >= 1000 ? [timerToday, 'Today'] : [timerTomorrow, 'Tomorrow'], at = new Date(Date.now() + timer).toLocaleString('en-ZA')
      const start = new Date(new Date(Date.now() + timer).setDate(new Date(Date.now() + timer).getDate() - 1))
      setTimeout(() => { this.boominResultsInitialise(siteKey, true, true) }, timer)
      this.boominResultsObj.start[siteKey] = start
      // if (this.auth.isDev) if (this.auth.showWarn) console.warn(this.TAG, 'boominResultsInitialise()', this.sitesObj?.[siteKey]?.name, 'isLive', isLive, at, timer, timerToday, timerTomorrow)
    }
    this.observerObj.boominResults.next({ event: 'refresh', siteKey: siteKey })
  } //end boominResultsInitialise()

  public boominResultsObjClear(siteKey: string, isLive?: boolean) {
    // if (this.auth.isDev) if (this.auth.showWarn) console.warn(this.TAG, 'boominResultsObjClear()', this.sitesObj?.[siteKey]?.name, 'isLive', isLive)
    const liveKey = isLive ? 'live' : 'history'
    delete this.boominResultsObj[liveKey].checklists[siteKey]
    delete this.boominResultsObj[liveKey].identifiers[siteKey]
    delete this.boominResultsObj[liveKey].listCustom[siteKey]
    delete this.boominResultsObj[liveKey].matched[siteKey]
    delete this.boominResultsObj[liveKey].onSite[siteKey]
    delete this.boominResultsObj[liveKey].subPages[siteKey]
    delete this.boominResultsObj[liveKey].summary[siteKey]
  } //end boominResultsObjClear()

  async boominResultsLoad(siteKey: string, isLive: boolean, time?: { start, end }) {
    if (this.authService.isDev) if (this.authService.showError) console.error(this.TAG, 'boominResultsLoad', isLive, time, this.sitesObj[siteKey].name)
    const start = time?.start || this.boominResultsObj.start[siteKey], end = time?.end || new Date(new Date(start).setDate(start.getDate() + 1))
    try {
      // if (!isLive) this.loading.present(60000)
      this.boominResultsObj.loadingData[siteKey] = true
      const snapshot = await this.authService.firestore.collection('boomin-results').orderBy('date', 'asc').where('siteKey', '==', siteKey).where('processed', '==', true).where('date', '>=', start.getTime()).where('date', '<=', end.getTime()).limit(1000).get()
      this.boominResultsLoadAdd(snapshot, siteKey, isLive, { start, end })
    } catch (error) {
      if (this.authService.showError) console.error(this.TAG, error)
      this.boominResultsObj.retryCount[siteKey]++
      if (this.boominResultsObj.retryCount[siteKey] <= this.retryMax) setTimeout(() => this.boominResultsLoad(siteKey, isLive, { start, end }), 1000)
      else {
        this.boominResultsLoadFinished(siteKey, isLive)
        alert(`Error loading data please try again! `)
      }
    } //end try
  } //end boominResultsLoad()

  private boominResultsLoadAdd(snapshot, siteKey, isLive, time: { start, end }) {
    if (this.authService.isDev) if (this.authService.showError) console.error(this.TAG, 'addLogs', isLive, this.sitesObj[siteKey].name, (this.boominResultsObj[`${isLive ? 'live' : 'history'}`].summary[siteKey] ? Object.keys(this.boominResultsObj[`${isLive ? 'live' : 'history'}`].summary[siteKey]).length : 0))
    if (!snapshot.empty) {
      const lastDate = snapshot.docs[snapshot.docs.length - 1].data().date
      this.boominResultsLoadNext(lastDate, siteKey, isLive, time)
      snapshot.docs.forEach(boominResultsData => this.boominResultsAdd({ ...boominResultsData.data() as any, key: boominResultsData.id }, isLive)) //end this.boominResultsObj.snapshot[siteKey].docs.forEach()
    } else { this.boominResultsLoadFinished(siteKey, isLive) } //end if (!this.boominResultsObj.snapshot[siteKey].empty)
  } //end boominResultsLoadAdd()

  private boominResultsLoadFinished(siteKey, isLive) {
    if (this.loading.isLoading) this.loading.dismiss()
    else this.boominResultsObj.liveLoaded[siteKey] = true
    if (this.authService.isDev) if (this.authService.showError) console.error(this.TAG, 'finished', isLive, siteKey, this.sitesObj[siteKey].name, (this.boominResultsObj[`${isLive ? 'live' : 'history'}`].summary[siteKey] ? Object.keys(this.boominResultsObj[`${isLive ? 'live' : 'history'}`].summary[siteKey]).length : 0), this.boominResultsObj)
    this.observerObj.boominResults.next({ event: 'finished', siteKey: siteKey })
    const [size, sizeSite] = [new TextEncoder().encode(JSON.stringify(this.boominResultsObj)).length, new TextEncoder().encode(JSON.stringify(this.boominResultsObj)).length]
    const [kiloBytes, kiloBytesSite] = [size / 1024, sizeSite / 1024]
    const [megaBytes, megaBytesSite] = [kiloBytes / 1024, kiloBytesSite / 1024]
    if (this.authService.showError) console.error(this.TAG, 'Boomin Results Size: ', kiloBytes + 'KB', megaBytes + 'MB  Site Size: ', kiloBytesSite + 'KB', megaBytesSite + 'MB')

    this.boominResultsObj.loadingData[siteKey] = false
    this.boominResultsObj.retryCount[siteKey] = 0
  } //end boominResultsLoadFinished()

  private async boominResultsLoadNext(lastDate, siteKey, isLive, time: { start, end }) {
    if (this.loading.isLoading) this.loading.dismiss()
    if (this.authService.isDev) console.log(this.TAG, 'boominResultsLoadNext', isLive, this.sitesObj[siteKey].name, (this.boominResultsObj[`${isLive ? 'live' : 'history'}`].summary[siteKey] ? Object.keys(this.boominResultsObj[`${isLive ? 'live' : 'history'}`].summary[siteKey]).length : 0))
    try {
      const snapshot = await this.authService.firestore.collection('boomin-results').orderBy('date', 'asc').where('siteKey', '==', siteKey).where('processed', '==', true).where('date', '>=', time.start.getTime()).where('date', '<=', time.end.getTime()).startAfter(lastDate).limit(1000).get()
      this.boominResultsLoadAdd(snapshot, siteKey, isLive, time)
    } catch (error) {
      if (this.authService.showError) console.error(this.TAG, error)
      const count = this.boominResultsObj.retryCount[siteKey] >= 1 ? ++this.boominResultsObj.retryCount[siteKey] : 1
      if (count < this.retryMax) setTimeout(() => { this.boominResultsLoadNext(lastDate, siteKey, isLive, time) }, 1000)
      else if (!isLive) alert(`Error loading data please try again! `)
    } //end try
  } //end boominResultsLoadNext()

  private chatAdd(chat) {
    if (this.devicesSitesObj[chat.imei]?.siteKey === chat.siteKey || this.devicesSitesObj[chat.imei]?.supervisor?.enabled) {
      const device = this.devicesSitesObj[chat.imei]
      chat.isMe = chat?.userID === this.authService.user.userID
      if (!chat.isMe && !chat.userMsg && (chat.date > device.chatLastSeen || !device.chatLastSeen)) device.chatLastSeen = chat.date
      if (chat.msg !== undefined) {
        this.chatsObj[chat.key] = chat
        if (chat.date > device.latestMsgDate) {
          device.latestMsg = `${chat.thumb ? 'IMAGE: ' : ''}${chat.msg}`
          device.latestMsgDate = chat.date
          if (chat.date > this.dateService.startup && !chat.isMe) {
            device.newMsgCount++, this.newMSG++
            this.playNotificationSound()
          } //end  if (!load && !chat.isMe)
        } //end if (chat.date > device.latestMsgDate)
        this.observerObj.chats.next({ event: 'added' })
      } //end if (chat.msg === undefined)
    } //end if (this.devicesSitesObj[chat.imei])
  } //end chatAdd()

  private chatsHandler() {
    return new Promise<boolean>(async (resolve) => {
      this.noChats ? this.globalChatEnabled = false : this.globalChatEnabled = true
      this.newMSG = 0
      let runOnce = true
      await this.authService.firestore.collection('chats').where('companyDB', '==', this.authService.user.companyDB).orderBy('date', 'desc').limit(1000).onSnapshot(chatsSnapshot => {
        this.zone.run(() => {
          chatsSnapshot.docChanges().forEach(chatData => {
            if (chatData.type === 'added') this.chatAdd({ ...chatData.doc.data() as any, key: chatData.doc.id })
          }) //chatsSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.loaded.chats = true
            this.observerObj.chats.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'chatsHandler()', /*  this.chatsObj,   */Object.keys(this.chatsObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //await this.auth.firestore.collection('chats')
    }) //end new Promise()
  } //end chatsHandler()

  public chatLoadMore(imei, end) {
    console.log(this.TAG, 'chatLoadMore()', imei, end)
    return new Promise<boolean>(async (resolve) => {
      await this.authService.firestore.collection('chats').where('companyDB', '==', this.authService.user.companyDB).where('imei', '==', imei).orderBy('date', 'desc').where('date', '<', end)
        .limit(300).get().then(chatsSnapshot => {
          chatsSnapshot.forEach(chatData => {
            this.zone.run(() => {
              this.chatAdd({ ...chatData.data() as any, key: chatData.id })
            }) //end this.zone.run()
          })
          this.observerObj.chats.next({ event: 'loaded' })
          resolve(true)
        }) //end this.auth.firestore.collection('chats')
    })
  } //end chatLoadMore()

  private checkChkInc(obj, item, event, type, load?) {
    if (this.sitesObj[item.siteKey]) {
      this[obj][item.key] = item, this.incidentsChkNotificationsObj[item.key] = item
      if (!load && event === 'added') this.playNotificationSound()
      this.observerObj.alerts.next({ event: event, key: item.key })
    } //end if (this.sitesObj[item.siteKey])
  } //end checkChkInc()

  private checklistsDoneHandler() {
    return new Promise<boolean>(async (resolve) => {
      let count = 0
      let runOnce = true
      await this.authService.firestore.collection('checklists-done').where('companyDB', '==', this.authService.user.companyDB).where('date', '>=', this.dateService.fsYesterday).orderBy('date', 'desc').limit(1000)
        .onSnapshot(checklistsSnapshot => {
          this.zone.run(() => {
            checklistsSnapshot.docChanges().forEach(checklistsDoneData => {
              if (runOnce) ++count
              const checklistDone = { ...checklistsDoneData.doc.data() as any, key: checklistsDoneData.doc.id, type: 'checklist' }
              if (checklistsDoneData.type === 'added' || checklistsDoneData.type === 'modified') this.checkChkInc('checklistsDoneObj', checklistDone, 'modified', 'checklists-done')
              else if (checklistsDoneData.type === 'removed') {
                if (this.checklistsDoneObj[checklistDone.key]) delete this.checklistsDoneObj[checklistDone.key]
                if (this.incidentsChkNotificationsObj[checklistDone.key]) delete this.incidentsChkNotificationsObj[checklistDone.key]
              } //end else if (checklistsDoneData.type === 'removed')
              this.observerObj.alerts.next({ event: checklistsDoneData.type, key: checklistsDoneData.doc.id })
            }) //checklistsSnapshot.docChanges().forEach()
            if (runOnce) {
              runOnce = false
              this.observerObj.alerts.next({ event: 'loaded-checklists-done' })
              resolve(true)
              console.log(this.TAG, 'checklistDonesHandler()', count)
            } //end if (runOnce)
          }) //end this.zone.run()
        }) //end this.auth.firestore.collection('checklists-done')
    }) //end new Promise()
  } //end checklistsDoneHandler()

  private checklistsHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('checklists').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(checklistsSnapshot => {
        this.zone.run(() => {
          checklistsSnapshot.docChanges().forEach(checklistData => {
            const checklist = { ...checklistData.doc.data() as any, key: checklistData.doc.id }
            if (checklistData.type === 'added' || checklistData.type === 'modified') this.checklistsObj[checklist.key] = checklist
            else if (checklistData.type === 'removed' && this.checklistsObj[checklist.key]) delete this.checklistsObj[checklist.key]
            this.observerObj.checklists.next({ event: checklistData.type, key: checklist.key })
          }) //checklistsSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.loaded.checklists = true
            this.observerObj.checklists.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'checklistsHandler()', Object.keys(this.checklistsObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('checklists')
    }) //end new Promise()
  } //end checklistsHandler()

  private checkpointsHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('checkpoints').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(checkpointsSnapshot => {
        this.zone.run(() => {
          checkpointsSnapshot.docChanges().forEach(checkpointData => {
            const checkpoint = { ...checkpointData.doc.data() as any, key: checkpointData.doc.id }
            if (checkpointData.type === 'added' && (!this.authService.user.limitedSites || (this.authService.user.limitedSites && this.sitesObj[checkpoint?.siteKey]))) this.checkpointsObj[checkpoint.tagID] = checkpoint
            else if (checkpointData.type === 'modified' && this.checkpointsObj[checkpoint?.tagID]) this.checkpointsObj[checkpoint.tagID] = checkpoint
            else if (checkpointData.type === 'removed' && this.checkpointsObj[checkpoint.tagID]) delete this.checkpointsObj[checkpoint.tagID]
            this.observerObj.checkpoints.next({ event: checkpointData.type, key: checkpoint.key, tagID: checkpoint.tagID })
          }) //checkpointsSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.loaded.checkpoints = true
            this.observerObj.checkpoints.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'checkpointsHandler()', Object.keys(this.checkpointsObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('checkpoints')
    }) //end new Promise()
  } //end checkpointsHandler()

  private clockingAdd(clocking, load?) {
    if (this.sitesObj[clocking.siteKey]) {
      const site = this.sitesObj[clocking.siteKey]
      if (site.subsites) {
        for (let subsiteKey in site.subsites) {
          if (site.subsites[subsiteKey].siteID == clocking.siteID) {
            clocking.subsiteName = site.subsites[subsiteKey].name
            this.clockings.push(clocking)
          }
        } //end for (let key in site.subsites)
      } else { this.clockings.push(clocking) }
      if (!load) this.playNotificationSound()
      this.observerObj.checkins.next({ event: 'added' })
    } //end if (this.sitesObj[clocking.siteKey])
  } //end clockingAdd()

  private clockingsHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      if (this.clockingsRef) this.clockingsRef()
      this.clockingsRef = await this.authService.firestore.collection('check-in').where('companyDB', '==', this.authService.user.companyDB).where('date', '>', this.dateService.fsToday).where('date', '<', this.dateService.fsTomorrow).orderBy('date', 'desc')
        .onSnapshot(clockingsSnapshot => {
          this.zone.run(() => {
            clockingsSnapshot.docChanges().forEach(clockingData => {
              if (clockingData.type === 'added') { this.clockingAdd({ ...clockingData.doc.data() as any, key: clockingData.doc.id }) }
            }) //clockingsSnapshot.docChanges().forEach()
            if (runOnce) {
              runOnce = false
              this.loaded.checkins=true
              this.observerObj.checkins.next({ event: 'loaded' })
              resolve(true)
              console.log(this.TAG, 'clockingsHandler()', this.clockings.length)
            } //end if (runOnce)
          }) //end this.zone.run()
        }) //end this.auth.firestore.collection('check-in')
    }) //end new Promise()
  } //end clockingsHandler()

  private companyHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.doc('companies/' + this.authService.user.companyDB).onSnapshot(companiesnapshot => {
        this.companyObj = companiesnapshot.data()
        if (runOnce) {
          runOnce = false
          resolve(true)
          console.log(this.TAG, 'companyHandler()')
        } //end if (runOnce)
      }) //end this.firestore.doc('companies/' + this.currentUser.companyDB)
    }) //end new Promise()
  } //end companyHandler()

  private contactsHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('contacts').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(contactsSnapshot => {
        this.zone.run(() => {
          contactsSnapshot.docChanges().forEach(contactData => {
            const contact = { ...contactData.doc.data() as any, key: contactData.doc.id }
            if (contactData.type === 'added' || contactData.type === 'modified') this.contactsObj[contact.key] = contact
            else if (contactData.type === 'removed' && this.contactsObj[contact.key]) delete this.contactsObj[contact.key]
            this.observerObj.contacts.next({ event: contactData.type, key: contact.key })
          }) //contactsSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.loaded.contacts = true
            this.observerObj.contacts.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'contactsHandler()', Object.keys(this.contactsObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('contacts')
    }) //end new Promise()
  } //end contactsHandler()

  private defaultReportTemplatesHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('settings/report-templates/defaults').onSnapshot(defaultReportsSnapshot => {
        this.zone.run(() => {
          defaultReportsSnapshot.docChanges().forEach((defaultReportData) => {
            const defaultReport = { ...defaultReportData.doc.data() as any, key: defaultReportData.doc.id }
            if (defaultReportData.type === 'added' || defaultReportData.type === 'modified') this.defaultReportsObj[defaultReport.key] = defaultReport
            else if (defaultReportData.type === 'removed' && this.defaultReportsObj[defaultReport.key]) delete this.defaultReportsObj[defaultReport.key]
            this.observerObj.defaultReports.next({ event: defaultReportData.type, key: defaultReport.key })
          }) //defaultReportsSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.observerObj.defaultReports.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'defaultReportTemplatesHandler()', Object.keys(this.defaultReportsObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('settings/report-templates/defaults')
    }) //end new Promise()
  } //end defaultReportTemplatesHandler()

  private async deviceGetBattery(imei) {
    await this.authService.firestore.collection('battery-status').where('imei', '==', imei).orderBy('date', 'desc').limit(1)
      .get().then(batteryStatusSnapshot => {
        batteryStatusSnapshot.forEach(batteryStatusData => {
          this.zone.run(() => {
            if (this.devicesObj?.[batteryStatusData.data()?.imei]) {
              const batteryStatus = batteryStatusData.data()
              this.devicesBatteryObj[imei] = batteryStatus
              this.devicesObj[batteryStatus.imei].battery = batteryStatus
              this.observerObj.deviceBattery.next({ event: 'modified', imei: imei })
            } //end if (this.devicesObj[batteryStatus.imei])
          }) //end this.zone.run()
        })
      }) //end this.auth.firestore.collection('battery-status')
  } //end deviceGetBattery()

  private async deviceGetLastSeen(imei) {
    await this.authService.firestore.collection('last-seen').where('imei', '==', imei).orderBy('date', 'desc').limit(1)
      .get().then(lastSeenSnapshot => {
        lastSeenSnapshot.forEach(lastSeenData => {
          this.zone.run(() => {
            if (this.devicesObj?.[lastSeenData.data()?.imei]) {
              const lastSeen = lastSeenData.data()
              this.devicesLastSeenObj[imei] = lastSeen.date
              this.devicesObj[lastSeen.imei].lastSeen = lastSeen.date
              this.observerObj.deviceLastSeen.next({ event: 'modified', imei: imei })
            } //end if (this.devicesObj[lastSeen.imei])
          }) //end this.zone.run()
        })
      }) //end this.auth.firestore.collection('last-seen')
  } //end deviceGetLastSeen()

  private devicesHandler() {
    const isObject = v => v && typeof v === 'object'
    const getDifference = (a, b) => {
      const keys = [...new Set([...Object.keys(a), ...Object.keys(b)])]
      return Object.assign({}, ...keys.map(k => ({ [k]: isObject(a[k]) && isObject(b[k]) ? getDifference(a[k], b[k]) : a[k] !== b[k] })))
    } //end getDifference()
    const getTrueProperties = (obj: any): any => {
      const trueProperties: any = {}
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === 'object') {
            const nestedProperties = getTrueProperties(obj[key])
            if (Object.keys(nestedProperties).length > 0) {
              trueProperties[key] = nestedProperties
            }
          } else if (obj[key] === true) {
            trueProperties[key] = true
          }
        }
      }
      return trueProperties
    } //end getTrueProperties()

    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      const operator = /* this.authService.isGlobal ? 'in' : */ '=='
      const query = /* this.authService.isGlobal ? [this.authService.user.companyDB, 'none'] :  */this.authService.user.companyDB
      await this.authService.firestore.collection('devices').where('companyDB', operator, query).onSnapshot(devicesSnapshot => {
        this.zone.run(() => {
          devicesSnapshot.docChanges().forEach(deviceData => {
            const device = { ...deviceData.doc.data() as any, key: deviceData.doc.id }
            if (deviceData.type === 'added' && (!this.authService.user.limitedSites || (this.authService.user.limitedSites && this.sitesObj[device?.siteKey]))) {
              if (device.companyDB === 'none') console.log(this.TAG, 'Device No Company', device)
              device.battery = { level: -1 }
              device.latestMsgDate = 0
              device.chatLastSeen = 0
              device.newMsgCount = 0
              device.lastSeen = 0
              this.deviceGetLastSeen(device.imei)
              this.deviceGetBattery(device.imei)
              this.devicesObj[device.imei] = device
              if ((!device.userID && device.siteKey) || device?.supervisor?.enabled) this.devicesSitesObj[device.imei] = device
              this.observerObj.devices.next({ event: deviceData.type, key: device.key, userID: device?.userID || undefined })
            } //end if (deviceData.type === 'added')

            else if (deviceData.type === 'modified') {
              const differences = getTrueProperties(getDifference({ ...this.devicesObj[device.imei] }, { ...this.devicesObj[device.imei], ...device }))
              // console.warn(this.TAG, 'devicesHandler() modified diff', deviceData.type, device.key, devicesSnapshot.size, differences)
              if (this.devicesObj[device.imei]) {
                if (this.devicesSitesObj[device.imei]?.latestMsg) device.latestMsg = this.devicesSitesObj[device.imei].latestMsg
                this.devicesObj[device.imei] = { ...this.devicesObj[device.imei], ...device }

                if (this.devicesSitesObj[device.imei]) {
                  if (this.sitesObj[device?.siteKey] || device?.supervisor?.enabled) {
                    this.devicesSitesObj[device.imei] = { ...this.devicesSitesObj[device.imei], ...device }
                    this.observerObj.devices.next({ event: deviceData.type, key: device.key, userID: device?.userID || undefined, changed: differences })
                  } else {
                    delete this.devicesSitesObj[device.imei]
                    // delete this.devicesObj[device.imei]
                    this.observerObj.devices.next({ event: 'removed', key: device.key, userID: device?.userID || undefined, changed: differences })
                  } //end if (this.sitesObj[device?.siteKey])
                } else { this.observerObj.devices.next({ event: deviceData.type, key: device.key, userID: device?.userID || undefined, changed: differences }) } //end if (this.devicesSitesObj[device.imei])
              } else {
                if (this.sitesObj[device?.siteKey]) {
                  this.devicesSitesObj[device.imei] = device, this.devicesObj[device.imei] = device
                  this.observerObj.devices.next({ event: deviceData.type, key: device.key, userID: device?.userID || undefined, changed: differences })
                } //end if (this.sitesObj[device?.siteKey])
              } //end if (this.devicesSitesObj[device.imei])


            } //end if (deviceData.type === 'modified')
            else if (deviceData.type === 'removed' && this.devicesObj[device?.imei]) {
              delete this.devicesObj[device.imei]
              if (this.devicesSitesObj[device.imei]) delete this.devicesSitesObj[device.imei]
              this.observerObj.devices.next({ event: deviceData.type, key: device.key, userID: device?.userID || undefined })
            } //end if (deviceData.type === 'removed')
            // console.log(this.TAG, 'devicesHandler() device', deviceData.type, device.key, devicesSnapshot.size,)
          }) //devicesSnapshot.docChanges().forEach()
          // this.observerObj.devices.next({ event: 'modified*' })
          if (runOnce) {
            runOnce = false
            console.log(this.TAG, 'devicesHandler()', Object.keys(this.devicesObj).length, Object.keys(this.devicesSitesObj).length)
            this.loaded.devices = true
            this.observerObj.devices.next({ event: 'loaded' })
            this.loadAllHandlers()
            resolve(true)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //await this.auth.firestore.collection('devices').where('companyDB', '==', this.auth.user.companyDB)
    }) //end new Promise()
  } //end devicesHandler()

  private devicesHandlerBattery() {
    return new Promise<boolean>(async (resolve) => {
      await this.authService.firestore.collection('battery-status').where('companyDB', '==', this.authService.user.companyDB).orderBy('date', 'desc').limit(1)
        .onSnapshot(batteryStatusSnapshot => {
          this.zone.run(() => {
            batteryStatusSnapshot.docChanges().forEach(batteryStatusData => {
              if (batteryStatusData.type === 'added') {
                const batteryStatus = { ...batteryStatusData.doc.data() as any, key: batteryStatusData.doc.id }
                this.devicesBatteryObj[batteryStatus.imei] = batteryStatus
                if (this.devicesObj[batteryStatus.imei]) this.devicesObj[batteryStatus.imei].battery = batteryStatus
                this.observerObj.deviceBattery.next({ event: 'modified', imei: batteryStatus.imei })
              } //if (batteryStatusData.type === 'added')
            }) //batteryStatusSnapshot.docChanges().forEach()
          }) //this.zone.run()
        }) //end this.auth.firestore.collection('battery-status')
      console.log(this.TAG, 'devicesHandlerBattery()')
      resolve(true)
    }) //end new Promise()
  } //end devicesHandlerBattery()

  private devicesHandlerLastSeen() {
    return new Promise<boolean>(async (resolve) => {
      await this.authService.firestore.collection('last-seen').where('companyDB', '==', this.authService.user.companyDB).orderBy('date', 'desc').limit(1)
        .onSnapshot(lastSeenSnapshot => {
          this.zone.run(() => {
            lastSeenSnapshot.docChanges().forEach(lastSeenData => {
              if (lastSeenData.type === 'added') {
                const lastSeen = { ...lastSeenData.doc.data() as any, key: lastSeenData.doc.id }
                this.devicesLastSeenObj[lastSeen.imei] = lastSeen.date
                if (this.devicesObj[lastSeen.imei]) this.devicesObj[lastSeen.imei].lastSeen = lastSeen.date
                this.observerObj.deviceLastSeen.next({ event: 'modified', imei: lastSeen.imei })
              } //if (lastSeenData.type === 'added')
            }) //lastSeenSnapshot.docChanges().forEach()
          }) //this.zone.run()
        }) //end this.auth.firestore.collection('last-seen')
      console.log(this.TAG, 'devicesHandlerLastSeen()')
      resolve(true)
    }) //end new Promise()
  } //end devicesHandlerLastSeen()

  private devicesHandlerRestart() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('device-restarts').where('companyDB', '==', this.authService.user.companyDB).where('date', '>=', this.dateService.fsYesterday).orderBy('date', 'desc').limit(3000).onSnapshot(deviceRestartSnapshot => {
        this.zone.run(() => {
          deviceRestartSnapshot.docChanges().forEach(deviceRestartData => {
            const deviceRestart = { ...deviceRestartData.doc.data() as any, key: deviceRestartData.doc.id }
            if (deviceRestartData.type === 'added' || deviceRestartData.type === 'modified') {
              if (runOnce && !this.devicesRestartsObj[deviceRestart.imei]) this.devicesRestartsObj[deviceRestart.imei] = deviceRestart
              if (!runOnce) this.devicesRestartsObj[deviceRestart.imei] = deviceRestart
              if (deviceRestart.restart) deviceRestart.restart.restarting ? this.devicesRestartsObj[deviceRestart.imei].spinner = true : this.devicesRestartsObj[deviceRestart.imei].spinner = false
              if (deviceRestart.reboot) deviceRestart.reboot.rebooting ? this.devicesRestartsObj[deviceRestart.imei].spinner = true : this.devicesRestartsObj[deviceRestart.imei].spinner = false
            }
          }) //deviceRestartSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            resolve(true)
            console.log(this.TAG, 'devicesHandlerRestart()', Object.keys(this.devicesRestartsObj).length)
          } //end if (runOnce)
        }) //this.zone.run()
      })// end this.auth.firestore.collection('device-restarts')
    }) //end new Promise()
  } //end devicesHandlerRestart()

  private devicesHandlerScreenshot() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('screenshots').where('companyDB', '==', this.authService.user.companyDB).where('date', '>=', this.dateService.fsYesterday).orderBy('date', 'desc').limit(3000).onSnapshot(screenshotSnapshot => {
        this.zone.run(() => {
          screenshotSnapshot.docChanges().forEach(screenshotData => {
            const deviceScreenshot = { ...screenshotData.doc.data() as any, key: screenshotData.doc.id }
            if (screenshotData.type === 'added' || screenshotData.type === 'modified') {
              if (runOnce && !this.deviceScreenshotsObj[deviceScreenshot.imei]) this.deviceScreenshotsObj[deviceScreenshot.imei] = deviceScreenshot
              if (!runOnce) this.deviceScreenshotsObj[deviceScreenshot.imei] = deviceScreenshot
              deviceScreenshot.screenshoting ? this.deviceScreenshotsObj[deviceScreenshot.imei].spinner = true : this.deviceScreenshotsObj[deviceScreenshot.imei].spinner = false
            } //if (screenshotData.type === 'added' || screenshotData.type === 'modified')
          }) //screenshotSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            resolve(true)
            console.log(this.TAG, 'devicesHandlerScreenshot()', Object.keys(this.deviceScreenshotsObj).length)
          } //end if (runOnce)
        }) //this.zone.run()
      }) //end this.auth.firestore.collection('screenshots')
    }) //end new Promise()
  } //end devicesHandlerScreenshot()

  private devicesHandlerUpdate() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('device-screenshots').where('companyDB', '==', this.authService.user.companyDB).where('date', '>=', this.dateService.fsYesterday).orderBy('date', 'desc').limit(3000).onSnapshot(deviceUpdateSnapshot => {
        this.zone.run(() => {
          deviceUpdateSnapshot.docChanges().forEach(deviceUpdateData => {
            const deviceUpdate = { ...deviceUpdateData.doc.data() as any, key: deviceUpdateData.doc.id }
            if (deviceUpdateData.type === 'added' || deviceUpdateData.type === 'modified') {
              if (runOnce && !this.deviceUpdatesObj[deviceUpdate.imei]) this.deviceUpdatesObj[deviceUpdate.imei] = deviceUpdate
              if (!runOnce) this.deviceUpdatesObj[deviceUpdate.imei] = deviceUpdate
              deviceUpdate.updating ? this.deviceUpdatesObj[deviceUpdate.imei].spinner = true : this.deviceUpdatesObj[deviceUpdate.imei].spinner = false
            } //if (deviceUpdateData.type === 'added' || deviceUpdateData.type === 'modified')
          }) //deviceUpdateSnapshot.docChanges().forEach()
          if (runOnce) {
            this.deviceUpdateAllowed = true
            runOnce = false
            resolve(true)
            console.log(this.TAG, 'devicesHandlerUpdate()', Object.keys(this.deviceUpdatesObj).length)
          } //end if (runOnce)
        }) //this.zone.run()
      }) //end this.auth.firestore.collection('device-updates')
    }) //end new Promise()
  } //end devicesHandlerUpdate()

  private guardsHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('guards').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(guardsSnapshot => {
        this.zone.run(() => {
          guardsSnapshot.docChanges().forEach(guardData => {
            const guard = { ...guardData.doc.data() as any, key: guardData.doc.id }
            if (guardData.type === 'added' || guardData.type === 'modified') this.guardsObj[guard.key] = guard
            else if (guardData.type === 'removed' && this.guardsObj[guard.key]) delete this.guardsObj[guard.key]
            this.observerObj.guards.next({ event: guardData.type, key: guard.key })
          }) //guardsSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.loaded.guards = true
            this.observerObj.guards.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'guardsHandler()', Object.keys(this.guardsObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('guards')
    }) //end new Promise()
  } //end guardsHandler()

  private incidentsDoneHandler() {
    return new Promise<boolean>(async (resolve) => {
      let count = 0
      let runOnce = true
      await this.authService.firestore.collection('incidents').where('companyDB', '==', this.authService.user.companyDB).where('date', '>=', this.dateService.fsYesterday).limit(3000).orderBy('date', 'desc')
        .onSnapshot(incidentsSnapshot => {
          this.zone.run(() => {
            incidentsSnapshot.docChanges().forEach(incidentData => {
              const incident = { ...incidentData.doc.data() as any, key: incidentData.doc.id, type: 'incident' }
              if (runOnce) ++count
              if (incidentData.type === 'added' || incidentData.type === 'modified') this.checkChkInc('incidentsObj', incident, incidentData.type, 'incidents')
              else if (incidentData.type === 'removed') {
                if (this.incidentsObj[incident.key]) delete this.incidentsObj[incident.key]
                if (this.incidentsChkNotificationsObj[incident.key]) delete this.incidentsChkNotificationsObj[incident.key]
                this.observerObj.alerts.next({ event: incidentData.type, key: incidentData.doc.id })
              } //end if (incidentData.type === 'removed')
            }) //incidentsSnapshot.docChanges().forEach()
            if (runOnce) {
              runOnce = false
              this.observerObj.alerts.next({ event: 'loaded-incidents-done' })
              resolve(true)
              console.log(this.TAG, 'incidentsDoneHandler()', count)
            } //end if (runOnce)
          }) //end this.zone.run()
        }) //end this.auth.firestore.collection('incidents')
    }) //end new Promise()
  } //end incidentsDoneHandler()

  private incidentsPoliciesHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('incidents-policies').onSnapshot(incidentsPoliciesSnapshot => {
        this.zone.run(() => {
          incidentsPoliciesSnapshot.docChanges().forEach(incidentPolicyData => {
            const incidentPolicy = { ...incidentPolicyData.doc.data() as any, key: incidentPolicyData.doc.id }
            if (incidentPolicyData.type === 'added' || incidentPolicyData.type === 'modified') {
              if (incidentPolicy.companyDB == this.authService.user.companyDB) this.incidentsPoliciesObj[incidentPolicy.key] = incidentPolicy
              this.incidentsAllPoliciesObj[incidentPolicy.key] = incidentPolicy
            } //end if (policyData.type === 'added')
            else if (incidentPolicyData.type === 'removed') {
              if (this.incidentsPoliciesObj[incidentPolicy.key]) delete this.incidentsPoliciesObj[incidentPolicy.key]
              if (this.incidentsAllPoliciesObj[incidentPolicy.key]) delete this.incidentsAllPoliciesObj[incidentPolicy.key]
            }
            this.observerObj.incidentsPolicies.next({ event: incidentPolicyData.type, key: incidentPolicy.key })
          }) //incidentsPoliciesSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.observerObj.incidentsPolicies.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'incidentsPoliciesHandler()', Object.keys(this.incidentsAllPoliciesObj).length, Object.keys(this.incidentsPoliciesObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('incidents-policies')
    }) //end new Promise()
  } //end incidentsPoliciesHandler()

  private incidentsTemplatesHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('incidents-templates').onSnapshot(incidentsTemplatesSnapshot => {
        this.zone.run(() => {
          incidentsTemplatesSnapshot.docChanges().forEach(incidentsTemplateData => {
            const incidentTemplate = { ...incidentsTemplateData.doc.data() as any, key: incidentsTemplateData.doc.id }
            if (incidentsTemplateData.type === 'added' || incidentsTemplateData.type === 'modified') this.incidentsTemplatesObj[incidentTemplate.key] = incidentTemplate
            else if (incidentsTemplateData.type === 'removed' && this.incidentsTemplatesObj[incidentTemplate.key]) delete this.incidentsTemplatesObj[incidentTemplate.key]
            this.observerObj.incidentsTemplates.next({ event: incidentsTemplateData.type, key: incidentTemplate.key })
          }) //incidentsTemplatesSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.observerObj.incidentsTemplates.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'incidentsTemplatesHandler()', Object.keys(this.incidentsTemplatesObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('incidents-templates')
    }) //end new Promise()
  } //end incidentsTemplatesHandler()

  private patrolsHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('patrols').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(patrolsSnapshot => {
        this.zone.run(() => {
          patrolsSnapshot.docChanges().forEach(patrolData => {
            const patrol = { ...patrolData.doc.data() as any, key: patrolData.doc.id }
            if (patrolData.type === 'added' || patrolData.type === 'modified') this.patrolsObj[patrol.key] = patrol
            else if (patrolData.type === 'removed' && this.patrolsObj[patrol.key]) delete this.patrolsObj[patrol.key]
            this.observerObj.patrols.next({ event: patrolData.type, key: patrol.key })
          }) //patrolsSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.loaded.patrols = true
            this.observerObj.patrols.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'patrolsHandler()', Object.keys(this.patrolsObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('patrols')
    }) //end new Promise()
  } //end patrolsHandler()

  private playNotificationSound(alertType: string = '') {
    if (!this.platformReady) return

    if (alertType === 'pa') {
      if (!this.panicAlarm) {
        this.panicAlarm = true
        this.panic.load()
        this.panic.play()
      } //end if (!this.panicAlarm)
    } else {
      this.notification.play()
    } //end if (alert.alertType === 'pa')
  } //end playNotificationSound()

  private reportTemplatesHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('report-templates').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(reportTemplatesSnapshot => {
        this.zone.run(() => {
          reportTemplatesSnapshot.docChanges().forEach(reportTemplateData => {
            const reportTemplate = { ...reportTemplateData.doc.data() as any, key: reportTemplateData.doc.id }
            if (reportTemplateData.type === 'added' || reportTemplateData.type === 'modified') this.reportTemplatesObj[reportTemplate.key] = reportTemplate
            else if (reportTemplateData.type === 'removed' && this.reportTemplatesObj[reportTemplate.key]) delete this.reportTemplatesObj[reportTemplate.key]
            this.observerObj.reportTemplates.next({ event: reportTemplateData.type, key: reportTemplate.key })
          }) //reportTemplatesSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.observerObj.reportTemplates.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'reportTemplatesHandler()', Object.keys(this.reportTemplatesObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //.onSnapshot(reportTemplatesSnapshot)
    }) //end new Promise()
  } //end reportTemplatesHandler()

  private permissionsHandler() {
    return new Promise<boolean>(async (resolve) => {
      await this.authService.firestore.doc('settings/permissions').onSnapshot(settingsSnapshot => {
        this.zone.run(() => {
          if (settingsSnapshot.exists) this.permissions = settingsSnapshot.data()
          resolve(true)
        }) //end this.zone.run()
      }) //end this.auth.firestore.doc('settings/roles')
    }) //end new Promise()
  } //end permissionsHandler()

  private rosterHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      if (this.rosterRef) this.rosterRef()
      this.rosterRef = await this.authService.firestore.collection('er-roster').where('companyDB', '==', this.authService.user.companyDB).where('date', '>=', this.dateService.fsToday).where('date', '<', this.dateService.fsTomorrow).orderBy('date', 'desc')
        .onSnapshot(rosterSnapshot => {
          this.zone.run(() => {
            this.rostersObj = {}
            rosterSnapshot.docChanges().forEach(rosterData => {
              const roster = { ...rosterData.doc.data() as any, key: rosterData.doc.id }
              if (rosterData.type === 'added' || rosterData.type === 'modified') this.rostersObj[roster.key] = roster
            }) //rosterSnapshot.docChanges().forEach()
            if (runOnce) {
              runOnce = false
              this.observerObj.roster.next({ event: 'loaded' })
              resolve(true)
              console.log(this.TAG, 'rosterHandler()', Object.keys(this.rostersObj).length)
            } //end if (runOnce)
          }) //this.zone.run()
        }) //end this.rosterRef
    }) //end new Promise()
  } //end rosterHandler()

  private settingsChatHandler() {
    return new Promise<boolean>(async (resolve) => {
      await this.authService.firestore.doc(`settings/profile-settings/${this.authService.user.companyDB}/chat`).onSnapshot(settingsSnapshot => {
        this.zone.run(() => {
          if (settingsSnapshot.exists) {
            const settings = settingsSnapshot.data()
            settings.noChats ? this.globalChatEnabled = false : this.globalChatEnabled = true
            settings.noChats ? this.noChats = true : this.noChats = false
          } else { this.globalChatEnabled = true }
          console.log(this.TAG, 'settingsChatHandler()', this.globalChatEnabled, this.noChats)
          resolve(true)
        }) //end this.zone.run()
      }) //end this.auth.firestore.doc('settings/defaultSettings'))
    }) //end new Promise()
  } //end settingsChatHandler()

  private settingsInventoryHandler() {
    return new Promise<boolean>(async (resolve) => {
      await this.authService.firestore.doc(`settings/profile-settings/${this.authService.user.companyDB}/inventory`).onSnapshot(settingsSnapshot => {
        this.zone.run(() => {
          if (settingsSnapshot.exists) {
            const settings = settingsSnapshot.data()
            this.inventoryEnabled = settings?.enabled || false
          } else { this.inventoryEnabled = false }
          console.log(this.TAG, 'settingsInventoryHandler()', this.inventoryEnabled)
          resolve(true)
        }) //end this.zone.run()
      }) //end this.auth.firestore.doc('settings/defaultSettings'))
    }) //end new Promise()
  } //end settingsInventoryHandler()

  private settingsContactsHandler() {
    return new Promise<boolean>(async (resolve) => {
      await this.authService.firestore.doc(`settings/profile-settings/${this.authService.user.companyDB}/contact`).onSnapshot(settingsSnapshot => {
        this.zone.run(() => {
          if (settingsSnapshot.exists) {
            const settings = settingsSnapshot.data()
            settings.active ? this.contactsActive = true : this.contactsActive = false
          } else { this.contactsActive = false }
          console.log(this.TAG, 'settingsContactsHandler()', this.contactsActive)
          resolve(true)
        }) //end this.zone.run()
      }) //end this.auth.firestore.doc('settings/defaultSettings'))
    }) //end new Promise()
  } //end settingsContactsHandler()

  private settingsHasNotScannedPatrolAlertHandler() {
    return new Promise<boolean>(async (resolve) => {
      await this.authService.firestore.doc(`settings/profile-settings/${this.authService.user.companyDB}/hasNotScannedPatrolAlert`).onSnapshot(settingsSnapshot => {
        this.zone.run(() => {
          if (settingsSnapshot.exists) {
            const settings = settingsSnapshot.data()
            this.hasNotScannedPatrolAlert = settings.value ? settings.value : ''
          } else { this.hasNotScannedPatrolAlert = '' }
          console.log(this.TAG, 'settingsHasNotScannedPatrolAlertHandler()', this.hasNotScannedPatrolAlert)
          resolve(true)
        }) //end this.zone.run()
      }) //end this.auth.firestore.doc('settings/defaultSettings'))
    }) //end new Promise()
  } //end settingsHasNotScannedPatrolAlertHandler()

  private settingsPTTHandler() {
    return new Promise<boolean>(async (resolve) => {
      await this.authService.firestore.doc(`settings/profile-settings/${this.authService.user.companyDB}/ptt`).onSnapshot(settingsSnapshot => {
        this.zone.run(() => {
          if (settingsSnapshot.exists) {
            const settings = settingsSnapshot.data()
            settings.enabled ? this.pptEnabled = true : this.pptEnabled = false
            settings.siteMode ? this.pptSiteMode = true : this.pptSiteMode = false
          } else { this.pptEnabled = false }
          this.observerObj.ptt.next({ event: 'loaded' })
          console.log(this.TAG, 'settingsPTTHandler()', this.pptEnabled)
          resolve(true)
        }) //end this.zone.run()
      }) //end this.auth.firestore.doc('settings/defaultSettings'))
    }) //end new Promise()
  } //end settingsPTTHandler()

  private settingsScanToPatrolHandler() {
    return new Promise<boolean>(async (resolve) => {
      await this.authService.firestore.doc(`settings/profile-settings/${this.authService.user.companyDB}/scanToPatrol`).onSnapshot(settingsSnapshot => {
        this.zone.run(() => {
          if (settingsSnapshot.exists) this.scanToPatrol = settingsSnapshot.data()?.scanToPatrol || false
          else this.scanToPatrol = false
          console.log(this.TAG, 'settingsScanToPatrolHandler()', this.scanToPatrol)
          resolve(true)
        }) //end this.zone.run()
      }) //end this.auth.firestore.doc('settings/defaultSettings'))
    }) //end new Promise()
  } //end settingsScanToPatrolHandler()

  private settingsShiftHandler() {
    return new Promise<boolean>(async (resolve) => {
      await this.authService.firestore.doc(`settings/profile-settings/${this.authService.user.companyDB}/shift`).onSnapshot(settingsSnapshot => {
        this.zone.run(() => {
          if (settingsSnapshot.exists) this.shift = settingsSnapshot.data()
          else this.shift = { day: '06:00', night: '18:00' }
          console.log(this.TAG, 'settingsShiftHandler()', this.shift)
          resolve(true)
        }) //end this.zone.run()
      }) //end this.auth.firestore.doc('settings/defaultSettings'))
    }) //end new Promise()
  } //end settingsShiftHandler()

  private sitesHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('sites').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(sitesSnapshot => {
        this.zone.run(() => {
          sitesSnapshot.docChanges().forEach(siteData => {
            const site = { ...siteData.doc.data() as any, key: siteData.doc.id }
            if ((siteData.type === 'added' || siteData.type === 'modified') && (!this.authService.user.limitedSites || (this.authService.user.limitedSites && this.authService.user.sites[site.key]))) this.sitesObj[site.key] = site, this.boominResultsInitialise(site.key, true, true)
            else if ((siteData.type === 'removed' || (siteData.type === 'modified' && (this.authService.user.limitedSites && !this.authService.user.sites[site.key]))) && this.sitesObj[site.key]) delete this.sitesObj[site.key], this.boominResultsInitialise(site.key, true, true)
            this.observerObj.sites.next({ event: siteData.type, key: site.key })
          }) //sitesSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.loaded.sites = true
            this.observerObj.sites.next({ event: 'loaded' })
            this.devicesHandler()
            console.log(this.TAG, 'sitesHandler()', Object.keys(this.sitesObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('sites')
    }) //end new Promise()
  } //end sitesHandler()

  private userEmailBlockedHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('email-blocking').where('blocked', '==', true).onSnapshot(userEmailsBlockedSnapshot => {
        this.zone.run(() => {
          userEmailsBlockedSnapshot.docChanges().forEach((userEmailBlockedData) => {
            const userEmailBlocked = userEmailBlockedData.doc.data().email.toLowerCase()
            if (userEmailBlockedData.type === 'added' || userEmailBlockedData.type === 'modified') {
              this.userEmailsBlockedObj[userEmailBlocked] = userEmailBlocked
              this.observerObj.userUserEmailBlocking.next({ event: 'modified', key: userEmailBlocked })
            } //end if (userData.type === 'modified')
            else if (userEmailBlockedData.type === 'removed' && this.userEmailsBlockedObj[userEmailBlocked]) delete this.userEmailsBlockedObj[userEmailBlocked]
            this.observerObj.userUserEmailBlocking.next({ event: userEmailBlockedData.type, key: userEmailBlocked })
          }) //usersSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.observerObj.userUserEmailBlocking.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'userEmailBlockedsHandler()', Object.keys(this.userEmailsBlockedObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('email-blocking')
    }) //end new Promise()
  } //end userEmailBlockedHandler()

  private userPoliciesHandler() {
    return new Promise<boolean>(async (resolve) => {
      let runOnce = true
      await this.authService.firestore.collection('user-policies').onSnapshot(userPoliciesSnapshot => {
        this.zone.run(() => {
          userPoliciesSnapshot.docChanges().forEach(userPolicyData => {
            const userPolicy = { ...userPolicyData.doc.data() as any, key: userPolicyData.doc.id }
            if (userPolicyData.type === 'added' || userPolicyData.type === 'modified') this.userPoliciesObj[userPolicy.key] = userPolicy
            else if (userPolicyData.type === 'removed' && this.userPoliciesObj[userPolicy.key]) delete this.userPoliciesObj[userPolicy.key]
            this.observerObj.userPolicies.next({ event: userPolicyData.type, key: userPolicy.key })
          }) //userPoliciesSnapshot.docChanges().forEach()
          if (runOnce) {
            runOnce = false
            this.loaded.userPolicies = true
            this.observerObj.userPolicies.next({ event: 'loaded' })
            resolve(true)
            console.log(this.TAG, 'userPoliciesHandler()', Object.keys(this.userPoliciesObj).length)
          } //end if (runOnce)
        }) //end this.zone.run()
      }) //end this.auth.firestore.collection('user-policies')
    }) //end new Promise()
  } //end userPoliciesHandler()

  private usersHandler() {
    // const demoUSer: User = { companyDB: 'amatrack'}
    // demoUSer.ipo = 'sample'
    return new Promise<boolean>((resolve) => {
      if (this.authService.user.limitedSites || !this.isAdminUser) {
        this.usersObj[this.authService.user.userID] = this.authService.user
        resolve(true)
      } else {
        const emailIgnore = this.authService.user.companyDB + '@amatrack.net'
        let runOnce = { users: true, userCompanies: true }
        const promiseUserCompanies = new Promise<boolean>(async (resolveUserCompanies) => {
          await this.authService.firestore.collection('user-companies').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(usersSnapshot => {
            this.zone.run(() => {
              usersSnapshot.docChanges().forEach(async (userData) => {
                const user = userData.doc.data()
                user.companyUserID = userData.doc.id
                user.name = `${user.fname} ${user.lname}`
                if (this.authService.isDev) {
                  if (userData.type === 'added' || userData.type === 'modified') {
                    if (userData.doc.data().name !== user.name) {
                      console.warn(this.TAG, 'name mismatch company', user.fname, '|', user.lname, '|', user.name, '|', userData.doc.data().name)
                      await this.authService.firestore.doc('user-companies/' + user.companyUserID).update({ date: Date.now(), dateModified: Date.now(), name: user.name }).then(() => {
                        if (this.authService.showError) console.error(this.TAG, 'name mismatch company fixed', user.fname, '|', user.lname, '|', userData.doc.data().name, '|', user.name)
                      })
                    }
                    if (userData.doc.data().companyUserID !== user.companyUserID) {
                      console.warn(this.TAG, 'name mismatch companyUserID', user.name, '|', userData.doc.data().companyUserID)
                      await this.authService.firestore.doc('user-companies/' + user.companyUserID).update({ date: Date.now(), dateModified: Date.now(), companyUserID: user.companyUserID }).then(() => {
                        if (this.authService.showError) console.error(this.TAG, 'companyUserID mismatch company fixed', user.name, '|', userData.doc.data().companyUserID, '|', user.companyUserID)
                      })
                    }
                  }
                }
                if (user.email != emailIgnore) {
                  if (userData.type === 'added' || userData.type === 'modified') { this.usersObj[user.userID] = user }
                  else if (userData.type === 'removed' && (this.usersObj[user.userID])) delete this.usersObj[user.userID]
                  this.observerObj.users.next({ event: userData.type, key: user.userID })
                } //end if (user.email != email)
              }) //usersSnapshot.docChanges().forEach()
              if (runOnce.userCompanies) {
                runOnce.userCompanies = false
                setTimeout(() => resolveUserCompanies(true), 1000)
              } //end if (runOnce.userCompanies)
            }) //end this.zone.run()
          }) //end this.auth.firestore.collection('user-companies')
        }) //end promiseUserCompanies

        const promiseUsers = new Promise<boolean>(async (resolveUsers) => {
          await this.authService.firestore.collection('users').where('companyDB', '==', this.authService.user.companyDB).onSnapshot(usersSnapshot => {
            this.zone.run(() => {
              usersSnapshot.docChanges().forEach(async (userData) => {
                const user = userData.doc.data()
                user.name = `${user.fname} ${user.lname}`
                if (this.authService.isDev) {
                  if (userData.type === 'added' || userData.type === 'modified') {
                    if (userData.doc.data().name !== user.name) {
                      console.warn(this.TAG, 'name mismatch', user.fname, '|', user.lname, '|', user.name, '|', userData.doc.data().name)
                      await this.authService.firestore.doc('users/' + user.userID).update({ date: Date.now(), dateModified: Date.now(), name: user.name }).then(() => {
                        if (this.authService.showError) console.error(this.TAG, 'name mismatch fixed', user.fname, '|', user.lname, '|', userData.doc.data().name, '|', user.name)
                      })
                    }
                  }
                }
                if (user.email != emailIgnore) {
                  if (userData.type === 'added' || userData.type === 'modified') { this.usersObj[user.userID] = user }
                  else if (userData.type === 'removed' && (this.usersObj[user.userID])) delete this.usersObj[user.userID]
                  this.observerObj.users.next({ event: userData.type, key: user.userID })
                } //end if (user.email != email)
              }) //usersSnapshot.docChanges().forEach()
              if (runOnce.users) {
                runOnce.users = false
                setTimeout(() => resolveUsers(true), 1000)
              } //end if (runOnce.users)
            }) //end this.zone.run()
          }) //end this.auth.firestore.collection('users')
        }) //end promiseUsers

        Promise.all([promiseUserCompanies, promiseUsers]).then(() => {
          this.loaded.users = true
          this.observerObj.users.next({ event: 'loaded' })
          resolve(true)
          console.log(this.TAG, 'usersHandler()', Object.keys(this.usersObj).length)
        })
      } //end if (this.auth.user.limitedSites)
    }) //end new Promise()
  } //end usersHandler()

  public metadataRemove(obj) {
    delete obj._metadata
  }

} //end DataService