import { Component } from '@angular/core'
import { NavParams, PopoverController } from '@ionic/angular'

@Component({
  selector: 'app-popover-sort',
  templateUrl: './popover-sort.component.html',
  styleUrls: ['./popover-sort.component.scss'],
})
export class PopoverSortComponent {
  public items: any[] = []

  constructor(
    private navParams: NavParams,
    private popoverController: PopoverController
  ) {
    this.items = this.navParams.data.items
  } //end ()

  itemSelected(item) {
    this.dismiss(item)
  }

  dismiss(data?: any) {
    this.popoverController.dismiss(data)
  }
}
