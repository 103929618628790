import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth/auth.service'
import { DataService } from 'src/app/services/data/data.service'
import { DateService } from 'src/app/services/date/date.service'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { UtilityService } from 'src/app/services/utility/utility.service'

@Component({
  selector: 'app-boomin-page-detail',
  templateUrl: './boomin-page-detail.component.html',
  styleUrls: ['./boomin-page-detail.component.scss'],
})
export class BoominPageDetailComponent implements OnInit {
  private TAG = 'BoominPageDetailComponent|'
  public segment = 'configuration'
  public isNew: boolean = false

  public barcodeOther: boolean = false
  public config = { photo: false }
  public page
  public type
  public name
  public description
  public typeCategory
  public siteKey
  summaryFieldsCount = 0
  summaryFieldsMax = 4

  public manual

  public fieldExtraTypes = { 'No': 0, 'Optional': 1, 'Required': 2 }
  public types = ['Barcode', 'Disclaimer', 'Form', 'Inventory', 'List', 'Photo', 'Signature',]

  public typeCategories = {
    Barcode: [{ type: 'Licence', title: 'Drivers Licence' }, { type: 'ID', title: 'ID' }, { type: 'Other', title: 'Other' }, { type: 'Vehicle', title: 'Vehicle Disk' },],
    Disclaimer: [{ type: 'Disclaimer', title: 'Disclaimer' }, { type: 'Terms and Conditions', title: 'Terms and Conditions' },],
    Form: [{ type: 'Single', title: 'Fields Displayed Same Page' }, { type: 'Multi', title: 'Fields Displayed Seperate Pages' }],
    List: [{ type: 'Custom', title: 'Custom' }],
    Inventory: [{ type: 'Custom', title: 'Custom' }],
    Photo: [{ type: 'Standard', title: 'Standard' }],
    Signature: [{ type: 'Standard', title: 'Standard' }],
  }

  public fieldTypes = {
    date: { title: 'Date', categories: [{ type: 'date', title: 'Date' }, { type: 'time', title: 'Time' }] },
    // image: { title: 'Image', categories: [{ type: 'standard', title: 'Standard' },] },
    number: { title: 'Number', min: 0, max: null, categories: [{ type: 'standard', title: 'Standard' }, { type: 'counter', title: 'Counter' }] },
    select: { title: 'Select', categories: [{ type: 'standard', title: 'Standard' }, { type: 'yesno', title: 'Yes / No' }] },
    text: { title: 'Text', categories: [{ type: 'standard', title: 'Standard' },] },
    // scan: { title: 'Scan', categories: [{ type: 'barcode', title: 'Barcode' }, { type: 'nfc', title: 'NFC' }] },
    // image: { title: 'Image', categories: [{ type: 'standard', title: 'Standard' },] },
    // signature: { title: 'Signature', categories: [{ type: 'standard', title: 'Standard' },] },
  }

  fields: any[] = []
  identifier
  identifierSummary

  public fieldsList = {
    Barcode: {
      ID: [
        { autoField: 'image', auto: false, manual: true, report: true, type: 'image', title: 'ID Image', identifier: false, summary: false },
        { autoField: 'idNumber', auto: true, manual: true, report: true, type: 'text', title: 'ID Number', identifier: true, match: true, summary: true, identifierType: 'person' },
        { autoField: 'names', auto: true, manual: true, report: true, type: 'text', title: 'First Names', identifier: false, summary: false },
        { autoField: 'surname', auto: true, manual: true, report: true, type: 'text', title: 'Last Name', identifier: false, summary: false },
        { autoField: 'description', auto: true, report: true, type: 'text', title: 'Description', identifier: false, summary: false },
        { autoField: 'age', auto: true, report: true, type: 'text', title: 'Age', identifier: false, summary: false },
        { autoField: 'gender', auto: true, report: true, type: 'text', title: 'Gender', identifier: false, summary: false },
      ], Vehicle: [
        { autoField: 'image', auto: false, manual: true, report: true, type: 'image', title: 'Vehicle Image', identifier: false, summary: false },
        { autoField: 'licenceNo', auto: true, manual: true, report: true, type: 'text', title: 'Number Plate', identifier: true, match: true, summary: true, identifierType: 'vehicle', },
        { autoField: 'make', auto: true, manual: true, report: true, type: 'text', title: 'Make', identifier: false, summary: false },
        { autoField: 'model', auto: true, manual: true, report: true, type: 'text', title: 'Model', identifier: false, summary: false },
        { autoField: 'colour', auto: true, manual: true, report: true, type: 'text', title: 'Colour', identifier: false, summary: false },
        { autoField: 'vin', auto: true, manual: true, report: true, type: 'text', title: 'VIN', identifier: false, summary: false },
        { autoField: 'description', auto: true, manual: true, report: true, type: 'text', title: 'Description', identifier: false, summary: false },
        { autoField: 'dateOfExpiry', auto: true, manual: true, report: true, type: 'text', title: 'Date of Expiry', identifier: false, summary: false },
        { autoField: 'expireStatus', auto: true, report: true, type: 'text', title: 'Expire Status', identifier: false, summary: false, notice: true },
        { autoField: 'engineNo', auto: true, manual: true, report: true, type: 'text', title: 'Engine Number', identifier: false, summary: false },
        { autoField: 'licenceDiskNo', auto: true, manual: true, report: true, type: 'text', title: 'License Disk Number ', identifier: false, summary: false },
        { autoField: 'vehicleReg', auto: true, manual: true, report: true, type: 'text', title: 'Registration Number', identifier: false, summary: false },
        { autoField: 'gvm', auto: true, manual: true, report: true, type: 'number', title: 'GVM (kg)', identifier: false, summary: false, canEdit: true },
        { autoField: 'tare', auto: true, manual: true, report: true, type: 'number', title: 'Tare (kg)', identifier: false, summary: false, canEdit: true },
      ], Other: [
        { autoField: 'image', auto: false, manual: true, report: true, type: 'image', title: 'Barcode Image', identifier: false, summary: false },
        { autoField: 'barcode', auto: true, manual: true, report: true, type: 'text', title: 'Barcode', identifier: true, match: true, summary: true, identifierType: 'other' },
      ], Licence: [
        { autoField: 'img', auto: true, manual: true, report: true, type: 'image', title: 'Driver Image', identifier: false, summary: false },
        { autoField: 'idNumber', auto: true, manual: true, report: true, type: 'text', title: 'ID Number', identifier: true, match: true, summary: true, identifierType: 'person' },
        { autoField: 'licenceNumber', auto: true, manual: true, report: true, type: 'text', title: 'Licence Number', identifier: false, summary: false },
        { autoField: 'initials', auto: true, manual: true, report: true, type: 'text', title: 'First Names', identifier: false, summary: false },
        { autoField: 'surname', auto: true, manual: true, report: true, type: 'text', title: 'Last Name', identifier: false, summary: false },
        { autoField: 'age', auto: true, report: true, type: 'text', title: 'Age', identifier: false, summary: false },
        { autoField: 'gender', auto: true, report: true, type: 'text', title: 'Gender', identifier: false, summary: false },
        { autoField: 'dateOfBirth', auto: true, report: true, type: 'text', title: 'Date of Birth', identifier: false, summary: false },
        { autoField: 'dateValidFrom', auto: true, report: true, type: 'text', title: 'Valid From', identifier: false, summary: false },
        { autoField: 'dateValidTo', auto: true, report: true, type: 'text', title: 'Valid Until', identifier: false, summary: false },
        { autoField: 'expireStatus', auto: true, report: true, type: 'text', title: 'Expire Status', identifier: false, summary: false, notice: true },

        // { autoField: 'image', auto: true, manual: true, report: true, type: 'image', title: 'Driver Image', identifier: false, summary: false },
        // { autoField: 'idNumber', auto: true, manual: true, report: true, type: 'text', title: 'ID Number', identifier: true, summary: true, identifierType: 'person' },
        // { autoField: 'licenceNumber', auto: true, manual: true, report: true, type: 'text', title: 'Licence Number', identifier: false, summary: false },
        // { autoField: 'initials', auto: true, manual: true, report: true, type: 'text', title: 'First Names', identifier: false, summary: false },
        // { autoField: 'surname', auto: true, manual: true, report: true, type: 'text', title: 'Last Name', identifier: false, summary: false },
        // { autoField: 'description', auto: true, report: true, type: 'text', title: 'Description', identifier: false, summary: false },
        // { autoField: 'age', auto: true, report: true, type: 'text', title: 'Age', identifier: false, summary: false },
        // { autoField: 'gender', auto: true, report: true, type: 'text', title: 'Gender', identifier: false, summary: false },
        // { autoField: 'restrictions', auto: true, report: true, type: 'text', title: 'Restrictions', identifier: false, summary: false },
        // { autoField: 'dateOfBirth', auto: true, report: true, type: 'text', title: 'Date of Birth', identifier: false, summary: false },
        // { autoField: 'dateValidFrom', auto: true, report: true, type: 'text', title: 'Date Valid From', identifier: false, summary: false },
        // { autoField: 'dateValidUntil', auto: true, report: true, type: 'text', title: 'Date Valid Until', identifier: false, summary: false },
        // { autoField: 'licenceState', auto: true, report: true, type: 'text', title: 'Licence State', identifier: false, summary: false },
        // { autoField: 'licenceType', auto: true, report: true, type: 'text', title: 'Licence Type', identifier: false, summary: false },
      ],
    },
    Disclaimer: [{ autoField: '', auto: false, manual: true, report: true, type: 'textarea', title: '', content: '' },],
    Form: [{ autoField: '', auto: false, manual: true, report: true, type: '', title: '', summary: false },],
    Inventory: [
      { autoField: '', auto: true, manual: true, report: true, type: 'text', title: 'P/B' },
      { autoField: '', auto: true, manual: true, report: true, type: 'text', title: 'B/N' },
      { autoField: '', auto: true, manual: true, report: true, type: 'text', title: 'L/N' },
    ],
    List: [{ autoField: '', auto: false, manual: true, report: true, type: 'image', title: '', },],
    Photo: [{ autoField: '', auto: false, manual: true, report: true, type: 'image', title: '', },],
    Signature: [{ autoField: '', auto: false, manual: true, report: true, type: 'image', title: '', },],
  }

  constructor(
    private authService: AuthService,
    public dataService: DataService,
    public dateService: DateService,
    public loading: LoadingService,
    public modalCtrl: ModalController,
    public utilityService: UtilityService
  ) {} //end ()

  ngOnInit() {
    if (this.page != null) {
      this.isNew = false

      this.name = this.page.name
      this.type = this.page.type
      this.typeCategory = this.page.typeCategory
      this.description = this.page.description
      this.fields = this.page.fields
      this.siteKey = this.page.siteKey || ''
      this.manual = this.page.manual || { images: [{ title: '' }] }
      this.barcodeOther = (this.type === 'Barcode' && this.typeCategory === 'Other' && this.fields[1].identifierType != 'other')
      this.config.photo = this.page.config?.photo || false
    } else {
      this.page = {}
      this.name = ''
      this.type = ''
      this.typeCategory = ''
      this.description = ''
      this.isNew = true
      this.siteKey = ''
      this.manual = { images: [{ title: '' }] }
      this.barcodeOther = false
      this.config.photo = false
    }
  } //end ngOnInit()

  close() { this.modalCtrl.dismiss() } //end close()

  fieldAdd() {
    this.fields.push({ config: { flag: false, deny: false }, identifier: false, manual: true, options: null, report: true, title: '', type: '', })
  } //end fieldAdd()

  fieldMatchChange(field) { field.match = !field.match } //end fieldMatchChange)

  fieldManualChange(field) { field.manual = !field.manual } //end fieldManualChange)

  fieldSummaryChange(field) { field.summary = !field.summary } //end fieldSummaryChange()

  fieldReportChange(field) {
    field.report = !field.report
    if (field.report === false && field.manual != null) field.manual = false, field.summary = false
  } //end fieldReportChange()

  fieldsReorder(ev: any) {
    this.fields = ev.detail.complete(this.fields)
  } //end fieldsReorder()

  fieldOptionsReorder(ev: any, fieldIndex) {
    this.fields[fieldIndex].options = ev.detail.complete(this.fields[fieldIndex].options)
  } //end fieldOptionsReorder()

  fieldRemove(fieldIndex) { this.fields.splice(fieldIndex, 1) } //end fieldRemove()

  fieldTypeChange(ev, fieldIndex) {
    const fieldType = ev.detail.value
    delete this.fields[fieldIndex].default
    delete this.fields[fieldIndex].fieldType
    delete this.fields[fieldIndex].max
    delete this.fields[fieldIndex].min
    delete this.fields[fieldIndex].note
    delete this.fields[fieldIndex].options
    this.fields[fieldIndex].options = [...new Set()]
  } //end fieldTypeChange()

  fieldFieldCategoryChange(ev, fieldIndex) {
    this.fields[fieldIndex].type === 'select' ? ev.detail.value === 'yesno' ? ['Yes', 'No'].some(title => this.fieldOptionAdd(fieldIndex, title)) : ['', ''].some(title => this.fieldOptionAdd(fieldIndex, title)) : this.fields[fieldIndex].options = [...new Set()]
  } //end fieldFieldTypeChange()

  fieldOptionAdd(fieldIndex, title?) { this.fields[fieldIndex].options.push({ config: { flag: false, deny: false }, title: title ? title : '' }) } //end fieldOptionAdd()

  fieldOptionModify(ev, fieldIndex, optionIndex) { this.fields[fieldIndex].options[optionIndex].title = ev.detail.value } //end fieldOptionModify()

  fieldOptionRemove(fieldIndex, optionIndex) { this.fields[fieldIndex].options.splice(optionIndex, 1) } //end fieldOptionRemove()

  fieldOptionConfigModify(configName, fieldIndex, optionIndex) {
    if (!this.fields[fieldIndex].options[optionIndex].config) this.fields[fieldIndex].options[optionIndex].config = {}
    this.fields[fieldIndex].options[optionIndex].config[configName] = !this.fields[fieldIndex].options[optionIndex].config[configName]
  } //end fieldOptionConfigModify()

  fieldConfigModify(configName, fieldIndex) {
    if (!this.fields[fieldIndex].config) this.fields[fieldIndex].config = {}
    this.fields[fieldIndex].config[configName] = !this.fields[fieldIndex].config[configName]
  } //end fieldConfigModify()

  manualImagesAdd() {
    this.manual.images.push({ title: '' })
  } //end manualImagesAdd()

  manualImagesRemove(fieldIndex) {
    this.manual.images.splice(fieldIndex, 1)
  } //end manualImagesRemove()

  manualImagesReorder(ev: any) {
    this.manual.images = ev.detail.complete(this.manual.images)
  } //end manualImagesReorder()

  typeChange(ev?) {
    this.name = ''
    this.typeCategoryChange()
  } //end changeType()

  typeCategoryChange(ev?) { this.fields = this.type == 'Barcode' ? this.fieldsList[this.type][this.typeCategory] : this.type != 'Form' ? this.fieldsList[this.type] : this.fields } //end typeCategoryChange()

  validate(): boolean {
    if (this.type === 'Disclaimer') return this.validateSettings()
    else if (this.type === 'Form') return this.validateSettings()
    else if (this.type === 'Inventory') return this.validateSettings()
    else if (this.type === 'List') return this.validateSettings()
    else if (this.type === 'Photo' || this.type === 'Signature') return this.validateSettings()
    else if (this.type === 'Barcode') return this.validateSettings()
  } //end validate()

  validateArray(array) {
    let isValid = true
    if (array) { array.forEach(element => { if (!element) isValid = false }) }
    return isValid
  } //end validateArray(array)

  validateSettings() {
    if (this.type) {
      if (this.name) {
        return true
      } else {
        this.utilityService.simpleAlert('Name Not Entered')
        return false
      } //end if (this.page.name)
    } else {
      this.utilityService.simpleAlert('Type Not Selected')
      return false
    } //end if (this.page.type)
  } //end validateSettings()

  async create(obj) {
    this.loading.present()
    await this.authService.firestore.collection(`boomin-pages`).add(obj).then(() => {
      console.warn(this.TAG, 'PAGE ADDED', { ...obj })
      this.authService.logEvent('boomin_page_add')
      this.loading.dismiss()
      this.modalCtrl.dismiss()
    }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //catch(err)
  } //end create()

  async edit(obj) {
    this.loading.present()
    await this.authService.firestore.doc(`boomin-pages/${this.page.key}`).set(obj, { merge: true }).then(async () => {
      await this.dataService.boominPageEventsModify(obj, this.page.key).then(() => {
        console.warn(this.TAG, 'PAGE MODIFIED', { ...obj })
        this.authService.logEvent('boomin_page_modify')
        this.loading.dismiss()
        this.modalCtrl.dismiss()
      }) //end this.dataService.boominPageEventsModify()
    }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //catch(err)
  } //end edit()

  async delete() {
    this.loading.present()
    await this.authService.firestore.collection('boomin-events').where('pagesKeys', 'array-contains', this.page.key).get().then(async (eventsSnapshot) => {
      if (!eventsSnapshot.empty) {
        this.loading.dismiss()
        this.utilityService.simpleAlert('Remove Page from Events')
      } else {
        await this.authService.firestore.doc(`boomin-pages/${this.page.key}`).set({ ...this.authService.defaultDoc(), deleted: true }, { merge: true }).then(async () => {
          await this.authService.firestore.doc(`boomin-pages/${this.page.key}`).delete().then(() => {
            this.authService.logEvent('boomin_page_remove')
            // this.utilityService.simpleAlert('removed')
            this.loading.dismiss()
            this.modalCtrl.dismiss()
          }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //await this.auth.firestore.doc(`boomin-pages/${this.page.key}`).delete().then()
        })
      } //end if (eventsSnapshot)
    }) // await this.auth.firestore.collection('boomin-events').where('pagesKeys', 'array-contains', pageKey).get().then()
  } //end delete()

  save(copy?) {
    console.log(this.TAG, 'save()', this.page)
    if (this.validate()) {
      // const t=''.trim().toLowerCase().replace(' ','_')
      const obj: any = this.authService.defaultDoc((this.isNew || copy))
      obj.name = this.name.trim()
      obj.type = this.type
      obj.typeCategory = this.typeCategory
      obj.description = this.description ? this.description.trim() : ''
      if (this.type === 'Barcode' && this.typeCategory === 'Other') this.fields[1].identifierType = true ? this.name.trim().toLowerCase().replace(' ', '_') : 'other'
      if (copy?.copy) obj.description = `${obj.description} Copy`.trim()
      else if (copy?.duplicate) obj.description = `${obj.description} Copied From ${this.dataService.sitesObj[this.siteKey].name}`.trim()
      obj.fields = this.fields
      obj.siteKey = this.siteKey
      obj.manual = this.manual
      obj.config = { ...this.config }
      this.isNew || copy ? this.create(obj) : this.edit(obj)
    } //end if (this.validateBarcode())
  } //end save()

} //end BoominPageDetailComponent
