import { Injectable, NgZone } from '@angular/core'
import { Observable } from 'rxjs/Observable'
import { AuthService } from '../auth/auth.service'

@Injectable({ providedIn: 'root' })
export class DateService {
  private TAG = 'DateService|'
  public antmediaDate: string
  public changes
  public observer
  public date
  public year
  public month
  public day
  public formattedTime
  public database
  public tomorrow
  public tomorrowDB
  public yesterday
  public yesterdayDB
  public fsToday
  public fsTomorrow
  public fsYesterday
  public dbDate
  public dbTimestamp
  public startup = Date.now()
  public loaded: boolean = false
  public now: number

  constructor(
    public authService: AuthService,
    private zone: NgZone
  ) {
    this.dbDate = () => { return Date.now() }
    this.dbTimestamp = () => { return this.authService.timestamp }
    this.changes = new Observable((observer) => { this.observer = observer }) //Observable(),
    this.changes.subscribe()
    if (this.authService.showError) console.error(this.TAG, '@@@ Date Service Init Complete @@@')
  } //end ()

  async initialize() {
    await this.authService.firestore.doc('settings/date').onSnapshot(dateSnapshot => {
      this.zone.run(() => {
        const data = dateSnapshot.data().today, date_unformatted = new Date(data)
        this.year = date_unformatted.getFullYear()
        this.month = date_unformatted.getMonth() + 1
        this.day = date_unformatted.getDate()
        if (this.month < 10) { this.month = '0' + this.month }
        if (this.day < 10) { this.day = '0' + this.day }
        this.antmediaDate = `${this.year}-${this.month}-${this.day}`
        this.date = `${this.year}-${this.month}-${this.day}`
        this.database = this.year + '/' + this.month + '/' + this.date

        const time = new Date(data), hours = time.getHours(), minutes = time.getMinutes(), seconds = time.getSeconds()
        // Will display time in 10:30:23 format
        this.formattedTime = hours + ':' + minutes + ':' + seconds

        let tMonth, tDay
        const tomorrow = new Date(data)
        tomorrow.setDate(tomorrow.getDate() + 1)
        const tYear = tomorrow.getFullYear()
        tMonth = tomorrow.getMonth() + 1
        tDay = tomorrow.getDate()
        if (tMonth < 10) { tMonth = '0' + tMonth }
        if (tDay < 10) { tDay = '0' + tDay }
        this.tomorrow = tYear + '-' + tMonth + '-' + tDay
        this.tomorrowDB = tYear + '/' + tMonth + '/' + this.tomorrow

        let yMonth
        let yDay
        const yesterday = new Date(this.date)
        yesterday.setDate(yesterday.getDate() - 1)
        const yYear = yesterday.getFullYear()
        yMonth = yesterday.getMonth() + 1
        yDay = yesterday.getDate()
        if (yMonth < 10) { yMonth = '0' + yMonth }
        if (yDay < 10) { yDay = '0' + yDay }
        this.yesterday = yYear + '-' + yMonth + '-' + yDay
        this.yesterdayDB = yYear + '/' + yMonth + '/' + this.yesterday

        this.getFireStoreDates()
        if (this.authService.showError) console.error(this.TAG, '@@@ Date Service Init Complete @@@', this.yesterday, this.date, this.tomorrow, this.formattedTime)
        this.observer.next({ event: 'modified', type: 'date' })
        setInterval(() => this.now = new Date().getTime(), 1000)
        this.hourScheduler()
      }) //this.zone.run()
    }) //this.auth.firestore.doc('settings/date').onSnapshot()
  } //end initialize()

  hourScheduler() {
    const now = new Date()
    let next = new Date(now)
    if (now.getMinutes() != 0) next.setHours(now.getHours() + 1, 0, 0, 0)
    const timerHour = 1000 * 60 * 60
    const timerStart = next.getTime() - now.getTime()
    const at = new Date(Date.now() + timerStart).toLocaleString('en-ZA')
    if (this.authService.showError) console.error(this.TAG, `${this.TAG} Next Hour ${at}`)
    setTimeout(() => {
      this.nextHour()
      setInterval(() => this.nextHour(), timerHour)
    }, timerStart) //end setTimeout()
  } //end hourScheduler

  private nextHour() {
    const hour = `${new Date().getHours() < 10 || new Date().getHours() == 0 ? '0' : ''}${new Date().getHours()}:00`.trim()
    if (this.authService.showError) console.error(this.TAG, 'Current Hour', hour)
    this.observer.next({ event: 'modified', type: 'hour', data: hour })

  } //end nextHour()

  getFireStoreDates() {
    const today = new Date(this.date)
    today.setHours(0, 0, 0, 0)
    this.fsToday = today.getTime() - 7200000

    const yesterday = new Date(this.yesterday)
    yesterday.setHours(0, 0, 0, 0)
    this.fsYesterday = yesterday.getTime()

    const tomorrow = new Date(this.tomorrow)
    tomorrow.setHours(0, 0, 0, 1)
    this.fsTomorrow = tomorrow.getTime()
    this.loaded = true
  } //end getFireStoreDates()
} //end DateService