import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth/auth.service'
import { DataService } from 'src/app/services/data/data.service'
import { LoadingService } from 'src/app/services/loading/loading.service'

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.page.html',
  styleUrls: ['./contact-detail.page.scss'],
})
export class ContactDetailPage implements OnInit {
  private TAG = 'ContactDetailPage|'
  public isNew: boolean = false
  public contact: any = {}
  public name: string
  public perm: any = {}
  public phoneNumber: string

  constructor(
    private authService: AuthService,
    public dataService: DataService,
    public loading: LoadingService,
    public modalCtrl: ModalController,
  ) {
    this.perm = this.authService.user.permissions.perm5.a8
  } //end ()

  ngOnInit() {
    console.log(this.TAG, 'contact Detail ', this.contact)
    if (this.contact != null) {
      this.isNew = false
      this.name = this.contact.name ? this.contact.name : ''
      this.phoneNumber = this.contact.phoneNumber ? this.contact.phoneNumber : ''
    } else {
      this.contact = {}
      this.isNew = true
      this.name = ''
      this.phoneNumber = ''
    }
  } //end ngOnInit()

  save() {
    const contact = { name: this.name, phoneNumber: this.phoneNumber, }
    this.isNew ? this.create(contact) : this.edit(contact)
    console.log(this.TAG, 'save()', contact)
  } //end save()

  close() { this.modalCtrl.dismiss() } //end close()

  async create(contact) {
    this.loading.present()
    let contactNew = contact
    contactNew.companyDB = this.authService.user.companyDB
    contactNew.date = Date.now()
    contactNew.dateModified = Date.now()
    // contactNew.timestamp = this.authService.timestamp
    await this.authService.firestore.collection('contacts').add(contact).then(docRef => {
      this.authService.logEvent('contact_add')
      this.authService.saveHistory({ ...contact, key: docRef.id }, 'contact-detail')
      this.loading.dismiss()
      this.modalCtrl.dismiss()
    }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //end this.auth.firestore.collection('contacts').add()
  } //end create()

  async edit(contact) {
    this.loading.present()
    let update = JSON.parse(JSON.stringify(contact))
    update.companyDB = this.authService.user.companyDB
    update.date = Date.now()
    update.dateModified = Date.now()
    // update.timestamp = this.authService.timestamp
    this.authService.saveHistory({ ...update }, 'contact-detail')
    delete update.key
    await this.authService.firestore.doc('contacts/' + this.contact.key).set(update).then(() => {
      this.authService.logEvent('contact_modify')
      this.loading.dismiss()
      this.modalCtrl.dismiss()
    }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //end this.auth.firestore.doc('contacts/' + this.contact.key).set()
  } //end edit()

  async delete() {
    this.loading.present()
    this.contact.deleted = true
    this.authService.saveHistory({ ...this.contact }, 'contact-detail')
    await this.authService.firestore.doc('contacts/' + this.contact.key).update({ date: Date.now(), dateModified: Date.now(), deleted: true }).then(async () => {
      await this.authService.firestore.doc('contacts/' + this.contact.key).delete().then(() => {
        this.authService.logEvent('contact_remove')
        this.loading.dismiss()
        this.modalCtrl.dismiss()
      }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //end this.auth.firestore.doc('contacts/' + this.contact.key).delete()
    })
  } //end delete()

}
