import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthGuard } from 'src/app/guards/auth.guard'

const routes: Routes = [
  { canActivate: [AuthGuard], path: '', loadChildren: () => import('src/app/pages//tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'login', loadChildren: () => import('src/app/pages/global/login/login.module').then(m => m.LoginPageModule) },
  {    path: 'ptt',    loadChildren: () => import('./pages/settings/ptt/ptt.module').then( m => m.PttPageModule)  },
]
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}