import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PttAntmediaWebhooksService {
  private streamQueue: any[] = [];
  private isPlaying: boolean = false;
  private streamQueueSubject = new Subject<any[]>();

  constructor(private http: HttpClient) {}

  handleWebhook(data: any): void {
    const action = data.action;

    switch (action) {
      case 'liveStreamStarted':
        this.handleLiveStreamStarted(data);
        break;

      case 'liveStreamEnded':
        this.handleLiveStreamEnded(data);
        break;

      case 'vodReady':
        this.handleVodReady(data);
        break;

      default:
        console.warn(`Unhandled action: ${action}`);
    }
  }

  private handleLiveStreamStarted(data: any): void {
    console.log('Live stream started:', data);
    
    this.streamQueue.push(data);
    
    if (!this.isPlaying) {
      this.playNextStream();
    }

    this.streamQueueSubject.next(this.streamQueue);
  }

  private handleLiveStreamEnded(data: any): void {
    console.log('Live stream ended:', data);
    
    // Allow controll room to replay the current stream before disposing

    this.streamQueueSubject.next(this.streamQueue);
  }

  private handleVodReady(data: any): void {
    console.log('VOD ready:', data);

    this.streamQueueSubject.next(this.streamQueue);
  }

  // Public method to subscribe to the stream queue updates
  getStreamQueue(): Observable<any[]> {
    return this.streamQueueSubject.asObservable();
  }

  private playNextStream(): void {
    if (this.streamQueue.length > 0) {
      const nextStream = this.streamQueue.shift();
      console.log('Playing stream:', nextStream);

      this.isPlaying = true;

      setTimeout(() => {
        console.log('Stream playback complete:', nextStream);
        this.isPlaying = false;

        if (this.streamQueue.length > 0) {
          this.playNextStream();
        }
      }, 5000); 
    }
  }
}
