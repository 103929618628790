import { Component, OnInit, ViewChild } from '@angular/core'
import { IonReorderGroup, ModalController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth/auth.service'
import { DataService } from 'src/app/services/data/data.service'
import { DateService } from 'src/app/services/date/date.service'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { UtilityService } from 'src/app/services/utility/utility.service'
import { BoominEventPagesSubComponent } from '../boomin-event-pages-sub/boomin-event-pages-sub.component'
import { BoominEventPagesComponent } from '../boomin-event-pages/boomin-event-pages.component'

@Component({
  selector: 'app-boomin-event-detail',
  templateUrl: './boomin-event-detail.component.html',
  styleUrls: ['./boomin-event-detail.component.scss'],
})
export class BoominEventDetailComponent implements OnInit {
  private TAG = 'BoominEventDetailComponent|'
  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup
  config = { randomCheck: { enabled: false, photo: false, comment: false, title: '' }, accessCodeDisable: false }
  public description
  public event
  public isNew: boolean = false
  public checklist: boolean = false
  public name
  public orderIn = []
  public orderOut = []
  public pagesObj: any = {}
  public pageOptions = [
    { title: 'Optional', type: 'optional', disabled: false },
    { title: 'Photo', type: 'photo', disabled: false },
    { title: 'Repeatable', type: 'repeatable', disabled: false },
    // { title: 'Skip Whitelist', type: 'skipWhitelist', disabled: true }
  ]

  public randomCheckOptions = [
    { title: 'Comment', type: 'comment' },
    { title: 'Photo', type: 'photo' },
  ]

  public segment = 'ordering'
  public siteKey

  constructor(
    private authService: AuthService,
    public dataService: DataService,
    public dateService: DateService,
    public loading: LoadingService,
    public modalCtrl: ModalController,
    public utilityService: UtilityService
  ) {} //end ()

  ngOnInit() {
    if (this.event != null) {
      this.isNew = false
      this.name = this.event.name
      this.description = this.event.description
      this.checklist = this.event.checklist ? true : false
      this.config = this.event?.config ?? { randomCheck: { enabled: false, photo: false, comment: false, title: '' } }
      this.pagesObj = this.event.pagesObj
      this.siteKey = this.event.siteKey ? this.event.siteKey : ''
      const orderIn = [], orderOut = []
      Object.entries(this.pagesObj).forEach(([k, v]: any) => {
        if (!v.options) v.options = {}
        if (v.inIndex >= 0) orderIn[v.inIndex] = k
        if (v.outIndex >= 0) orderOut[v.outIndex] = k
      })
      this.orderIn = orderIn.filter(x => x !== undefined && x != null)
      this.orderOut = orderOut.filter(x => x !== undefined && x != null)

      console.log(this.TAG, 'pagesInIndex', this.orderIn)
      console.log(this.TAG, 'pagesOutIndex', this.orderOut)
      console.log(this.TAG, 'pagesObj', this.pagesObj)
    } else {
      this.description = ''
      this.event = {}
      this.isNew = true
      this.name = ''
      this.orderIn = [...new Set()]
      this.orderOut = [...new Set()]
      this.pagesObj = {}
      this.checklist = false
      this.siteKey = ''
      this.config = { randomCheck: { enabled: false, photo: false, comment: false, title: '' }, accessCodeDisable: false }
    }
  } //end ngOnInit()

  doReorder(ev, side) {
    this[`order${side}`] = ev.detail.complete(this[`order${side}`])
    if (this.authService.showError) console.error(this.TAG, 'doReorder()', this[`order${side}`])
  } //end doReorder()

  async pagesManage() {
    const modal = await this.modalCtrl.create({ component: BoominEventPagesComponent, componentProps: { event: this.event, pagesIn: this.orderIn, pagesOut: this.orderOut, currentPagesObj: this.pagesObj, siteKey: this.siteKey, modeManage: true } })
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned.data && dataReturned.role == 'update') {
        console.log(this.TAG, 'pagesManage() update', { ...dataReturned.data }, this.orderIn, this.orderOut)
        const data = dataReturned.data
        this.orderIn = ([...new Set(data.keysIn)])
        this.orderOut = ([...new Set(data.keysOut)])
        this.pagesObj = { ...data.pages }
      } //end if (dataReturned.data && dataReturned.role == 'update')
    }) //end modal.onDidDismiss()
    return await modal.present()
  } //end pagesManage()

  async pagesSubManage(page) {
    const modal = await this.modalCtrl.create({ component: BoominEventPagesSubComponent, componentProps: { currentPage: { ...page }, siteKey: this.siteKey } })
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned.data && dataReturned.role == 'update') {
        console.log(this.TAG, 'pagesSubManage() update', { ...dataReturned.data })
        const data = dataReturned.data
        this.pagesObj[page.key].subPages = data.subPages
      } //end if (dataReturned.data && dataReturned.role == 'update')
    }) //end modal.onDidDismiss()
    return await modal.present()
  } //end pagesManage()

  changeOption(page, option) { page.options[option] = !page.options[option] } //end changeOptional()

  save(createCopy?) {
    if (!this.name) {
      this.utilityService.simpleAlert('Event name missing')
      return
    }

    if (this.config.randomCheck.enabled && !this.config.randomCheck.title) {
      this.utilityService.simpleAlert('Random Check Title Missing')
      return
    }

    const obj: any = this.authService.defaultDoc((this.isNew || createCopy))
    obj.name = this.name
    obj.description = this.description ? this.description : ''
    obj.checklist = this.checklist
    obj.config = this.config
    if (createCopy) obj.description != '' ? obj.description += ' Copy' : obj.description = 'Copy'
    obj.pagesObj = { ...this.pagesObj }

    for (const pageKey in obj.pagesObj) {
      const indexIn = this.orderIn.indexOf(pageKey)
      const indexOut = this.orderOut.indexOf(pageKey)

      if (indexIn > -1) { obj.pagesObj[pageKey].inIndex = indexIn } else { delete obj.pagesObj[pageKey].inIndex }
      if (indexOut > -1) { obj.pagesObj[pageKey].outIndex = indexOut } else { delete obj.pagesObj[pageKey].outIndex }
    }

    obj.hasIn = this.orderIn.length > 0
    obj.hasOut = this.orderOut.length > 0
    obj.siteKey = this.siteKey
    delete obj.key
    if (this.authService.showError) console.error(this.TAG, 'save()', { ...obj })
    this.isNew || createCopy ? this.create(obj) : this.edit(obj)
  } //end save()

  close() { this.modalCtrl.dismiss() } //end close()

  async create(event) {
    this.loading.present()
    await this.authService.firestore.collection(`boomin-events`).add(event).then(() => {
      console.warn(this.TAG, 'EVENT ADDED', { ...event })
      this.authService.logEvent('boomin_event_add')
      this.loading.dismiss()
      this.modalCtrl.dismiss()
    }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //end this.auth.firestore.collection('boomin-events')
  } //end create()

  async edit(event) {
    this.loading.present()
    await this.authService.firestore.doc(`boomin-events/${this.event.key}`).set(event).then(() => {
      console.warn(this.TAG, 'EVENT MODIFIED', { ...event })
      this.authService.logEvent('boomin_event_modify')
      this.loading.dismiss()
      this.modalCtrl.dismiss()
    }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //catch(err)
  } //end edit()

  async delete() {
    this.loading.present()
    await this.authService.firestore.collection('boomin-policies').where('eventsKeys', 'array-contains', this.event.key).get().then(async (querySnapshot) => {
      if (!querySnapshot.empty) {
        this.loading.dismiss()
        this.utilityService.simpleAlert('Remove Event from Policies')
      } else {
        await this.authService.firestore.doc(`boomin-events/${this.event.key}`).update({ ...this.authService.defaultDoc(), deleted: true }).then(async () => {
          await this.authService.firestore.doc(`boomin-events/${this.event.key}`).delete().then(() => {
            this.authService.logEvent('boomin_event_remove')
            this.utilityService.simpleAlert('Event Removed')
            this.loading.dismiss()
            this.modalCtrl.dismiss()
          }).catch(err => { if (this.authService.showError) console.error(this.TAG, err) }) //await this.auth.firestore.doc(`boomin-events/${this.event.key}`).delete().then()
        })
      } //end if (querySnapshot)
    }) //await this.auth.firestore.collection('boomin-policies').where('eventsKeys', 'array-contains', this.event.key).get()
  } //end delete()

} //end